export const isMyAccount = (account: any, location: any) => {
  const propsOwner = (location.state && location.state.owner) || account;
  if (propsOwner === account) {
    return true;
  }
  return false;
};

export const walletType=()=>{
  return window.localStorage.getItem('provider');
}
