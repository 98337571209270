import React from 'react';
import { ReactComponent as Flowers } from 'assets/icons/flowers.svg';
import { ReactComponent as Techno } from 'assets/icons/techno.svg';
import { ReactComponent as Shark } from 'assets/icons/shark.svg';
import { ReactComponent as Insect } from 'assets/icons/insect.svg';
import { ReactComponent as Dragon } from 'assets/icons/dragon.svg';
import { ReactComponent as HardShell } from 'assets/icons/hardshell.svg';
import { ReactComponent as Spaceman } from 'assets/icons/spaceman.svg';

export interface CardRaceProps {
  race: string;
}

const CardRace: React.FC<CardRaceProps> = ({ race }) => {
  return (
    <>
      {race === 'flower' ? (
        <Flowers style={{ fill: 'none' }} />
      ) : race === 'techno' ? (
        <Techno style={{ fill: 'none' }} />
      ) : race === 'shark' ? (
        <Shark style={{ fill: 'none' }} />
      ) : race === 'insect' ? (
        <Insect style={{ fill: 'none' }} />
      ) : race === 'dragon' ? (
        <Dragon style={{ fill: 'none' }} />
      ) : race === 'hardshell' ? (
        <HardShell style={{ fill: 'none' }} />
      ) : race === 'spaceman' ? (
        <Spaceman style={{ fill: 'none' }} />
      ) : (
        ''
      )}
    </>
  );
};

export default CardRace;
