import { ActionTypes, ActionSetIsLogin, ActionSetIsPaired, ActionSetNeedForceBind, ActionSetIsMigration } from './types';

export const setIsLogin = (isLogin: boolean): ActionSetIsLogin => ({
  type: ActionTypes.SET_IS_LOGIN,
  data: isLogin
});

export const setIsPaired = (isPaired: boolean): ActionSetIsPaired => ({
  type: ActionTypes.SET_IS_PAIRED,
  data: isPaired
});

export const setNeedForceBind = (needForceBind: boolean): ActionSetNeedForceBind => ({
  type: ActionTypes.SET_NEED_FORCE_BIND,
  data: needForceBind
});

export const setIsMigration = (isMigration: boolean): ActionSetIsMigration => ({
  type: ActionTypes.SET_IS_MIGRATION,
  data: isMigration
});
