import axios from '../axios';
import { PaginationParams } from './types';

export interface OrderRequest extends PaginationParams {
  orderId: string;
  sig?: boolean;
}

export interface Signature {
  format: string;
  sig: string;
}

export interface CreateOrderRequest extends OrderInfo {
  signature: Signature;
}

export interface OrderInfo {
  nftAddress: string;
  tokenId: number;
  nonce: number;
  auctionType: 0 | 1;
  tokenIds: number[];
  seller: string;
  buyer: string;
  startingPrice: string;
  endingPrice: string;
  startedAt: number;
  endedAt: number;
}

export interface Order extends OrderInfo {
  orderId: string;
  status: string;
  ts: string;
  order: OrderInfo;
  signature?: Signature;
}

export interface OrderResponse {
  orders: Order[];
  count: number;
}

export const listOrders = (params: OrderRequest): Promise<OrderResponse> => {
  return axios.get('order', {
    params
  });
};

export const createOrder = (params: CreateOrderRequest) => {
  return axios.post('order', {
    ...params
  });
};
