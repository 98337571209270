import React from 'react';
import { connect } from 'react-redux';
import { State } from 'store';
import { PriceState } from 'store/price/types';
import { parsePrice } from 'utils';

export interface PriceProps {
  priceState: PriceState;
  eth: string;
  className?: string;
}

const Price: React.FC<PriceProps> = ({ priceState, eth, className = '' }) => {
  const { price } = priceState;
  const usdPrice = (Number(eth) * price).toFixed(2);
  return <span className={className}>{'$' + parsePrice(usdPrice)}</span>;
};
const mapStateToProps = (state: State) => ({
  priceState: state.price
});

export default connect(mapStateToProps)(Price);
