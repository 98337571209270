import React, { useCallback, useEffect, useState } from 'react';
import SliderMenu from './SliderMenu';
import AccountTips from './AccountTips';
import { AccountLayout, RouteViewProps } from './typings';
import styles from './account.layout.module.css';
import PrivateRoute from 'routes/PrivateRoute';
import { Route } from 'react-router';
import { Dashboard, Account, Transfer } from 'views/AccountNew';
import { ReactComponent as Expandable } from 'assets/icons/expandable.svg';
import { ReactComponent as Closable } from 'assets/icons/closable.svg';
import { configResponsive, useResponsive } from 'ahooks';
import { AuthProvider } from '../Account/AuthLogin';

/**default menu data*/

const menuDataRoutes = [
  {
    name: 'Dashboard',
    path: '/new/account/dashboard',
    component: Dashboard,
    private: true
  },
  {
    name: 'Transfer',
    path: '/new/account/transfer',
    component: Transfer,
    private: true
  },
  {
    name: 'RunBlox Account',
    path: '/new/account/rouge',
    component: Account,
    private: true
  }
];

/**
 * basic slider menu & route view
 *
 * @param props basic menu props
 * @constructor
 */
configResponsive({
  small: 0,
  middle: 900,
  large: 1200
});
const ProLayout: React.FC<AccountLayout> = props => {
  const { small, middle, large } = useResponsive();

  const [expandable, setExpandable] = useState<boolean>(true);
  const expandableClick = useCallback(() => {
    setExpandable(!expandable);
  }, [expandable]);

  useEffect(() => {
    if ((small && middle) || large) {
      setExpandable(true);
    }
  }, [small, middle, large]);

  return (
    <>
      <div className={`${styles.basicLayout} ${styles.hasSider}`}>
        {!middle && !large ? (
          <>
            <Divider />
            <ClosableMenu
              expandable={expandable}
              expandableClick={expandableClick}
            />
            {
              <SliderMenu
                menuData={expandable ? [menuDataRoutes[0]] : menuDataRoutes}
              />
            }
            <Divider />
          </>
        ) : (
          <SliderMenu menuData={menuDataRoutes} />
        )}

        <div className={styles.rightContainer}>
          <AccountRouteView routes={menuDataRoutes} history={props.history} />
        </div>
      </div>
    </>
  );
};

const ClosableMenu: React.FC<{
  expandable: boolean;
  expandableClick: any;
}> = props => {
  const { expandable, expandableClick } = props;

  return (
    <div className={styles.topNav}>
      <span onClick={expandableClick}>
        {expandable ? (
          <Expandable className={styles.topNavIcon} />
        ) : (
          <Closable className={styles.topNavIcon} />
        )}
      </span>
    </div>
  );
};
const Divider: React.FC<any> = props => {
  return (
    <div style={{ padding: '0px 10px' }}>
      <div className={styles.basicLayoutDivider} />
    </div>
  );
};
/**
 * account route view
 *
 * @param props routes config data
 * @constructor
 */
const AccountRouteView: React.FC<RouteViewProps> = props => {
  const { routes, history } = props;
  return (
    <main className={styles.accountContainer}>
      <AuthProvider>
        <AccountTips history={history}/>
        {routes?.map((r, i) =>
          r.private ? (
            <PrivateRoute
              key={'pr' + i}
              path={r.path}
              component={r.component}
            />
          ) : (
            <Route key={'r' + i} path={r.path} component={r.component} />
          )
        )}
      </AuthProvider>
    </main>
  );
};

export default ProLayout;
