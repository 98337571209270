import React from 'react';
import { ButtonNew, Balance, WatchAssetButton } from 'components';
import styles from './tokenInfo.module.css';
import { BigNumber } from '@ethersproject/bignumber';
import { shortenAddress } from 'utils';
export interface TokenInfoProps {
  className?: string;
  symbol: string;
  balance: BigNumber;
  decimals?: number;
  contract?: string;
  showAction?: boolean;
  disabled?: boolean;
  onDeposit?: () => void;
  onWithdraw?: () => void;
  renderIcon?: () => React.ReactNode;
  frozenBtn?: any;
}

const TokenInfo: React.FC<TokenInfoProps> = ({
  className = '',
  symbol,
  balance,
  decimals=18,
  contract = '',
  showAction = false,
  disabled = false,
  onDeposit,
  onWithdraw,
  renderIcon,
  frozenBtn={
    deposit:false,
    withdraw:false,
    claimTimeLeft:''
  }
}) => {

  return (
    <section className={`${className} ${styles.tokenInfo} ${disabled ? styles.disabled : ''}`}>
      <div className={styles.baseInfo}>
        {renderIcon && renderIcon()}
        <div className={styles.balanceWrapper}>
          <Balance
            className={styles.balance}
            balance={balance}
            symbol={symbol}
            decimals={decimals}
          />
          {/* <Price className={styles.price} eth={formatEther(balance)} />*/}
        </div>
      </div>
      {contract && (
        <div className={styles.contract}>
          <p>Contract</p>
          <p className={styles.addr}>{shortenAddress(contract)}</p>
          <WatchAssetButton tokenAddress={contract}
                            tokenSymbol={symbol}
                            tokenDecimals={18}
                            tokenImage={symbol === 'RUX' ? 'https://runblox.io/runblox-icon.png' : 'https://runblox.io/%20OBX%20token-black%20background%20preview%2096.png'}
          ></WatchAssetButton>
        </div>
      )}
      {showAction && (
        <div className={styles.actions}>
          <ButtonNew
            className={styles.btn}
            mode="secondary"
            disabled={frozenBtn?.deposit}
            onClick={onDeposit}
          >
            {frozenBtn?.deposit ? 'FREEZING' : 'DEPOSIT'}
          </ButtonNew>
          <ButtonNew
            className={styles.btn}
            mode="secondary"
            disabled={frozenBtn?.withdraw}
            onClick={onWithdraw}
          >
            {frozenBtn?.withdraw ? 'FREEZING' : 'WITHDRAW'}
          </ButtonNew>
        </div>
      )}
    </section>
  );
};

export default TokenInfo;
