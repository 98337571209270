import React from 'react';
import styles from './table.module.css';

export type RecordType = Record<string, string>;

export interface Column {
  title: string;
  key: string;
  width?: string;
  render?: (value: string, index: number) => React.ReactNode;
}

export interface TableProps {
  columns: Array<Column>;
  dataSource: Array<RecordType>;
  className?: string;
}

export interface TableHeaderProps {
  columns: Array<Column>;
}

export type TableBodyProps = TableProps;

const TableHeader: React.FC<TableHeaderProps> = ({ columns }) => {
  return (
    <div className={styles.tableHeader}>
      <div className={styles.tableHeaderRow}>
        {columns.map(({ title, key, width }) => {
          return (
            <div
              className={styles.tableHeaderCell}
              key={key}
              style={{ width: width }}
            >
              {title}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TableBody: React.FC<TableBodyProps> = ({
  columns,
  dataSource,
  className
}) => {
  return (
    <div className={styles.tableBody}>
      {dataSource.map((data, rowIndex) => {
        const rowHtml = [];
        rowHtml.push(
          <div className={styles.tableBodyRow} key={rowIndex}>
            {Object.keys(data).map((key, cellIndex) => {
              const column = columns[cellIndex];
              if (column.render) {
                return (
                  <div className={styles.tableBodyCell} key={key}>
                    {column.render(data[key], rowIndex)}
                  </div>
                );
              } else {
                return (
                  <div className={styles.tableBodyCell} key={key}>
                    {data[key]}
                  </div>
                );
              }
            })}
          </div>
        );
        return rowHtml;
      })}
    </div>
  );
};

const Table: React.FC<TableProps> = ({ columns, dataSource, className }) => {
  return (
    <div className={styles.tableWrapper}>
      <div className={styles.table}>
        <TableHeader columns={columns} />
        <TableBody
          columns={columns}
          dataSource={dataSource}
          className={className}
        />
      </div>
    </div>
  );
};

export default Table;
