import { useTranslation } from 'react-i18next';
import styles from './login.module.css';
import WalletConnectComponent from 'components/Wallet/WalletConnectComponent';

const Login = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.login}>
        <div className={`${styles.inner}`}>
          <div className={styles.connect}>
            <h1 className={styles.title}>{t('views.login.title')}</h1>
            <WalletConnectComponent name={"login"}></WalletConnectComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
