import React, { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import styles from './select_input.module.css';
import { ReactComponent as Search } from 'assets/icons/search.svg';

interface SelectInputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputChange: (value: string) => void;
  className?: string;
  type?: string;
}
const disableKeyCode = [37, 38, 39, 40];
const SearchInput: React.FC<SelectInputProps> = ({
  name = '',
  value,
  type = 'text',
  inputMode = 'text',
  inputChange,
  className = '',
  placeholder = ''
}) => {
  const [inputValue, setInputValue] = useState(value);
  const onInputChanged = ({ target }: ChangeEvent<HTMLInputElement>) => {
    let value = target.value;
    if (inputMode === 'numeric') {
      value = value.replace(/[^\d]/g, '');
    }
    setInputValue(value);
    console.log(value);
    inputChange(value);
  };

  const clickHandler = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    target.value = '';
    target.focus();
    target.value = value;
  };

  return (
    <div className={`${styles.searchInputWrapper} ${className}`}>
      <Search></Search>
      <input
        className={`${styles.input} ${className}`}
        type={type}
        inputMode={inputMode}
        value={inputValue}
        onChange={onInputChanged}
        onClick={clickHandler}
        onKeyDown={event => {
          if (
            inputMode === 'numeric' &&
            (event.key === 'e' ||
              event.key === 'E' ||
              event.key === '+' ||
              event.key === '-')
          ) {
            event.preventDefault();
          }
          if (
            inputMode === 'decimal' &&
            disableKeyCode.filter(e => e === event.keyCode).length
          ) {
            event.preventDefault();
          }
        }}
        onWheel={e => {
          if (inputMode === 'numeric') e.currentTarget.blur();
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchInput;
