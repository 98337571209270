import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './modal.module.css';
import { runCallback } from 'utils';

interface MaskProps {
  visible: boolean;
  children: React.ReactNode;
  onClose?: (visible: boolean) => void;
}

const Mask: React.FC<MaskProps> = ({ visible, children, onClose }) => {
  const nodeRef = React.useRef(null);
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={visible}
      unmountOnExit
      timeout={300}
      classNames={{
        enter: styles['mask-enter'],
        enterActive: styles['mask-enter-active'],
        exit: styles['mask-exit'],
        exitActive: styles['mask-exit-active']
      }}
    >
      <div
        className={styles.mask}
        ref={nodeRef}
        onClick={() => runCallback(onClose, false)}
      >
        {children}
      </div>
    </CSSTransition>
  );
};

export default Mask;
