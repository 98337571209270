import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserWeb3 } from '@moverfinance/web3';
import { useWeb3React } from "@web3-react/core";
import { UnsupportedChainIdError } from '@web3-react/core';

import {
  ACTIVED_ID,
  envNetworkKey,
  L2Name
} from 'constants/chains';
import { switchToNetwork } from 'utils/network';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import Header from '../Header2';
import styles from './base.module.css';
import { Button, Modal, CardList } from '../../components';
import { listNFTs } from 'services';
import { ReactComponent as Transfer } from 'assets/icons/transfer.svg';
import { useDeposit } from 'hooks';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as Loading } from '../../assets/icons/waiting.svg';
import { NFT as NFT_ADDRESS } from '../../constants/address';
import message from '../../components/message';
import Footer from "../Footer2";
import SelectChain from '../SelectChain';

const BaseLayout: React.FC = ({ children }) => {
  const {
    library
  } = useWeb3React();
  const { error } = useUserWeb3();
  const { t } = useTranslation();
  const { account } = useUserWeb3() || {};
  const [showListing, setShowListing] = useState(false);
  const [showMigrateSuccess, setShowMigrateSuccess] = useState(false);
  const [tokenIds, setTokenIds] = useState([]);
  const deposit = useDeposit();
  const [steps, setSteps] = useState<boolean[]>([false, false, false]);
  const [showCardList, setShowCardList] = useState(false);
  const envActivedKey = envNetworkKey.toUpperCase();
  const { pathname } = useLocation();
  ReactGA.initialize('G-D2K6T8G9G6');
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [pathname]);
  const useScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  useEffect(() => {
    if (account) {
      let listParams = {
        nftAddress: NFT_ADDRESS,
        owner: account || ''
      };
      listNFTs(listParams).then(data => {
        setTokenIds(data.nfts.flatMap(d => d.tokenId) as []);
      });
    }
  }, [account]);
  const migrate = () => {
    setShowCardList(false);
    setShowListing(true);
    setSteps([false, false, false]);
    deposit(tokenIds, false, steps => {
      setSteps(steps);
    })
      .then(data => {
        setSteps([true, true, true]);
        setShowListing(false);
        setShowMigrateSuccess(true);
      })
      .catch(error => {
        setShowListing(false);
        message.warning(
          error.message ===
            'MetaMask Tx Signature: User denied transaction signature.'
            ? 'YOU DECLINED THE ACTION IN YOUR WALLET.'
            : error.message,
          5
        );
      });
  };
  useScrollToTop();
  return (
      <div className={styles.base}>
        <Header/>
        <SelectChain />
        {error instanceof UnsupportedChainIdError && (
          <div style={{ textAlign: 'center' }}>
            <div className={styles.transfer}>
              <div className={styles.transferInner}>
                <div className={styles.transferInfo}>
                  <div className={styles.transferInfoIcon}>
                    <Transfer/>
                  </div>
                  <div className={styles.transferInfoBlock}>
                    <div className={styles.transferInfoBlockTitle}>
                      Switch Your Network
                    </div>
                    <div className={styles.transferInfoBlockMsg}>
                      Please switch your wallet network to the {envNetworkKey}.
                    </div>
                  </div>
                </div>
                <div className={styles.transferMenu}>
                  <Button
                    className={`${styles.transferBtn}`}
                    onClick={() => {
                      if(window.localStorage.getItem("provider")==='walletConnect'){
                        const msg=`Please go to the dapp to switch the network to `+envNetworkKey;
                        message.warning(msg,5)
                      }else {
                        switchToNetwork(ACTIVED_ID, library)
                      }
                    }}
                  >
                    {t('views.account.transfer', { envActivedKey })}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {children}
        <Footer/>
        <Modal
          visible={showCardList}
          title={'Migrate all your Blox to Arbitrum network'}
          className={styles.cardListModal}
          onClose={() => {
            setShowCardList(false);
          }}
        >
          <div className={styles.cardListModalContent}>
            <CardList
              showSortOptions={false}
              useCardComponent={true}
              showSmallModal={false}
              limit={4}
              paginationClass={styles.cardListModalContentPagination}
            />
            <div className={styles.cardListModalContentBtn}>
              <Button
                className={`trade ${styles.transferMigrateBtn}`}
                disabled={showListing ? true : false}
                onClick={() => migrate()}
              >
                {'TRANSFER TO ' + L2Name.toUpperCase()}
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          visible={showListing}
          title={'Complete your migration'}
          closable={false}
        >
          <div className={styles.steps}>
            <div className={styles.step}>
              <h2 className={styles.stepTitle}>
                {steps[0] ? <Check/> : <Loading/>}
                <span className={styles.stepTitleText}>
                INTIALIZE YOUR WALLET
              </span>
              </h2>
              <p className={styles.stepContent}>
                To use our Marketplace for the first time you will need to
                initialize your wallet, which requires a one time gas fee.
              </p>
            </div>
            <div className={styles.step}>
              <h2 className={styles.stepTitle}>
                {steps[1] ? <Check/> : <Loading/>}
                <span className={styles.stepTitleText}>APPROVE NFT</span>
              </h2>
              <p className={styles.stepContent}>
                Approving the NFTs for migration sets up for arbitrum bridge. This
                requires a one time gas fee.
              </p>
            </div>
            <div className={styles.step}>
              <h2 className={styles.stepTitle}>
                {steps[2] ? <Check/> : <Loading/>}
                <span className={styles.stepTitleText}>CONFIRM MIGRATION</span>
              </h2>
              <p className={styles.stepContent}>
                Confirm the migration by accepting the signature request in your
                wallet.
              </p>
              {steps[2] && (
                <p className={styles.stepSuccess}>Waiting for signature...</p>
              )}
            </div>
          </div>
          <div className={styles.modalNotice}>
            <div className={styles.modalNoticeTitle}>NOTICE</div>
            <div className={styles.modalNoticeMsg}>
              It is one way migration, you will not transfer your NFTs back to
              Etherum. And the excess costs will be sent back to your arbitrum
              address.{' '}
            </div>
          </div>
        </Modal>

        <Modal
          visible={showMigrateSuccess}
          title={'Migration is processing'}
          closable={false}
        >
          <div className={styles.migrateSuccess}>
            <div>
              Please confirm your NFTs on Arbitum Network. It usually takes 5mins
              to complete.
            </div>
            <button
              className={'button-normal'}
              onClick={() => {
                window.location.href = process.env.REACT_APP_L2_HOST as string;
              }}
            >
              SWITCH TO ARBITRUM
            </button>
          </div>
        </Modal>
      </div>
  );
};

export default BaseLayout;
