import React, { useState, useCallback, useEffect } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseEther } from '@ethersproject/units';
import { useParams } from 'react-router-dom';
import { AddressZero } from '@ethersproject/constants';
import { useUserWeb3 } from '@moverfinance/web3';
import { useCreate, useAuthenticated } from 'hooks';
import { Button, Input, Modal, CardOverview, Card, Price } from 'components';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Loading } from 'assets/icons/waiting.svg';
import { NFT as NFT_ADDRESS } from 'constants/address';
import styles from './sell.module.css';
import {
  createOrder,
  listNFTs,
  NFT,
  listAttributes,
  Attribute
} from 'services';
import message from 'components/message';
import { ReactComponent as AVAX } from 'assets/icons/icon-avax.svg';
import { canBuy as canBuyNFT } from 'utils/assets';

interface ParamTypes {
  assetId: string;
}

interface SellProps extends RouteComponentProps {}

const Sell: React.FC<SellProps> = ({ history, location }) => {
  const { t } = useTranslation();
  const { account } = useUserWeb3() || {};
  const isAuthenticated = useAuthenticated();
  const { assetId } = useParams<ParamTypes>();
  const [price, setPrice] = useState('');
  const [showListing, setShowListing] = useState(false);
  const [showDone, setShowDone] = useState(false);
  const [steps, setSteps] = useState<boolean[]>([false, false, false]);
  const [nft, setNft] = useState<NFT>({} as NFT);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const create = useCreate();

  useEffect(() => {
    const params = {
      nftAddress: NFT_ADDRESS,
      tokenId: assetId
    };
    listNFTs(params).then(data => {
      setNft(data.nfts[0]);
    });
    listAttributes(params).then(data => {
      setAttributes(data.attributes);
    });
  }, [assetId]);

  const sellAsset = useCallback(() => {
    if (isAuthenticated && account) {
      setShowListing(true);
      const startAt = parseInt(`${new Date().valueOf() / 1000}`, 10);

      create(
        {
          nftAddress: NFT_ADDRESS,
          tokenId: parseInt(assetId, 10),
          auctionType: 0,
          seller: account,
          buyer: AddressZero,
          tokenIds: [parseInt(assetId, 10)],
          startingPrice: parseEther(price).toHexString(),
          endingPrice: parseEther(price).toHexString(),
          startedAt: startAt,
          endedAt: startAt + 86400 * 90 // + 3 month
        },
        steps => setSteps(steps)
      )
        .then(newOrder => {
          setSteps([true, true, true]);
          createOrder(newOrder)
            .then(() => {
              setShowDone(true);
            })
            .finally(() => {
              setShowListing(false);
            });
        })
        .catch(error => {
          setShowListing(false);
          message.warning(
            error.error?.message ||
              error.message ===
                'MetaMask Tx Signature: User denied transaction signature.' ||
              error.message === 'User rejected the transaction'
              ? 'YOU DECLINED THE ACTION IN YOUR WALLET.'
              : error.message,
            5
          );
        });
    } else {
      history.push({
        pathname: '/login',
        state: { from: location }
      });
    }
  }, [assetId, create, account, price, isAuthenticated, history, location]);

  return (
    <>
      <div className={styles.sell}>
        <div className={styles.inner}>
          <div className={styles.nav}>
            <button
              className={`${styles.button} ${styles.prevBtn}`}
              onClick={() => history.goBack()}
            >
              Back
            </button>
            {/*<NavLink to={`/assets/${assetId}`} className={styles.back}>*/}
            {/*  <ArrowLeft />*/}
            {/*  <span className={styles.backText}>Back</span>*/}
            {/*</NavLink>*/}
          </div>
          <div className={styles.content}>
            <div className={styles.infoHeaderMobile}>
              <div className={styles.infoDesc}>
                <p className={styles.infoName}>Blox &nbsp;#{nft?.tokenId}</p>
              </div>
            </div>
            <div className={styles.card}>
              <CardOverview
                imgSrc={nft?.imageUrl}
                backgroundColor={nft?.backgroundColor}
                ethPrice={nft?.order?.price}
                lastPrice={nft?.order?.lastPrice}
                owner={nft?.owner}
                race={attributes.filter(a => a.key === 'race')[0]}
                onsale={nft?.order?.onsale}
              />
              <div className={styles.tradeView}>
                {canBuyNFT(nft?.tokenId, nft?.properties?.birthday || '') &&
                <Button
                  className={Number(price) <= 0 ? 'tradeDisabled' : 'trade'}
                  disabled={Number(price) <= 0}
                  onClick={sellAsset}
                >
                  {t('views.asset.postListing')}
                </Button>
                }
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.infoHeader}>
                <div className={styles.infoDesc}>
                  <p className={styles.infoName}>Blox &nbsp;#{nft?.tokenId}</p>
                </div>
              </div>
              <p className={styles.infoCaption}>Set Price</p>
              <div className={styles.infoCard}>
                <p className={styles.infoSubject}>PRICE</p>
                <div className={styles.infoOwner}>
                  <p className={styles.infoText}>
                    Sell at a fixed or declining price.
                    <br />
                    Will be on sale until you transfer this item or cancel it.
                  </p>
                  <div className={styles.infoPrice}>
                    <AVAX />
                    <Input
                      inputMode="decimal"
                      type={'decimal'}
                      value={price}
                      inputChange={value => {
                        var regex =
                          /^(([1-9][0-9]*)(\.\d{1,2})?|0|[0-9]*\.|[0-9]*\.\d{1,4})$/;
                        if (
                          // @ts-ignore
                          (isNaN(value) &&
                            // @ts-ignore
                            isNaN(value.substring(0, value.length - 1))) ||
                          value === '.'
                        ) {
                          setPrice('');
                        } else if (regex.test(value)) {
                          setPrice(value);
                        } else {
                          setPrice(value.substring(0, value.length - 1));
                        }
                      }}
                      className={styles.priceInput}
                    />
                  </div>
                  <div className={styles.priceListedInfo}>
                    Your asset listed on the Marketplace has a duration of 90
                    days.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.trading}>
              <div className={styles.history}>
                <h1 className={styles.infoCaption}>{'Summary'}</h1>
                <div className={styles.activity}>
                  <p className={styles.infoSubject}>PRICE</p>
                  <div className={styles.infoOwner}>
                    <div className={styles.infoText}>
                      <div className={styles.priceContainer}>
                        <div className={styles.cardPriceETH}>{`${
                          Number(price) === 0 ? '0.00' : price
                        } ${t('common.eth')}`}</div>
                        <Price eth={price} className={styles.cardPriceUSD} />
                      </div>
                    </div>
                    <p className={styles.infoSubject}>FEES</p>
                    <p className={styles.infoText}>
                      Listing is free. At the time of the sale, the following
                      fees will be deducted.
                    </p>
                    <p className={styles.priceFees}>
                      <span className={styles.priceFeesName}>ARTIST ROYALTIES</span>
                      <span className={styles.priceFeesValue}>4%</span>
                    </p>
                    <p className={styles.priceFees}>
                      <span className={styles.priceFeesName}>TRANSACTION FEE</span>
                      <span className={styles.priceFeesValue}>2%</span>
                    </p>
                    <p className={styles.priceFees}>
                      <span className={styles.priceFeesName}>TOTAL</span>
                      <span className={styles.priceFeesValue}>6%</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={showListing}
        title={'Complete your listing'}
        closable={false}
      >
        <div className={styles.steps}>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {steps[0] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>
                INTIALIZE YOUR WALLET
              </span>
            </h2>
            <p className={styles.stepContent}>
              To use our Marketplace for the first time you will need to
              initialize your wallet, which requires a one time gas fee.
            </p>
          </div>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {steps[1] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>APPROVE TOKEN</span>
            </h2>
            <p className={styles.stepContent}>
              Approving the token for trading sets you up for auction listings.
              This requires a one time gas fee.
            </p>
          </div>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {steps[2] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>
                CONFIRM {price}&nbsp;AVAX LISTING
              </span>
            </h2>
            <p className={styles.stepContent}>
              Confirm the listing by accepting the signature request in your
              wallet.
            </p>
            {steps[2] && (
              <p className={styles.stepSuccess}>Waiting for signature...</p>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        visible={showDone}
        title={'Your item is now listed for sale'}
        onClose={() => {
          setShowDone(false);
          history.push({
            pathname: `/assets/${assetId}/`
          });
        }}
      >
        <div className={styles.done}>
          <Card
            id={nft?.tokenId}
            ethPrice={price}
            onsale={true}
            owner={nft?.owner}
            imgSrc={nft?.imageUrl}
            heroTag={nft?.properties?.race}
            key={nft._id}
            className={styles.cardView}
          />
          <NavLink
            to={`/assets/${assetId}/`}
            className={`${styles.button} trade `}
          >
            {'VIEW ITEM'}
          </NavLink>
        </div>
      </Modal>
    </>
  );
};

export default Sell;
