import React from 'react';
import styles from './slider.module.css';

export interface SliderProps {
  value: number;
  min?: number;
  max: number;
  step?: number;
  onChange: (value: number) => void;
}

const Slider: React.FC<SliderProps> = ({
  value,
  min = 0,
  max,
  step,
  onChange
}) => {
  const hanldeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    onChange(value);
  };

  const percent = (value / (max - min)) * 100;
  const sliderStyle = {
    '--width': Math.min(Math.max(percent, 0), 100) + '%'
  } as React.CSSProperties;

  const len = max - min + 1;
  const stepsArray: Array<number> = Array.from(Array(len).keys()).map(
    key => key + min
  );
  return (
    <div className={styles.slider} style={sliderStyle}>
      <input
        type="range"
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={hanldeChange}
        className={styles.sliderInput}
      />
      <div className={styles.sliderSteps}>
        {value === 0 ? 'any' : value + '/'}
        {value === 0 ? '' : stepsArray.length - 1}
      </div>
      {/*<div className={styles.steps}>
        {stepsArray.map(step => {
          return (
            <span className={styles.step} key={step}>
              {step}
            </span>
          );
        })}
      </div>*/}
    </div>
  );
};

export default Slider;
