import styles from '../account.module.css';
import { ButtonNew, Card, Input, Modal, NoResult, TokenInfo } from 'components';
import { claimCoin, NFT, listShoes, listShoeBox } from 'services';
import { parseEther } from '@ethersproject/units';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useAuthLogin } from '../AuthLogin';
import { formatEther } from '@ethersproject/units';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Loading } from 'assets/icons/waiting.svg';
import { ReactComponent as TokenRUX } from 'assets/icons/token-rux.svg';
import { ReactComponent as TokenAvax } from 'assets/icons/token-avax.svg';
import { ReactComponent as TokenOBX } from 'assets/icons/token-obx.svg';
import { useAccountIsPair, useBalance } from 'hooks';
import { useDeposit, useRUXBalance, useOBXBalance } from 'hooks/useRunBlox';
import message from 'components/message';
import { trimBalance } from 'utils';
import { useUserWeb3 } from '@moverfinance/web3';
import { useTranslation } from 'react-i18next';
import { track, trackName } from 'vendor/mixpanel';

type InventoryCardsProps = {};
enum TOKEN_TYPE {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  ANY = 'ANY'
}

enum WITHDRAW_FEE {
  AVAX = 0.05,
  RUX = 1,
  RUXAVAX = 0.01,
  NFT_AVAX=0.01,
  NFT_RUX=5
}

const VALIDATE_REG = /^(([1-9][0-9]*)(\.\d{1,2})?|0|[0-9]*\.|[0-9]*\.\d{1,2})$/;
const InventoryCards: React.FC<InventoryCardsProps> = () => {
  const { t } = useTranslation();
  let { account } = useUserWeb3();
  const accountIsPair = useAccountIsPair();
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [nft] = useState<NFT>();
  const [nftCount, setNftCount] = useState<number>(0);
  const { isPaired, balance, avaxBalance, isLogin, isMigration, reloadBalance, setReloadBalance, token, balanceFrozen, lastClaim, obxBalance } = useAuthLogin();
  const ruxBalanceWallet = useRUXBalance(reloadBalance);
  const obxBalanceWallet = useOBXBalance(reloadBalance);
  const avaxBalanceWallet = useBalance(undefined,reloadBalance);
  const { deposit } = useDeposit();
  const { library } = useUserWeb3();
  const [listingTitle, setListingTitle] = useState('');
  const [showListing, setShowListing] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [withdrawing] = useState(false);
  const [depositSteps, setDepositSteps] = useState<boolean[]>([
    false,
    false,
    false
  ]);

  // modal refs
  const modelRef = useRef<DepositRefType>();

  const depositHandler = useCallback(

    async (amount: string, { curToken }: TypeShow) => {
      modelRef.current?.hide();
      setShowListing(true);
      setListingTitle('Deposit');
      console.log('begin deposit:', amount);
      // deposit begin
      track(trackName.CLICK_ACCOUNT_DEPOSIT, { amount: amount, currency: curToken });
      deposit(
        step => {
          setDepositSteps(step);
        },
        amount,
        curToken
      )
        .then((d:any) => {
          console.log(d);
          setShowListing(false);
          message.success('Deposit success!');
          setReloadBalance(true);
          library?.waitForTransaction(d?.hash || d).then(transaction => {
            //refresh balance
            setReloadBalance(true);
          })
        })
        .catch(error => {
          setShowListing(false);
          console.error(error);
          message.warning(error.error?.message || error.message || error, 5);
        }).finally(()=>{
        console.log("finally");
      });
    },
    [deposit, library, setReloadBalance]
  );
  const withdrawHandler = useCallback(
    async (amount: string, { curToken }: TypeShow) => {
      if (token) {
        track(trackName.CLICK_ACCOUNT_WITHDRAW, { amount: amount, currency: curToken });
        //todo 需要重构
        if (curToken === 'AVAX') {
          if (WITHDRAW_FEE.AVAX +parseFloat(amount) > parseFloat(trimBalance(formatEther(avaxBalance || 0)))) {
            message.warning('Insufficient balance!');
            return false;
          }
        }
        if (curToken === 'RUX' || curToken === 'OBX') {
          if (WITHDRAW_FEE.RUX + (curToken === 'OBX' ? 0 : parseFloat(amount)) > parseFloat(trimBalance(formatEther(balance || 0))) || WITHDRAW_FEE.RUXAVAX > parseFloat(trimBalance(formatEther(avaxBalance || 0)))) {
            message.warning('Insufficient balance!');
            return false;
          }
        }
        modelRef.current?.hide();
        claimCoin({ token: token, token_name: curToken.toLowerCase(), coins: parseFloat(amount) * 100 }).then(data => {
          console.log(data);
          message.success('Withdraw success!');
          // reload balance
          setReloadBalance(true);
        }).catch((err:any)=>{
          console.log(err);
          message.warning(err);
        })
      }
    },
    [token, setReloadBalance, balance, avaxBalance]
  );

  useEffect(() => {
    if (token && account) {
      accountIsPair(account).then(paired => {
        if (paired) {
          listShoes({ limit: 100000, token: token }).then((shoesList) => {
            listShoeBox({ limit: 100000, token: token }).then((shoeBox) => {
              const list = shoesList?.shoes.concat(shoeBox?.shoe_boxes);
              const nftsList: any[] | ((prevState: NFT[]) => NFT[]) = [];
              list.filter(d => d.id > 0).forEach(d => {
                nftsList.push({
                  tokenId: d?.id,
                  imageUri: d?.image_uri,
                  imageUrl: d?.image_url,
                  race: d?.race,
                  order: { onsale: d?.listed },
                  backgroundColor: d?.background_color
                });
              });
              setNfts(nftsList);
              setNftCount(nftsList?.length);
            });
          });
        }
      });
    }
  }, [token, account, accountIsPair, reloadBalance]);

  return (
    <>
      <div className={styles.tokenList}>
        {isLogin && (
          <>
            <TokenInfo
              className={styles.tokenItem}
              disabled={!isPaired}
              symbol={'AVAX'}
              balance={avaxBalance || parseEther('0')}
              showAction={isPaired && !isMigration}
              frozenBtn={{
                withdraw: balanceFrozen?.avax > 0 || lastClaim?.avax > 0,
              }}
              renderIcon={() => {
                return <TokenAvax />;
              }}
              onDeposit={() => {
                modelRef.current?.show({
                  type: TOKEN_TYPE.DEPOSIT,
                  title: 'Deposit',
                  curToken: 'AVAX',
                  curBalance:
                    avaxBalanceWallet &&
                    trimBalance(formatEther(avaxBalanceWallet)),
                  icon: () => {
                    return <TokenAvax />;
                  }
                });
              }}
              onWithdraw={() => {
                modelRef.current?.show({
                  type: TOKEN_TYPE.WITHDRAW,
                  title: 'Withdraw',
                  curToken: 'AVAX',
                  curBalance: avaxBalance && trimBalance(formatEther(avaxBalance)),
                  icon: () => {
                    return <TokenAvax />;
                  }
                });
              }}
            />
            <TokenInfo
              className={styles.tokenItem}
              disabled={!isPaired}
              symbol={'RUX'}
              balance={balance || parseEther('0')}
              showAction={isPaired && !isMigration}
              frozenBtn={{
                withdraw: balanceFrozen?.rux > 0 || lastClaim?.rux > 0,
              }}
              renderIcon={() => {
                return <TokenRUX />;
              }}
              onDeposit={() => {
                modelRef.current?.show({
                  type: TOKEN_TYPE.DEPOSIT,
                  title: 'Deposit',
                  curToken: 'RUX',
                  curBalance: trimBalance(formatEther(ruxBalanceWallet)),
                  icon: () => {
                    return <TokenRUX />;
                  }
                });
              }}
              onWithdraw={() => {
                modelRef.current?.show({
                  type: TOKEN_TYPE.WITHDRAW,
                  title: 'Withdraw',
                  curToken: 'RUX',
                  minimal: 0.01,
                  fixed: 2,
                  curBalance: balance && trimBalance(formatEther(balance)),
                  icon: () => {
                    return <TokenRUX />;
                  }
                });
              }}
            />
            <TokenInfo
              className={styles.tokenItem}
              disabled={!isPaired}
              symbol={'OBX'}
              balance={obxBalance || parseEther('0')}
              showAction={isPaired && !isMigration}
              frozenBtn={{
                withdraw: balanceFrozen?.obx > 0 || lastClaim?.obx > 0,
              }}
              renderIcon={() => {
                return <TokenOBX />;
              }}
              onDeposit={() => {
                modelRef.current?.show({
                  type: TOKEN_TYPE.DEPOSIT,
                  title: 'Deposit',
                  curToken: 'OBX',
                  curBalance: trimBalance(formatEther(obxBalanceWallet)),
                  icon: () => {
                    return <TokenOBX />;
                  }
                });
              }}
              onWithdraw={() => {
                modelRef.current?.show({
                  type: TOKEN_TYPE.WITHDRAW,
                  title: 'Withdraw',
                  curToken: 'OBX',
                  minimal: 0.01,
                  fixed: 2,
                  curBalance: obxBalance && trimBalance(formatEther(obxBalance)),
                  icon: () => {
                    return <TokenOBX />;
                  }
                });
              }}
            />
          </>
        )}
      </div>

      {isPaired &&<>
        <section className={styles.inventory}>
          <h1 className={styles.caption}>
            {t('views.account.inventory', { count: nftCount })}
          </h1>
          {nfts?.length === 0 && <NoResult className={styles.noReslut} />}
          {nfts?.length > 0 && (
            <div className={styles.inventoryContent}>
              <div className={styles.inventoryList}>
                {nfts.map(nft => {
                  return (
                    <Card
                      id={nft.tokenId}
                      ethPrice={nft.order?.price}
                      lastPrice={nft.order?.lastPrice}
                      onsale={nft.order?.onsale}
                      owner={nft?.owner}
                      cardClickable={false}
                      className={styles.cursorInherit}
                      backgroundColor={nft?.backgroundColor}
                      frozenWithdraw={lastClaim?.nft > 0}
                      imgSrc={
                        nft?.imageUri || nft?.imageUrl
                      }
                      heroTag={nft?.properties?.race}
                      key={nft?.tokenId}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </section>
      </>}

      {/* Modal Deposit */}
      <ModalToken
        depositHandler={depositHandler}
        withdrawHandler={withdrawHandler}
        modelRef={modelRef}
      />

      <Modal
        visible={showListing}
        title={`Complete your ` + listingTitle}
        closable={false}
      >
        <div className={styles.steps}>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {depositSteps[0] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>
                INTIALIZE YOUR WALLET
              </span>
            </h2>
            <p className={styles.stepContent}>
              To use our Marketplace for the first time you will need to
              initialize your wallet, which requires a one time gas fee.
            </p>
          </div>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {depositSteps[2] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>
                CONFIRM {listingTitle.toUpperCase()}
              </span>
            </h2>
            <p className={styles.stepContent}>
              Confirm the transfer by accepting the signature request in your
              wallet.
            </p>
            {depositSteps[2] && (
              <p className={styles.stepSuccess}>Waiting for signature...</p>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        visible={showWithdraw}
        onClose={() => setShowWithdraw(false)}
        title={'Withdraw NFT Request'}
      >
        <div className={styles.showWithdraw}>
          {nft &&
          <div style={{ width: '100%' }}>
            <Card
              id={nft?.tokenId}
              onsale={nft.order?.onsale}
              cardClickable={false}
              className={styles.showWithdrawCard}
              owner={nft?.owner}
              heroTag={nft?.properties?.race}
              backgroundColor={nft?.backgroundColor}
              imgSrc={
                nft?.imageUri || nft?.imageUrl
              }
              key={nft._id}
            />
            <div className={styles.showWithdrawDescription}>
              <div className={styles.showWithdrawDescriptionTitle}>Withdraw FEE </div>
              <div className={styles.showWithdrawDescriptionFee}>5 rux and 0.01 avax</div>
            </div>
            <div className={styles.showWithdrawFooter}>
              <ButtonNew
                className={styles.showWithdrawCancel}
                onClick={() => setShowWithdraw(false)}
              >
                {'CANCEL'}
              </ButtonNew>
              <ButtonNew
                mode="secondary"
                className={styles.showWithdrawSubmit}
                onClick={() => {
                }}
                disabled={lastClaim?.nft > 0 || withdrawing}
              >
                {withdrawing ? 'WITHDRAWING' : 'WITHDRAW'}
              </ButtonNew>
            </div>
          </div>
          }
        </div>
      </Modal>
    </>
  );
};

type TypeShow = {
  type: TOKEN_TYPE;
  title: string;
  curToken: string;
  curBalance: any;
  receive?: any;
  fixed?: number;
  minimal?: number;
  icon?: () => React.ReactNode;
};
type DepositRefType = {
  show: (typeShow: TypeShow) => void;
  hide: () => void;
  setBalance: (balance: any)=>void;
};

interface ModalDepositProps {
  modelRef: MutableRefObject<
    | {
        show: (ts: TypeShow) => void;
        hide: () => void;
        setBalance: (balance: any)=>void;
      }
    | undefined
  >;
  depositHandler: (amount: string, type: TypeShow) => void;
  withdrawHandler: (amount: string, type: TypeShow) => void;
}

const ModalToken: React.FC<ModalDepositProps> = ({
  modelRef,
  depositHandler,
  withdrawHandler
}) => {

  // basic state
  const [showModal, setShowModal] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const { balance, avaxBalance, obxBalance } = useAuthLogin();
  const ruxBalanceWallet = useRUXBalance(showModal);
  const obxBalanceWallet = useOBXBalance(showModal);
  const avaxBalanceWallet = useBalance(undefined,showModal);
  const [typeShow, setTypeShow] = useState<TypeShow>({
    type: TOKEN_TYPE.ANY,
    title: '',
    receive: 0,
    curBalance: 0,
    curToken: ''
  });
  const {
    type,
    title,
    curToken,
    curBalance,
    fixed = 2,
    minimal = 0.01,
    icon
  } = typeShow;

  // show & hide handler
  useEffect(() => {
    if (modelRef) {
      modelRef.current = {
        show: (ts: TypeShow) => {
          setTypeShow(ts);
          setShowModal(true);
        },
        hide: () => {
          setShowModal(false);
        },
        setBalance: (balance: any) => {
         setTypeShow({...typeShow,curBalance:balance})
        }
      };
    }
  }, [modelRef, typeShow, avaxBalanceWallet, ruxBalanceWallet, type]);

  // input change
  const handleInput = useCallback((value: string) => {
    // @ts-ignore
    if ((isNaN(Number(value)) && isNaN(value.substring(0, value.length - 1))) || value === '.') {
      setAmount('');
    } else if (VALIDATE_REG.test(value)) {
      setAmount(value);
    } else {
      setAmount(value.substring(0, value.length - 1));
    }
  }, []);

  //  deposit or withdraw
  const tokenHandler = useCallback(() => {
    switch (type) {
      case TOKEN_TYPE.DEPOSIT:
        return depositHandler(amount, typeShow);
      case TOKEN_TYPE.WITHDRAW:
        return withdrawHandler(amount, typeShow);
    }
    setShowModal(false);
  }, [amount, depositHandler, type, typeShow, withdrawHandler]);

  // blur format amount handler
  const formatAmountHandler = useCallback(() => {
    const balanceNum = Number(curBalance)
    if (amount && balanceNum) {
      let numVal = Number(amount);
      // if min value
      numVal = numVal < minimal ? minimal : numVal;
      // if max value
      numVal = numVal > balanceNum ? balanceNum : numVal;

      const fmtAmount = `${fixed ? numVal.toFixed(fixed) : numVal}`;
      setAmount(fmtAmount);
    } else {
      setAmount('');
    }
  }, [amount, curBalance, fixed, minimal]);

  let availableBalance;
  //todo 重构
  if (type === TOKEN_TYPE.DEPOSIT) {
    availableBalance = curToken === 'AVAX' ? trimBalance(formatEther(avaxBalanceWallet)) : curToken === 'RUX'?trimBalance(formatEther(ruxBalanceWallet)):trimBalance(formatEther(obxBalanceWallet));
  } else {
    availableBalance = curToken === 'AVAX' ? trimBalance(formatEther(avaxBalance || 0)) : curToken === 'RUX'?trimBalance(formatEther(balance || 0)):trimBalance(formatEther(obxBalance || 0));
  }
  return (
    <Modal
      visible={showModal}
      title={title}
      className={styles.deposit}
      onClose={() => {
        setShowModal(false);
        setAmount('');
      }}
    >
      <div>
        <p className={styles.amount}>Amount ({curToken})</p>
        <div className={styles.inputWrapper}>
          {icon && icon()}
          <Input
            className={styles.input}
            type="decimal"
            inputMode="decimal"
            placeholder={`Minimal ${minimal}`}
            value={amount}
            blur={formatAmountHandler}
            inputChange={handleInput}
          />
        </div>
        <p className={styles.available}>
          Available: {availableBalance} {curToken}
        </p>
        {type === TOKEN_TYPE.WITHDRAW && (
          <p className={styles.available}>
            {curToken === 'AVAX' ? ('Fee: ' + WITHDRAW_FEE.AVAX + ' AVAX') : 'Fee: ' + WITHDRAW_FEE.RUX + ' RUX and ' + WITHDRAW_FEE.RUXAVAX + ' AVAX'}
          </p>
        )}
        <div className={styles.actions}>
          <ButtonNew
            className={styles.btn}
            disabled={(!amount||Number(amount)< minimal||Number(amount)>Number(curBalance))}
            onClick={tokenHandler}
          >
            {type}
          </ButtonNew>
        </div>
      </div>
    </Modal>
  );
};
export default InventoryCards;
