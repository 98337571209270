import React, { MouseEvent } from 'react';
import { useScrollLock } from 'hooks/useScrollLock';
import cls from 'clsx';
import styles from './modal.module.css';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { runCallback } from 'utils';
import Portal from 'components/Portal';
import Mask from './Mask';

export interface ModalProps {
  children: React.ReactNode;
  visible: boolean;
  closable?: boolean;
  onClose?: (visible: boolean) => void;
  title?: string;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({
  children,
  visible,
  closable = true,
  onClose,
  title,
  className = ''
}) => {
  useScrollLock(visible);
  const nodeRef = React.useRef(null);
  const stopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };
  return (
    <Portal>
      <Mask
        visible={visible}
        onClose={() => closable && runCallback(onClose, false)}
      >
        <div
          className={cls(styles.modal, className)}
          ref={nodeRef}
          onClick={stopPropagation}
        >
          <div className={styles.inner}>
            <div className={styles.header}>
              <p className={styles.title}>{title}</p>
              {closable && (
                <button
                  onClick={() => runCallback(onClose, false)}
                  className={styles.close}
                >
                  <Close className={styles.icon} />
                </button>
              )}
            </div>
            {children}
          </div>
        </div>
      </Mask>
    </Portal>
  );
};

export default Modal;
