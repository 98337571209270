import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useUserWeb3, isMobile } from '@moverfinance/web3';
import styles from './header.module.css';
import { Wallet } from 'components';
import { ReactComponent as Logo } from 'assets/icons/logo-v2.svg';
import { priceRequest, TypePriceRequest } from 'store/price/actions';
import Account from './Account';
import buyRux from 'assets/images/buy-rux.png';
import buyObx from 'assets/images/buy-obx.png';

interface HeaderProps {
  priceRequest: TypePriceRequest;
}
const Header: React.FC<HeaderProps> = ({ priceRequest }) => {
  const { active } = useUserWeb3();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const toggleMenu = () => {
    setOpen(open => !open);
  };

  useEffect(() => {
    priceRequest();
  }, [priceRequest]);

  const isMenuChecked = (path: string) => {
    if (location.pathname.indexOf(path) > 0) {
      return true;
    }
    return false;
  };

  const hiddenMenu = () => {
    setOpen(open => !open);
  };

  useEffect(() => {
    if (open && isMobile) {
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [open]);

  return (
    <>
    <div className={styles.header}>
      <div className={styles.inner}>
        <div className={styles.innerView}>
          <a
            href={'https://runblox.io'}
            className={styles.logo}
            target={'_blank'}
            rel="noreferrer"
          >
            <Logo />
          </a>
          <div className={styles.navMenu}>
            <NavLink
              className={`${styles.navItem} ${
                isMenuChecked('assets') ? styles.navItemActive : ''
              }`}
              to={'/assets'}
            >
              <div>
                <div className={styles.navItemDisabled}>Marketplace</div>
              </div>
            </NavLink>

            <div className={`${styles.navItem}`}>
              <div className={styles.navItemDisabled}>
                <a href="https://runblox.io/howToUse.html" rel={"noreferrer"}>How to Use</a>
              </div>
            </div>

            <div className={`${styles.navItem}`}>
              <div className={styles.navItemDisabled}>
                <a href="https://docs.runblox.io/" target="_blank" rel={"noreferrer"} data-lang="header.menu.title.whitepaper">Whitepaper</a>
              </div>
            </div>

          </div>
        </div>
        <nav className={`${styles.nav} ${open ? styles.open : ''}`}>
          <div className={styles.navInfo}>
            <div className={styles.navInfoBtn}>
            <a href={"https://traderjoexyz.com/trade?&inputCurrency=0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7&outputCurrency=0xa1afcc973d44ce1c65a21d9e644cb82489d26503#/"} target={"_blank"} rel="noreferrer"><img src={buyRux} style={{marginRight:'16px'}} alt={'buy-rux'}></img></a>
            <a href={'https://www.bybit.com/en-US/trade/spot/OBX/USDT'} target={"_blank"} rel="noreferrer"><img src={buyObx} alt={'buy-obx'}></img></a>
          </div>
            {active ? (
              <Account />
            ) : (
              <Wallet hiddenMenu={hiddenMenu} showNetSelect={true} />
            )}
          </div>
        </nav>
        <button className={styles.menu} onClick={toggleMenu}>
          <span
            className={`${styles.menuIcon} ${open ? styles.open : ''}`}
          ></span>
        </button>
      </div>
    </div>
    <div className={styles.navInfoBtnMobile}>
        <a href={"https://traderjoexyz.com/trade?&inputCurrency=0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7&outputCurrency=0xa1afcc973d44ce1c65a21d9e644cb82489d26503#/"} target={"_blank"} rel="noreferrer"><img src={buyRux}style={{marginRight:'16px'}} alt={'buy-rux'}></img></a>
        <a href={'https://www.bybit.com/en-US/trade/spot/OBX/USDT'} target={"_blank"}  rel="noreferrer"><img src={buyObx} alt={'buy-obx'}></img></a>
      </div>
    </>
  );
};
const mapDispatchToProps = {
  priceRequest
};
export default connect(null, mapDispatchToProps)(Header);
