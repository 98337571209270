import {
  AccountState,
  ActionSetIsLogin,
  ActionSetIsPaired,
  ActionSetNeedForceBind,
  ActionSetIsMigration,
  ActionTypes
} from './types';
import { Reducer } from 'redux';

export const initialState: AccountState = {
  isLogin: false,
  isPaired: false,
  needForceBind: false,
  isMigration: false,
};

type Actions = ActionSetIsLogin | ActionSetIsPaired | ActionSetNeedForceBind | ActionSetIsMigration;

const accountReducer: Reducer<AccountState, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_IS_LOGIN: {
      return { ...state, isLogin: action.data };
    }
    case ActionTypes.SET_IS_PAIRED: {
      return { ...state, isPaired: action.data };
    }
    case ActionTypes.SET_NEED_FORCE_BIND: {
      return { ...state, needForceBind: action.data };
    }
    case ActionTypes.SET_IS_MIGRATION: {
      return { ...state, isMigration: action.data };
    }
    default: {
      return state;
    }
  }
};

export { accountReducer };
