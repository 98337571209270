import styles from './not-found.module.css';

const NotFound = () => {
  return (
    <>
      <div className={styles.notFound}>
        <div className={styles.inner}>
          <h1 className={styles.title}> {'Not Found'}</h1>
        </div>
      </div>
    </>
  );
};

export default NotFound;
