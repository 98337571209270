import { useCallback } from 'react';
import { useTryConnect, useUserWeb3, WalletConnectConnector } from '@moverfinance/web3';
import { injected, walletconnect } from 'connectors/injected';
import { NFT as NFT_ADDRESS, BATCH_ADDRESS } from 'constants/address';
import { useApprove, useIsApproved} from './useApprove';
import { useBatchTransferContract } from './useContract';
import { ContractTransaction } from 'ethers';

export const useTransfer = () => {
  const isApproved = useIsApproved(BATCH_ADDRESS);
  const tryConnect = useTryConnect();
  const { connector } = useUserWeb3();
  const approve = useApprove(BATCH_ADDRESS);
  const batchTransfer = useBatchTransfer();

  return useCallback(
    async (
      tokenIds: Array<number | string>,
      recipientAddress: string,
      onStep: (steps: boolean[]) => void
    ) => {
      const approved = await isApproved();
      await tryConnect(connector instanceof WalletConnectConnector ? walletconnect : injected);
      onStep([true, false, false]);
      if (!approved) await approve();
      onStep([true, true, false]);
      return await batchTransfer(NFT_ADDRESS,tokenIds,recipientAddress);
    },
    [isApproved, tryConnect, approve, batchTransfer, connector]
  );

};


export const useBatchTransfer = () => {
  const batchTransferContract = useBatchTransferContract();
  return useCallback(
    async (nftAddress:string,tokenIds: Array<number | string>, recipient: string) => {
      console.log(nftAddress)
      const contract = batchTransferContract();
      return contract.batchSafeTransfer(nftAddress, tokenIds, recipient) as Promise<ContractTransaction>;
    },
    [batchTransferContract]
  );
};
