import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserWeb3 } from '@moverfinance/web3';
import { ReactComponent as Enabled } from 'assets/icons/enabled.svg';
import styles from './account.module.css';
import { Balance, ExternalLink } from 'components';
import { copyText, shortenAddress } from 'utils';
import { ReactComponent as OpenOutside } from 'assets/icons/open-outside.svg';
import { ETHERSCAN_URL } from '../../constants/chains';
import Tippy from '@tippyjs/react';
import { useBalance } from 'hooks';
import { track, trackName } from 'vendor/mixpanel';

const Account = () => {
  const { t } = useTranslation();
  const { account } = useUserWeb3();
  const [tippyText, setTippyText] = useState<string>(t('common.copy'));
  const [visible, setVisible] = useState(false);
  const [visibleM, setVisibleM] = useState(false);
  const balance = useBalance();
  const location = useLocation();

  const isMenuChecked = (path: string) => {
    if (location.pathname.indexOf(path) > 0) {
      return true;
    }
    return false;
  };

  const copyAddress = () => {
    if (account && copyText(account)) {
      setTippyText(t('common.copied'));
    }
  };

  const showTippy = (ca?: string) => {
    setTippyText(t('common.copy'));
    if (ca === 'm') {
      setVisibleM(true);
    } else {
      setVisible(true);
    }
  };

  const hideTipppy = (ca?: string) => {
    if (ca === 'm') {
      setVisibleM(false);
    } else {
      setVisible(false);
    }
  };

  return (
    <div className={styles.account}>
      <div className={styles.pc}>
        <div className={styles.accountInfo}>
          <div className={styles.accountBalance}>
            <Balance className={styles.balance} balance={balance} />
            <Enabled />
          </div>
          {account && (
            <div className={styles.accountInfoAddress}>
              <ExternalLink
                to={`${ETHERSCAN_URL}/address/${account}`}
                className={styles.accountAddress}
              >
                <p>{shortenAddress(account)}&nbsp;</p>
              </ExternalLink>
            </div>
          )}
          {account && (
            <Tippy content={tippyText} visible={visible}>
              <div
                className={styles.address}
                onClick={copyAddress}
                onMouseEnter={() => showTippy()}
                onMouseLeave={() => hideTipppy()}
              >
                <OpenOutside />
              </div>
            </Tippy>
          )}
        </div>
        <div className={styles.accountLink}>
          <NavLink
            onClick={() => track(trackName.CLICK_MY_ACCOUNT, { 'Address': account })}
            to="/new/account/dashboard"
            className={styles.accountText}
            activeClassName={styles.accountActive}
          >
            {t('layouts.header.account')}
          </NavLink>
        </div>
      </div>

      <div className={styles.mobile}>
        <div className={styles.mobileMenu}>
          <div
            className={`${styles.mobileMenuItem} ${
              isMenuChecked('assets') ? styles.mobileMenuItemActive : ''
            }`}
          >
            <NavLink to="/assets">
              <div>
                <div className={styles.mobileMenuItemDisabled}>Marketplace</div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className={styles.accountInfo}>
          <div className={styles.accountBalance}>
            <Balance className={styles.balance} balance={balance} />
            <Enabled />
          </div>
          {account && (
            <div className={styles.accountView}>
              <ExternalLink
                to={`${ETHERSCAN_URL}/address/${account}`}
                className={styles.address}
              >
                <p>{shortenAddress(account)}&nbsp;</p>
              </ExternalLink>
              <Tippy content={tippyText} visible={visibleM}>
                <div
                  className={styles.address}
                  onClick={copyAddress}
                  onMouseEnter={() => showTippy('m')}
                  onMouseLeave={() => hideTipppy('m')}
                >
                  <OpenOutside />
                </div>
              </Tippy>
            </div>
          )}
        </div>
        <div className={styles.accountLink}>
          <NavLink to="/new/account/dashboard" onClick={()=>track(trackName.CLICK_MY_ACCOUNT, { 'Address': account })} className={styles.accountText}>
            {t('layouts.header.account')}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Account;
