import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseEther } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import {
  useAccountIdToken,
  useAccountUser,
  useFirebaseLogin,
  useAccountIsPair,
  useFirebase,
  useLogOut
} from 'hooks';
import { setIsLogin, setIsPaired, setNeedForceBind, setIsMigration } from 'store/account/actions';
import { bindWallet, getBalance } from 'services';
import { State } from 'store';
import message from '../../../../../components/message';
import { useUserWeb3 } from '@moverfinance/web3';
import { useBindWallet } from 'hooks/useRunBlox';
import { unbindWallet } from 'services';
import { WITHDRAW_FREEZING_HOURS } from 'constants/address';
import moment from 'moment';

/**
 * basic auth context
 *
 */
const AuthContext = createContext<{
  token?: string;
  gameAccount?: any;
  balance?: BigNumber;
  avaxBalance?: BigNumber;
  obxBalance?: BigNumber;
  isLogin: boolean;
  isPaired: boolean;
  isMigration: boolean;
  needForceBind: boolean;
  reloadBalance: boolean;

  handlePair?: any;
  handleForcePair?: any;
  handleUnPair?: any;
  account?: any;

  showLogin: boolean;
  setShowLogin?: any;

  firebaseLogin?: any;
  firebaseLogOut?: any;
  dispatch?: any;
  setReloadBalance?: any;

  balanceFrozen?: any;
  lastClaim?: any
}>({
  reloadBalance: false,
  showLogin: false,
  isLogin: false,
  isPaired: false,
  needForceBind: false,
  isMigration: true
});

/**
 * use auth login hooks
 *
 */
export const useAuthLogin = () => {
  return useContext(AuthContext);
};

/**
 * ReactContext Provider
 *
 * @param children warpper contents
 * @constructor
 */
export const AuthProvider: React.FC = ({ children }) => {
  const { firebaseUi } = useFirebase();
  // @ts-ignore
  const [showLogin, setShowLogin] = useState(false);
  const [reloadBalance, setReloadBalance] = useState(false);

  const dispatch = useDispatch();
  // login and paired status
  const { isLogin, isPaired, needForceBind, isMigration } = useSelector((state: State) => state.account);
  // fetch account user
  const accountUser = useAccountUser();
  // fetch account id token
  const accountIdToken = useAccountIdToken();

  const accountIsPair = useAccountIsPair();
  // firebase login
  const firebaseLogin = useFirebaseLogin();
  const firebaseLogOut = useLogOut();
  const { account } = useUserWeb3();
  const signBindWallet = useBindWallet();

  // auth data
  const [token, setToken] = useState('');
  const [gameAccount, setGameAccount] = useState<any>();

  const [avaxBalance, setAvaxBalance] = useState(BigNumber.from('0'));
  const [avaxBalanceFrozen, setAvaxBalanceFrozen] = useState(0);
  const [avaxLastClaim, setAvaxLastClaim] = useState('');
  const [nftLastClaim, setNFTLastClaim] = useState('');
  const [nftWithdrawPadding, setNFTWithdrawPadding] = useState(false);

  const [balance, setBalance] = useState(BigNumber.from('0'));
  const [ruxBalanceFrozen, setRuxBalanceFrozen] = useState(0);
  const [ruxLastClaim, setRuxLastClaim] = useState('');

  const [obxBalance, setObxBalance] = useState(BigNumber.from('0'));
  const [obxBalanceFrozen, setObxBalanceFrozen] = useState(0);
  const [obxLastClaim, setObxLastClaim] = useState('');


  useEffect(() => {
    // check is redirect
    if (firebaseUi?.isPendingRedirect()) {
      setShowLogin(true);
    }
    accountIdToken(idToken => {
      setToken(idToken);
    });
    accountUser().then(data => {
      setGameAccount(data);

      dispatch(setIsLogin(!!data));
      if (account && isLogin && token) {
        accountIsPair(account).then(isPair => {
          dispatch(setIsPaired(isPair));
          // 获取账户余额
          if (isPair) {
            getBalance({ token: token }).then(balance => {
              const tokens = balance?.tokens as any;
              setBalance(parseEther(tokens?.rux?.available?.toString() || '0'));
              setRuxBalanceFrozen(tokens?.rux?.frozen || 0);
              setRuxLastClaim(tokens?.rux?.last_claim || '');

              setAvaxBalance(parseEther(tokens?.avax?.available?.toString() || '0'));
              setAvaxBalanceFrozen(tokens?.avax?.frozen || 0);
              setAvaxLastClaim(tokens?.avax?.last_claim || '');

              setObxBalance(parseEther(tokens?.obx?.available?.toString() || '0'));
              setObxBalanceFrozen(tokens?.obx?.frozen || 0);
              setObxLastClaim(tokens?.obx?.last_claim || '');

              setNFTLastClaim((balance as any)?.runblox_last_claim || '');
              setNFTWithdrawPadding((balance as any)?.runblox_withdraw_pending);

              dispatch(setIsMigration((balance as any)?.migrated || false));
            });
          } else {
            setBalance(parseEther('0'));
            setAvaxBalance(parseEther('0'));
          }
        });
      }
    });
  }, [
    isLogin,
    token,
    accountUser,
    dispatch,
    accountIdToken,
    account,
    accountIsPair,
    firebaseUi,
    reloadBalance,
    nftLastClaim,
  ]);

  const handlePair = useCallback(() => {
    if (account && token) {
      // bind email refresh
      signBindWallet(gameAccount?.email).then(data => {
        let parameter = { wallet: account || '', token: token, ...data, force: false };
        bindWallet(parameter)
          .then((data: any) => {
            // refresh token
            if (data?.success) {
              accountIdToken(idToken => {
                setToken(idToken);
              });
              dispatch(setIsPaired(true));
            } else if (data?.existing_email || data?.existing_wallet) {
              message.warning("user already binded");
              dispatch(setNeedForceBind(true));
            }
          })
          .catch(error => {
            message.warning(error.message);
          });
      });
    }
  }, [dispatch, gameAccount, account, token, accountIdToken, signBindWallet]);

  const handleForcePair = useCallback(() => {
    if (account && token) {
      // bind email refresh
      signBindWallet(gameAccount?.email).then(data => {
        let parameter = { wallet: account || '', token: token, ...data, force: true };
        bindWallet(parameter)
          .then((data: any) => {
            // refresh token
            if (data?.success) {
              accountIdToken(idToken => {
                setToken(idToken);
              });
              dispatch(setIsPaired(true));
            } else {
              message.warning("user already binded");
            }
          })
          .catch(error => {
            message.warning(error.message);
          });
      });
    }
  }, [dispatch, gameAccount, account, token, accountIdToken, signBindWallet]);

  const handleUnPair = useCallback(() => {
    if (token) {
      unbindWallet({ token }).then(d => {
        console.log(d);
        message.success('unpair success!');
        accountIdToken(idToken => {
          setToken(idToken);
        });
      });
    }
  }, [token, accountIdToken]);

  useEffect(() => {
    if (showLogin) {
      firebaseLogin('firebaseui-auth-container', user => {
        dispatch(setIsLogin(true));
        setShowLogin(false);
      });
    }
  }, [showLogin, firebaseLogin, dispatch]);

  const authLogin = {
    token,
    gameAccount,
    balance,
    avaxBalance,
    obxBalance,
    isLogin,
    isPaired,
    isMigration,
    needForceBind,
    reloadBalance,
    handlePair,
    handleForcePair,
    handleUnPair,
    account,

    showLogin,
    setShowLogin,
    setReloadBalance,
    firebaseLogin,
    firebaseLogOut,
    dispatch,
    balanceFrozen: {
      avax: avaxBalanceFrozen,
      rux: ruxBalanceFrozen,
      obx: obxBalanceFrozen,
    },
    lastClaim: {
      avax: moment(avaxLastClaim).add(WITHDRAW_FREEZING_HOURS, 'hours').diff(moment()),
      rux: moment(ruxLastClaim).add(WITHDRAW_FREEZING_HOURS, 'hours').diff(moment()),
      obx: moment(obxLastClaim).add(WITHDRAW_FREEZING_HOURS, 'hours').diff(moment()),
      nft: nftWithdrawPadding || moment(nftLastClaim).add(WITHDRAW_FREEZING_HOURS, 'hours').diff(moment())
    }
  };

  return (
    <AuthContext.Provider value={authLogin}>{children}</AuthContext.Provider>
  );
};
