import React, { ChangeEvent } from 'react';
import style from './select.module.css';

export interface Option {
  value: string;
  name: string;
  disabled?: boolean;
}

export interface SelectProps {
  value: string;
  options: Array<Option>;
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: string;
}

const Select: React.FC<SelectProps> = ({
  value,
  options,
  onChange,
  disabled = false,
  className = ''
}) => {
  const onSelectChanged = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    const value = target.value.trim();
    onChange(value);
  };

  return (
    <div className={`${style.selectWrapper} ${className}`}>
      <select
        className={style.select}
        value={value}
        onChange={onSelectChanged}
        disabled={disabled}
      >
        {options.map(option => (
          <option
            value={option.value}
            key={option.value}
            disabled={option.disabled}
            className={style.option}
          >
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
