import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserWeb3, isMobile } from '@moverfinance/web3';
import { UnsupportedChainIdError } from '@web3-react/core';
import styles from './wallet.module.css';
import { runCallback } from 'utils';
import { useLocation } from 'react-router-dom';
import WalletConnectModal from "components/Wallet/WalletConnectModal";
import { track, trackName } from 'vendor/mixpanel';

export interface WalletProps {
  hiddenMenu?: () => void;
  showNetSelect?: boolean;
}

const Wallet: React.FC<WalletProps> = ({
  hiddenMenu,
  showNetSelect = false
}) => {
  const walletConnectModalRef = useRef();
  const { t } = useTranslation();
  const { active, error } = useUserWeb3();
  const [callback, setCallback] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (!callback && error instanceof UnsupportedChainIdError) {
      // @ts-ignore
      walletConnectModalRef.current&&walletConnectModalRef.current.hideWalletConnectModal();
      hiddenMenu && runCallback(hiddenMenu, true);
      setCallback(true);
    }
  }, [error, hiddenMenu, callback]);

  const isMenuChecked = (path: string) => {
    if (location.pathname.indexOf(path) > 0) {
      return true;
    }
    return false;
  };

  const tryConnect = () => {
    if (error instanceof UnsupportedChainIdError) return;
    // @ts-ignore
    walletConnectModalRef.current && walletConnectModalRef.current.showWalletConnectModal();
  }

  return (
    <>
      {window.ethereum ? (
        !active && isMobile ? (
          <div className={styles.walletConnect}>
            {showNetSelect && (
              <div className={styles.walletMenu}>
                <div className={styles.mobileMenu}>
                  <div
                    className={`${styles.mobileMenuItem} ${
                      isMenuChecked('assets') ? styles.mobileMenuItemActive : ''
                    }`}
                  >
                    <a href="/assets">
                      <div>
                        <div className={styles.mobileMenuItemDisabled}>
                          Marketplace
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {showNetSelect && (
              <div className={styles.walletInfo}>
                <button
                  className={`${
                    error instanceof UnsupportedChainIdError
                      ? styles.walletDisabled
                      : styles.wallet
                  }`}
                  onClick={() => {
                    track(trackName.CONNECT_WALLET);
                    tryConnect();
                  }}
                >
                  {t('common.connectWallet')}
                </button>
              </div>
            )}
            {!showNetSelect && (
              <button
                className={`${
                  error instanceof UnsupportedChainIdError
                    ? styles.walletDisabled
                    : styles.wallet
                }`}
                onClick={() => {
                  track(trackName.CONNECT_WALLET);
                  tryConnect();
                }}
              >
                {t('common.connectWallet')}
              </button>
            )}
          </div>
        ) : !active ? (
          <div className={styles.walletFlex}>
            <button
              className={`${
                error instanceof UnsupportedChainIdError
                  ? styles.walletDisabled
                  : styles.wallet
              }`}
              onClick={() => {
                track(trackName.CONNECT_WALLET);
                tryConnect();
              }}
            >
              {t('common.connectWallet')}
            </button>
          </div>
        ) : (
          ''
        )
      ) : isMobile ? (
        <div className={styles.walletTop}>
          {showNetSelect && (
            <div className={styles.walletMenu}>
              <div className={styles.mobileMenu}>
                <div
                  className={`${styles.mobileMenuItem} ${
                    isMenuChecked('assets') ? styles.mobileMenuItemActive : ''
                  }`}
                >
                  <a href="/assets">
                    <div>
                      <div className={styles.mobileMenuItemDisabled}>
                        Marketplace
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className={styles.walletInfo}>
            <button
              className={`${
                error instanceof UnsupportedChainIdError
                  ? styles.walletDisabled
                  : styles.wallet
              }`}
              onClick={() => tryConnect()}
            >
              {t('common.connectWallet')}
            </button>
          </div>
        </div>
      ) : (
        <button
          className={`${
            error instanceof UnsupportedChainIdError
              ? styles.walletDisabled
              : styles.wallet
          }`}
          onClick={() => tryConnect()}
        >
          {t('common.connectWallet')}
        </button>
      )}
      <WalletConnectModal ref={walletConnectModalRef}></WalletConnectModal>
    </>
  );
};
export default Wallet;
