import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuthenticated } from 'hooks';
import { BaseLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';

import Account from 'views/Account';
import Asset from 'views/Asset';
import Activity from 'views/Activity';
import Sell from 'views/Sell';
// import Home from 'views/Home';
import Login from 'views/Login';
import Marketplace from 'views/Marketplace';
import NotFound from 'views/NotFound';
import Download from 'views/Download';
import { isL1NET } from '../constants/chains';
import React from 'react';
import ProLayout from '../views/AccountNew/components/AccountLayout';

const Routes = () => {
  const isAuthenticated = useAuthenticated();
  const location = useLocation<{ from: string }>();
  const { from } = location.state || { from: '/' };
  return (
    <BaseLayout>
      <Switch>
        {/* <Route exact path="/" component={Home} /> */}
        <Redirect exact from="/" to="/assets" />
        <Route path="/login">
          {isAuthenticated ? <Redirect to={from} /> : <Login />}
        </Route>
        {isL1NET() && <PrivateRoute exact path="/assets" component={Account} />}
        {isL1NET() && (
          <PrivateRoute exact path="/assets/:assetId" component={Account} />
        )}
        {isL1NET() && (
          <PrivateRoute exact path="/assets/:assetId/sell" component={Account} />
        )}
        {isL1NET() && <PrivateRoute path="/account" component={Account} />}
        {isL1NET() && <PrivateRoute exact path="/assets" component={Account} />}
        {isL1NET() && <PrivateRoute exact path="/activity" component={Account} />}

        {!isL1NET() && <Route exact path="/assets" component={Marketplace} />}
        {!isL1NET() && <Route exact path="/assets/:assetId" component={Asset} />}
        {!isL1NET() && (
          <Route exact path="/assets/:assetId/sell" component={Sell} />
        )}
        {!isL1NET() && <PrivateRoute path="/account" component={Account} />}
        {!isL1NET() && <PrivateRoute path="/new/account" component={ProLayout} />}
        {!isL1NET() && <Route exact path="/assets" component={Marketplace} />}
        {!isL1NET() && <Route exact path="/activity" component={Activity} />}
        <Route exact path="/download" component={Download} />
        <Route component={NotFound} />
      </Switch>
    </BaseLayout>
  );
};

export default Routes;
