import { useState, useEffect, useCallback } from 'react';
import { isMobile } from '@moverfinance/web3';
import firebase from 'firebase/compat/app';
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  signOut
} from 'firebase/auth';
const firebaseui = require('firebaseui');

const LOGIN_KEY =
  'firebase:authUser:' + process.env.REACT_APP_FIREBASE_APIKEY + ':[DEFAULT]';
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
const f = firebase.initializeApp(firebaseConfig);
const ui = new firebaseui.auth.AuthUI(firebase.auth());

export const useFirebase = () => {
  const [fb, setFirebase] = useState<any>();
  const [fbUI, setFirebaseUI] = useState<any>();
  useEffect(() => {
    if (!fb) {
      setFirebase(f);
    }
    if (!fbUI) {
      setFirebaseUI(ui);
      setPersistence(getAuth(), browserSessionPersistence);
    }
  }, [fb, fbUI]);
  return { firebase: fb?.firebase, firebaseUi: fbUI };
};

export const loginProvider = (fb: any) => {
  return [
    {
      provider: fb.auth.GoogleAuthProvider.PROVIDER_ID
    },
    {
      provider: 'apple.com'
    }
  ];
};
export const useVerifyIdToken = () => {
  const { firebase } = useFirebase();
  let checkRevoked = true;
  return useCallback(
    async (idToken: string) => {
      return await firebase
        ?.auth()
        ?.verifyIdToken(idToken, checkRevoked)
        .then((payload: any) => {
          // Token is valid.
          console.log(payload);
          return {
            error: '',
            valid: true
          };
        })
        .catch((error: any) => {
          console.log(error);
          let err;
          if (error.code === 'auth/id-token-revoked') {
            console.log(
              'Token has been revoked. Inform the user to reauthenticate or signOut() the user.'
            );
            err =
              'Token has been revoked. Inform the user to reauthenticate or signOut() the user.';
          } else {
            console.log('Token is invalid.');
            err = 'Token is invalid.';
          }
          return {
            error: err,
            valid: false
          };
        });
    },
    [firebase, checkRevoked]
  );
};

export const useAccountIdToken = () => {
  const { firebase } = useFirebase();
  return useCallback(
    async (onCallback: (token: string) => void) => {
      firebase?.auth()?.onAuthStateChanged((user: any) => {
        if (user) {
          return user.auth.currentUser
            .getIdToken(/* forceRefresh */ true)
            .then((idToken: any) => {
              onCallback(idToken);
            })
            .catch((error: any) => {
              console.error(error);
              onCallback('error' + error);
            });
        } else {
          console.log('logout');
        }
      });
    },
    [firebase]
  );
};

export const useAccountIsPair = () => {
  const { firebase } = useFirebase();
  return useCallback(
    async (account: string) => {
      const wallet: string = await new Promise<string>((resolve, reject) => {
        firebase?.auth().currentUser.getIdTokenResult()
          .then((idTokenResult: any) => {
            resolve(idTokenResult.claims.wallet);
          })
          .catch((error: any) => {
            reject(new Error(error));
          });
      });
      return wallet === account.toLowerCase();
    },
    [firebase]
  );
};
export const useAccountUser = () => {
  return useCallback(async () => {
    const userJson = sessionStorage.getItem(LOGIN_KEY);
    if (userJson) {
      return JSON.parse(userJson);
    }
  }, []);
};
export const useLogOut = () => {
  return useCallback(async () => {
    signOut(getAuth()).then(() => {
      console.log('log out');
    });
  }, []);
};
export const useFirebaseLogin = () => {
  const { firebase, firebaseUi } = useFirebase();
  return useCallback(
    async (id: string, callback: (data: any) => void, providers?: any) => {
      firebaseUi.start('#' + id, {
        callbacks: {
          // Called when the user has been successfully signed in.
          signInSuccessWithAuthResult: function (
            authResult: any,
            redirectUrl: any
          ) {
            if (authResult.user) {
              callback(authResult.user);
            }
            if (authResult.additionalUserInfo) {
              //TODO
            }
            // Do not redirect.
            return false;
          }
        },
        // Opens IDP Providers sign-in flow in a popup.
        signInFlow: isMobile ? 'redirect' : 'popup',
        signInOptions: providers || loginProvider(firebase),
        // Terms of service url.
        tosUrl: 'https://runblox.io/terms.html',
        // Privacy policy url.
        privacyPolicyUrl: 'https://www.iubenda.com/privacy-policy/30870666'
      });
    },
    [firebase, firebaseUi]
  );
};
