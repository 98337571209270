import { ExternalLink } from 'components';
import downloadApp from 'assets/images/downloadApp.png';
import { ReactComponent as Apple } from 'assets/icons/apple.svg';
import { ReactComponent as DownloadApple } from 'assets/icons/download-apple.svg';
import { ReactComponent as GooglePlay } from 'assets/icons/google-play.svg';
import { ReactComponent as DownloadGoogle } from 'assets/icons/download-google.svg';
import styles from './download.module.css';

const DownloadView = () => {
  return (
    <div className={styles.download}>
      <div className={styles.inner}>
        <div className={styles.container}>
          <picture className={styles.picture}>
            <img
              src={downloadApp}
              alt="download app"
              className={styles.image}
            />
          </picture>
          <div className={styles.intro}>
            <h1 className={styles.title}>
              Download and use RunBlox on Arbitrum Network
            </h1>
            <p className={styles.caption}>
              Trade, mint and use your RunBlox NFT in the app
            </p>
            <div className={styles.link}>
              <ExternalLink
                to="https://apps.apple.com/us/app/id1619671688"
                className={styles.linkButton}
              >
                <Apple className={styles.linkIcon} />
                <DownloadApple className={styles.apple} />
              </ExternalLink>
              <ExternalLink
                to="https://play.google.com/store/apps/details?id=io.openblox.runblox&pli=1"
                className={styles.linkButton}
              >
                <GooglePlay className={styles.linkIcon} />
                <DownloadGoogle className={styles.google} />
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadView;
