import { call, put, takeLatest } from 'redux-saga/effects';
import { ethPrice, PriceResponse } from 'services';
import { ActionTypes } from './types';

function* priceRequest() {
  try {
    const data: PriceResponse = yield call(ethPrice);
    yield put({ type: ActionTypes.PRICE_SUCCESSED, data });
  } catch (error) {
    yield put({ type: ActionTypes.PRICE_FAILED, error });
  }
}

function* priceSagas() {
  yield takeLatest(ActionTypes.PRICE_REQUEST, priceRequest);
}

export default priceSagas;
