import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserWeb3 } from '@moverfinance/web3';
import { useRUXBalance, useOBXBalance, useDeposit } from 'hooks/useRunBlox';
import { RUX_ADDRESS, OBX_ADDRESS } from 'constants/address';

import {
  Card,
  ExternalLink,
  Pagination,
  NoResult,
  CopyButton,
  TokenInfo,
  Modal
} from 'components';
import styles from './dashboard.module.css';
import { NFT as NFT_ADDRESS } from 'constants/address';
import { ETHERSCAN_URL, isL1NET } from 'constants/chains';
import { listNFTs, NFT } from 'services';
import { ReactComponent as TokenRUX } from 'assets/icons/token-rux.svg';
import { ReactComponent as TokenOBX } from 'assets/icons/token-obx.svg';
import { ReactComponent as Check } from '../../../../assets/icons/check.svg';
import { ReactComponent as Loading } from '../../../../assets/icons/waiting.svg';
import message from '../../../../components/message';
import { track, trackName } from 'vendor/mixpanel';

const LIMIT = 32; // 每页数据量

const Dashboard: React.FC<any> = () => {
  const { t } = useTranslation();
  let { account } = useUserWeb3();
  const balance = useRUXBalance(false);
  const obxBalance = useOBXBalance(false);
  const { deposit } = useDeposit();
  const location = useLocation(); // 获取跳转页面携带的值

  const [page, setPage] = useState<number>(1);
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [nftCount, setNftCount] = useState<number>(0);
  const [showListing, setShowListing] = useState(false);
  const [depositSteps, setDepositSteps] = useState<boolean[]>([
    false,
    false,
    false
  ]);

  useEffect(() => {
    if (account && !showListing) {
      const params = {
        nftAddress:
          // @ts-ignore
          (location.state && location.state.nftAddress) || NFT_ADDRESS,
        owner: account,
        limit: LIMIT,
        page
      };
      listNFTs(params).then(data => {
        setNftCount(data.count);
        setNfts(data.nfts);
      });
    }
  }, [page, account, location, showListing]);

  const handlePage = useCallback((value: number) => {
    setPage(value);
  }, []);
  const handleDeposit = useCallback(
    (tokenId: number) => {
      setShowListing(true);
      // deposit begin
      track(trackName.CLICK_ACCOUNT_DEPOSIT, { tokenId: tokenId, currency: "NFT" });
      deposit(
        step => {
          setDepositSteps(step);
        },
        tokenId.toString(),
        'NFT'
      )
        .then(data => {
          message.success('Deposit success!');
          setShowListing(false);
        })
        .catch(error => {
          setShowListing(false);
          console.error(error);
          message.warning(error.error?.message || error.message || error, 5);
        });
    },
    [deposit]
  );

  return (
    <main className={styles.container}>
      {/* Wallet */}
      <section className={styles.wallet}>
        <h1 className={styles.caption}>Wallet</h1>
        {/* Wallet 地址 */}
        <div className={styles.address}>
          <ExternalLink
            to={`${ETHERSCAN_URL}/address/${account}`}
            className={styles.addressLink}
          >
            {account}
          </ExternalLink>
          <CopyButton className={styles.addressIcon} text={account as string} />
        </div>
        {/* Wallet 资产 */}
        <div className={styles.tokenList}>
          {/* <TokenInfo
            className={styles.tokenItem}
            symbol="ETH"
            balance={userBalance}
          />*/}
          <TokenInfo
            className={styles.tokenItem}
            symbol="RUX"
            balance={balance}
            contract={RUX_ADDRESS}
            decimals={18}
            renderIcon={() => {
              return <TokenRUX></TokenRUX>;
            }}
          />
          <TokenInfo
            className={styles.tokenItem}
            symbol="OBX"
            contract={OBX_ADDRESS}
            balance={obxBalance}
            decimals={18}
            renderIcon={() => {
              return <TokenOBX></TokenOBX>;
            }}
          />
        </div>
      </section>

      {/* Inventory */}
      <section className={styles.inventory}>
        <h1 className={styles.caption}>
          {t('views.account.inventory', { count: nftCount })}
        </h1>
        {nfts?.length === 0 && <NoResult className={styles.noReslut} />}
        {nfts?.length > 0 && (
          <div className={styles.inventoryContent}>
            <div className={styles.inventoryList}>
              {nfts.map(nft => {
                return (
                  <Card
                    id={nft.tokenId}
                    ethPrice={nft.order.price}
                    lastPrice={nft.order.lastPrice}
                    onsale={nft.order.onsale}
                    owner={nft?.owner}
                    cardClickable={isL1NET() ? false : true}
                    className={isL1NET() ? styles.cursorInherit : ''}
                    sourceType={'dashboard'}
                    depositCallback={handleDeposit}
                    imgSrc={
                      nft?.imageUrl || 'https://images.openblox.io/96.png'
                    }
                    heroTag={nft?.properties?.race}
                    key={nft?.tokenId}
                  />
                );
              })}
            </div>
            <div className={styles.pagination}>
              <Pagination
                current={page}
                total={Math.ceil(nftCount / LIMIT)}
                onChange={handlePage}
              />
            </div>
          </div>
        )}
      </section>
      <Modal
        visible={showListing}
        title={`Complete your Deposit`}
        closable={false}
      >
        <div className={styles.steps}>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {depositSteps[0] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>
                INTIALIZE YOUR WALLET
              </span>
            </h2>
            <p className={styles.stepContent}>
              To use our Marketplace for the first time you will need to
              initialize your wallet, which requires a one time gas fee.
            </p>
          </div>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {depositSteps[2] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>CONFIRM DEPOSIT</span>
            </h2>
            <p className={styles.stepContent}>
              Confirm the transfer by accepting the signature request in your
              wallet.
            </p>
            {depositSteps[2] && (
              <p className={styles.stepSuccess}>Waiting for signature...</p>
            )}
          </div>
        </div>
      </Modal>
    </main>
  );
};

export default Dashboard;
