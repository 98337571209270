import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile, useUserWeb3 } from '@moverfinance/web3';
import { SearchInput } from 'components';
import styles from './card_list.module.css';
import { Card, Pagination, Select } from '../index';
import CardRace from '../Card/CardRace';
import { listNFTs, NFT } from '../../services';
import { NFT as NFT_ADDRESS } from '../../constants/address';
import { ReactComponent as NotFound } from 'assets/icons/notfound.svg';
import { useWindowSize } from 'hooks';

const LIMIT = 8;

export interface CardListProps {
  sourceType?: string;
  click?: (value: NFT) => void;
  style?: {};
  showSortOptions?: boolean;
  showSearchComponent?: boolean;
  checkAble?: boolean;
  limit?: number;
  tokenIds?: string;
  useCardComponent?: boolean;
  paginationClass?: string;
  showSmallCard?: boolean;
  showSmallModal?: boolean;
  checkCallback?: (value: number[]) => void;
}

const CardList: React.FC<CardListProps> = ({
  click,
  style = {},
  showSortOptions = true,
  showSearchComponent = false,
  checkAble = false,
  limit = LIMIT,
  tokenIds = '',
  useCardComponent = false,
  showSmallCard = false,
  showSmallModal = true,
  paginationClass,
  checkCallback
}) => {
  const { t } = useTranslation();
  let { account } = useUserWeb3();
  let { width } = useWindowSize();
  const [count, setCount] = useState(0);
  const [onsale] = useState('false');
  const [page, setPage] = useState(1);
  const [check, setCheck] = useState<number[]>([]);
  const [checkALl, setCheckAll] = useState(false);
  const [checkALlDisable, setCheckALlDisable] = useState(false);
  const [sortPrice, setSortPrice] = useState('');
  const [tokenId, setTokenId] = useState(tokenIds);
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [nftAll, setNftAll] = useState<NFT[]>([]); //不分页获取所有卡片

  const sortOptions = [
    {
      value: '',
      name: 'Sort by'
    },
    {
      value: 'tokenId',
      name: 'Sort by NFT ID'
    },
    {
      value: 'lowPrice',
      name: 'Price low to high'
    },
    {
      value: 'highPrice',
      name: 'Price high to low'
    }
  ];

  const handleSelectSort = (value: string) => {
    setSortPrice(value);
    setPage(1);
  };
  const handlePage = (value: number) => {
    setPage(value);
  };
  const handleCheckAll = () => {
    if (checkALlDisable) return false;
    const nftIds = nftAll
      .filter(nft => !nft.order.onsale)
      .map(nft => nft.tokenId);
    setCheck(nftIds);
    setCheckAll(true);
    checkCallback && checkCallback(nftIds);
  };
  const handleClearAll = () => {
    setCheck([]);
    setCheckAll(false);
    checkCallback && checkCallback([]);
  };
  const checkALlIsDisable = (nftAll: NFT[]) => {
    setCheckALlDisable(
      nftAll.filter(nft => !nft.order.onsale).map(nft => nft.tokenId).length <=
        0
    );
  };
  // const isCheckAll = () => {
  //   if (check.length === 0 || count === 0) return false;
  //   const noSaleNFTList=nfts.filter(nft => !nft.order.onsale);
  //   return check.filter(c => noSaleNFTList.map(nft => nft.tokenId).includes(c)).length === count;
  // };
  const handleSearchInput = (value: string) => {
    const tokenId = value;
    setPage(1);
    setTokenId(tokenId);
  };
  const handleCheckCallback = (id: number) => {
    if (check.includes(id)) {
      let checked = check.filter(_id => _id !== id);
      setCheck(checked);
      setCheckAll(false);
      checkCallback && checkCallback(checked);
    } else {
      let checked = check.concat(id);
      setCheck(checked);
      const nftIds = nftAll
        .filter(nft => !nft.order.onsale)
        .map(nft => nft.tokenId);
      setCheckAll(checked.length === nftIds.length);
      checkCallback && checkCallback(checked);
    }
  };
  const isChecked = (id: number) => {
    return check.filter(_id => _id === id).length > 0;
  };
  useEffect(() => {
    setCheck([]);
    setCheckAll(false);
    checkCallback && checkCallback([]);
  }, [account, checkCallback]);

  useEffect(() => {
    let sort = 'desc';
    let sortby = '_id';
    if (sortPrice === 'lowPrice') {
      sort = 'asc';
      sortby = 'price';
    } else if (sortPrice === 'highPrice') {
      sort = 'desc';
      sortby = 'price';
    } else {
      sort = 'desc';
      sortby = '_id';
    }
    let params = {
      nftAddress: NFT_ADDRESS,
      owner: account || '',
      limit: limit,
      page,
      sort,
      sortby
    };

    let onsalePara = { onsale: onsale };
    if (onsale === 'true') {
      params = { ...params, ...onsalePara };
    }

    const paramsAll = { ...params, ...{ page: 1, limit: 1000 } };
    listNFTs(paramsAll).then(data => {
      setNftAll(data.nfts);
      checkALlIsDisable(data.nfts);
    });

    let tokenPara = { tokenId: tokenId };
    if (tokenId) {
      params = { ...params, ...tokenPara };
    }
    listNFTs(params).then(data => {
      setNfts(data.nfts);
      setCount(data.count);
    });
  }, [page, sortPrice, onsale, account, limit, tokenId]);

  return (
    <div className={styles.cardItem}>
      <div
        className={`${styles.listHeader} ${
          showSmallCard ? styles.listHeaderSmall : ''
        }`}
      >
        <div className={styles.sum}>
          <span>
            {`${count} Bloxes`}{' '}
            {checkAble && check.length > 0 && (
              <span className={styles.checkTitle}>
                （{check.length} selected）
              </span>
            )}
          </span>
          {checkAble && (
            <div className={styles.check}>
              <div
                className={`${styles.checkSelectAll} ${
                  checkALl ? styles.checkSelectAllSelected : ''
                }  ${checkALlDisable ? styles.checkSelectAllDisabled : ''}`}
                onClick={handleCheckAll}
              >
                Select all
              </div>
              <div
                className={`${styles.checkClearAll} ${
                  check.length > 0 ? styles.checkClearAllActive : ''
                }`}
                onClick={handleClearAll}
              >
                Clear
              </div>
            </div>
          )}
        </div>
        {showSortOptions && (
          <div className={styles.sort}>
            <Select
              value={sortPrice}
              options={sortOptions}
              onChange={handleSelectSort}
              className={styles.select}
            />
          </div>
        )}
        {showSearchComponent && (
          <div className={styles.sort}>
            <SearchInput
              inputChange={handleSearchInput}
              placeholder={'Search'}
              inputMode="numeric"
              type={'search'}
              className={`${styles.listHeaderSearchInput} ${
                !showSmallModal ? styles.listHeaderSearchInputLarge : ''
              }`}
            ></SearchInput>
          </div>
        )}
      </div>
      <div className={styles.list}>
        <div
          className={`${styles.cards} ${
            showSmallCard ? styles.cardsSmall : ''
          } ${
            showSmallCard && !showSmallModal
              ? styles.cardsHeightLargeModal
              : showSmallCard
              ? styles.cardsHeightSmallModal
              : ''
          } ${
            showSmallCard && count <= 0 && width <= 720
              ? styles.cardsSmallNotFound
              : ''
          }
           ${count === 0 && styles.cardsTransferNoFound}`}
          style={style}
        >
          {!useCardComponent &&
            nfts.map(nft => {
              return (
                <div className={styles.cardItem} key={nft?.tokenId}>
                  <div
                    onClick={() => {
                      click && click(nft);
                    }}
                    className={styles.card}
                  >
                    <div className={styles.cardLink}>
                      <img
                        src={nft.imageUrl}
                        width={150}
                        height={150}
                        className={styles.cardImage}
                        alt="asset"
                      />
                    </div>
                  </div>
                  <div className={styles.cardInfo}>
                    <span className={styles.cardId}>
                      <CardRace race={nft?.properties?.race}></CardRace>
                      {t('card.title')} #{nft?.tokenId}
                    </span>
                    <div className={styles.cardBreedInfo}>Breed count:0</div>
                  </div>
                </div>
              );
            })}
          {useCardComponent &&
            nfts.map(nft => {
              return (
                <Card
                  id={nft.tokenId}
                  backgroundColor={nft?.backgroundColor}
                  ethPrice={nft.order.price}
                  lastPrice={nft.order.lastPrice}
                  onsale={nft.order.onsale}
                  owner={nft?.owner}
                  imgSrc={nft?.imageUrl || 'https://images.runblox.io/96.png'}
                  cardClickable={false}
                  showSmallCard={showSmallCard}
                  checkAble={checkAble}
                  checked={isChecked(nft?.tokenId)}
                  checkCallback={handleCheckCallback}
                  className={styles.card}
                  heroTag={nft?.properties?.race}
                  key={nft?.tokenId}
                />
              );
            })}
          {count <= 0 && (
            <div
              className={`${styles.cardsNotFound} ${
                !showSmallModal ? styles.cardsNotFoundLarge : ''
              }`}
            >
              <NotFound></NotFound>
              <span>No items found for this search</span>
            </div>
          )}
        </div>
        {count <= 0 && width > 720 && !showSmallModal && showSmallCard && (
          <div style={{ height: '50px' }}></div>
        )}
        {count <= 0 && width <= 720 && showSmallCard && (
          <div style={{ paddingTop: '50px' }}></div>
        )}
        {showSmallModal && width > 720 ? (
          ''
        ) : showSmallCard && count <= 0 ? (
          ''
        ) : (
          <div
            className={`${paginationClass} ${styles.pagination} ${
              isMobile ? '' : styles.cardPagination
            }`}
          >
            <Pagination
              current={page}
              total={Math.ceil(count / limit)}
              onChange={handlePage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardList;
