import { createBrowserHistory } from 'history';
import { combineReducers, applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import homeReduces from './main/reduces';

import {
  RouterState,
  connectRouter,
  routerMiddleware
} from 'connected-react-router';

import { priceReducer } from './price/reducer';
import { accountReducer } from './account/reducer';
import priceSagas from './price/sagas';
import { PriceState } from './price/types';
import { AccountState } from './account/types';
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];
const enhancer = applyMiddleware(...middlewares);

const persistConfig = {
  key: 'root', // 必须有的
  storage: storageSession, // 缓存机制
  blacklist: ['loginStatus'] // reducer 里不持久化的数据,除此外均为持久化数据
  // whitelist: ['loginStatus'] // reducer 里持久化的数据,除此外均为不持久化数据
};

const persistedReducer = persistReducer(persistConfig, homeReduces);

const reducers = combineReducers({
  router: connectRouter(history),
  price: priceReducer,
  account: accountReducer,
  persistedReducer: persistedReducer
});

export interface State {
  price: PriceState;
  router: RouterState;
  account: AccountState;
}

const composeEnhancer =
  (process.env.NODE_ENV === 'development' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(reducers, composeEnhancer(enhancer));

[priceSagas].map(sagaMiddleware.run);
const persist = persistStore(store);

export { store, history, persist };
