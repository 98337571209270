import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './marketplace.module.css';
import { Card, Pagination, Select, Slider } from 'components';
import { listNFTs, NFT, category, CategoryResponse } from 'services';
import { NFT as NFT_ADDRESS } from 'constants/address';

import { useWindowSize } from 'hooks';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as BannerItems } from 'assets/icons/banner-items.svg';
import { ReactComponent as BannerOwners } from 'assets/icons/banner-owners.svg';
import { ReactComponent as BannerFloorPrice } from 'assets/icons/banner-floor-price.svg';
import { ReactComponent as BannerVolumeTrade } from 'assets/icons/banner-volume-traded.svg';
import { ReactComponent as AVAX } from 'assets/icons/icon-avax.svg';
import bannerImg from '../../../assets/images/banner.png';
import bannerMImg from '../../../assets/images/banner-m.png';
import Checkbox from '../../../components/Checkbox';
import Radio from '../../../components/Radio';
import { store } from '../../../store';
import { ActionTypes } from '../../../store/main/actionsTypes';
import { speeds } from 'utils/assets';
import { track, trackName } from 'vendor/mixpanel';

const LIMIT = 48;
const marketplaceFilter = {
  slider: 0,
  legendarySlider: 0,
  mintSlider: 0,
  levelSlider: 0,
  page: 1,
  count: 0,
  filterCount: 0,
  sortPrice: '',
  onsale: 'false',
  assetClass: '',
  assetRarity: '',
  assetType: ''
};
const Marketplace = () => {
  const { t } = useTranslation();
  function getMarketplaceStore() {
    if (store.getState().persistedReducer.data === '') {
      return marketplaceFilter;
    } else {
      const marketplaceStore = store.getState().persistedReducer.data;
      if (typeof marketplaceStore === 'undefined') return marketplaceFilter;
      const jsonData = JSON.parse(marketplaceStore);
      return jsonData;
    }
  }

  // @ts-ignore
  const stores: MarketplaceFilter = getMarketplaceStore();
  const [slider, setSlider] = useState(stores.slider || 0);
  const [legendarySlider, setLegendarySlider] = useState(
    stores.legendarySlider || 0
  );
  const [mintSlider, setMintSlider] = useState(stores.mintSlider || 0);
  const [levelSlider, setLevelSlider] = useState(stores.levelSlider || 0);
  const [page, setPage] = useState(stores.page || 1);
  const [count, setCount] = useState(stores.count || 0);
  const [filterCount, setFilterCount] = useState(stores.filterCount || 0);
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [categoryResponse, setCategoryResponse] = useState<CategoryResponse>();
  const [assetType, setAssetType] = useState<string>(stores.assetType || '');
  const [assetRarity, setAssetRarity] = useState<string[]>(
    (stores.assetRarity && stores.assetRarity.split(',')) || []
  );
  const [assetClass, setAssetClass] = useState<string[]>(
    (stores.assetClass && stores.assetClass.split(',')) || []
  );
  const [sortPrice, setSortPrice] = useState(stores.sortPrice || 'lowPrice');
  const [onsale, setOnSale] = useState(stores.onsale || 'false');

  const [open, setOpen] = useState(false);
  const size = useWindowSize();
  const handleChange = (value: string) => {
    if (assetRarity.includes(value)) {
      setAssetRarity(assetRarity =>
        assetRarity.filter(asset => asset !== value)
      );
      setFilterCount(filterCount - 1);
    } else {
      setFilterCount(filterCount + 1);
      setAssetRarity(assetRarity => assetRarity.concat(value));
    }
    setPage(1);
  };
  const handleTypeClassChange = (value: string) => {
    if (assetType === value) {
      setAssetType('');
      setFilterCount(filterCount - 1);
    } else {
      let fc = filterCount;
      if (!assetType) {
        fc = filterCount + 1;
        setFilterCount(fc);

      }

      if (value === 'Boxes') {
        console.log(fc);
        console.log(assetClass.length);
        setFilterCount(fc - assetClass.length);
        setAssetClass([]);
        setLevelSlider(0);
        setMintSlider(0);
      }
      setAssetType(value);
    }
    setPage(1);
  };
  const handleAssetClassChange = (value: string) => {
    if (assetClass.includes(value)) {
      setAssetClass(assetClass => assetClass.filter(asset => asset !== value));
      setFilterCount(filterCount - 1);
    } else {
      setFilterCount(filterCount + 1);
      setAssetClass(assetClass => assetClass.concat(value));
    }
    setPage(1);
  };

  useEffect(() => {
    track(trackName.CLICK_CLASS_FILTER, { 'CLASS': assetClass });
  }, [assetClass]);
  useEffect(() => {
    console.log(assetRarity);
    track(trackName.RARITY_ON_CHANGE, { Rarity: assetRarity });
  }, [assetRarity]);

  const toggleMenu = () => {
    setOpen(open => !open);
  };

  const clearFilter = () => {
    setSlider(0);
    setLegendarySlider(0);
    setLevelSlider(0);
    setMintSlider(0);
    setAssetClass([]);
    setAssetRarity([]);
    setAssetType('');
    handleSelectSale('false');
    setFilterCount(0);
  };
  useEffect(() => {
    store.dispatch({ type: ActionTypes.TestTag, data: '' });
  }, []);
  useEffect(() => {
    let sort = 'desc';
    let sortby = '_id';
    if (sortPrice === 'lowPrice') {
      sort = 'asc';
      sortby = 'price';
    } else if (sortPrice === 'highPrice') {
      sort = 'desc';
      sortby = 'price';
    } else {
      sort = 'desc';
      sortby = '_id';
    }
    let params = {
      nftAddress: NFT_ADDRESS,
      limit: LIMIT,
      page,
      sort,
      sortby
    };
    let onsalePara = { onsale: onsale };
    if (onsale === 'true') {
      params = { ...params, ...onsalePara };
    }
    let sliderPara = { reproduction: 0 };
    let mintSliderPara = { mintTimes: 0 };
    let typePara = { type: '' };
    let levelSliderPara = { level: 0 };
    let legendarySliderPara = { legendary: 0 };
    let racePara = { race: '' };
    let rarityPara = { rarity: '' };
    if (slider > 0) {
      sliderPara.reproduction = slider;
      params = { ...params, ...sliderPara };
    }
    if (legendarySlider > 0) {
      legendarySliderPara.legendary = legendarySlider;
      params = { ...params, ...legendarySliderPara };
    }
    if (mintSlider > 0) {
      mintSliderPara.mintTimes = mintSlider;
      params = { ...params, ...mintSliderPara };
    }
    if (levelSlider > 0) {
      levelSliderPara.level = levelSlider;
      params = { ...params, ...levelSliderPara };
    }
    if (assetClass.length > 0) {
      racePara.race = assetClass.map(d => d.toLowerCase()).join(',');
      params = { ...params, ...racePara };
    }
    if (assetType) {
      typePara.type = assetType.toLowerCase();
      params = { ...params, ...typePara };
    }
    if (assetRarity.length > 0) {
      rarityPara.rarity = assetRarity.map(d => d.toLowerCase()).join(',');
      params = { ...params, ...rarityPara };
    }
    listNFTs(params).then(data => {
      setNfts(data.nfts);
      setCount(data.count);
    });
    category().then(data => {
      setCategoryResponse(data);
    });
  }, [
    page,
    sortPrice,
    onsale,
    slider,
    assetRarity,
    assetClass,
    assetType,
    legendarySlider,
    mintSlider,
    levelSlider
  ]);

  const handlePage = (value: number) => {
    setPage(value);
  };

  const handleSelectSort = (value: string) => {
    track(trackName.SORT_ON_CHANGE, { value: value });
    setSortPrice(value);
    setPage(1);
  };

  const handleSelectSale = (value: string) => {
    track(trackName.FILTER_ON_CHANGE, { value: value });
    setOnSale(value);
    setPage(1);
    if (value === 'true') {
      setFilterCount(filterCount + 1);
    } else {
      setFilterCount(filterCount - 1);
    }
  };

  const typeFilter = ['Shoes', 'Boxes'];
  const rarityFilter = ['Common', 'Rare', 'Epic', 'Hero', 'Legendary'];
  const classFilter = [
    'Flower',
    'Shark',
    'Techno',
    'Insect',
    'Hardshell',
    'Dragon',
    'Spaceman',
  ];

  const sortOptions = [
    {
      value: 'tokenId',
      name: 'Sort by NFT ID'
    },
    {
      value: 'lowPrice',
      name: 'Price low to high'
    },
    {
      value: 'highPrice',
      name: 'Price high to low'
    }
  ];

  const saleOptions = [
    {
      value: 'false',
      name: 'All items'
    },
    {
      value: 'true',
      name: 'For sale'
    }
  ];
  var bg = size.width <= 414 ? bannerMImg : bannerImg;
  return (
    <>
      {process.env.REACT_APP_MAINTENANCE_MODE === 'false' && <div className={styles.marketplace}>
        <div className={styles.inner}>
          <div className={styles.banner}>
            <div
              className={styles.bannerPic}
              style={{ backgroundImage: 'url(' + bg + ')' }}
            >
              <div style={{ display: size.width <= 414 ? 'none' : 'block' }}>
                Let's Run, Earn,
                <br/>
                Get fit and Guard the Bloxverse!
              </div>
            </div>
            <div className={styles.bannerInfo}>
              <div className={styles.bannerInfoItem}>
                <BannerItems/>
                <div className={styles.bannerInfoItemTitle}>ITEMS</div>
                <div className={styles.bannerInfoItemValue}>
                  {categoryResponse?.supply}
                </div>
              </div>
              <div className={styles.bannerInfoItem}>
                <BannerOwners/>
                <div className={styles.bannerInfoItemTitle}>OWNERS</div>
                <div className={styles.bannerInfoItemValue}>
                  {categoryResponse?.holders}
                </div>
              </div>
              <div className={styles.bannerInfoItem}>
                <BannerFloorPrice/>
                <div className={styles.bannerInfoItemTitle}>
                  FLOOR PRICE&nbsp;
                  <AVAX/>
                </div>
                <div className={styles.bannerInfoItemValue}>
                  {categoryResponse?.floorPrice}
                </div>
              </div>
              <div className={styles.bannerInfoItem}>
                <BannerVolumeTrade/>
                <div className={styles.bannerInfoItemTitle}>
                  VOLUME TRADED&nbsp;
                  <AVAX/>
                </div>
                <div className={styles.bannerInfoItemValue}>
                  {categoryResponse?.volume}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.splitHeader}></div>
          <div className={styles.filter}>
            <div className={styles.filterHeader}>
              <div className={styles.filterHeaderLeft}>
                <p className={styles.filterTitle}>Filter ({filterCount})</p>
                <button
                  className={`${
                    filterCount > 0
                      ? styles.filterResetEnable
                      : styles.filterReset
                  }`}
                  onClick={clearFilter}
                >
                  Clear
                </button>
              </div>
              <button className={styles.menu} onClick={toggleMenu}>
                {open ? <Minus/> : <Plus/>}
              </button>
            </div>
            <div className={`${open ? styles.open : styles.filterRow}`}>
              <div className={styles.filterBlock}>
                <p className={styles.filterCaption}>{t('fitler.type')}</p>
                <div className={styles.filterOptions}>
                  {typeFilter.map(type => {
                    return (
                      <Radio
                        key={type}
                        name={'type'}
                        mode={'point'}
                        value={type}
                        checked={assetType === type}
                        className={styles.filterCheckbox}
                        onChange={handleTypeClassChange}
                      >
                        {type}
                      </Radio>
                    );
                  })}
                </div>
              </div>
              <div className={styles.filterBlock}>
                <p className={styles.filterCaption}>{t('fitler.rarity')}</p>
                <div className={styles.filterOptions}>
                  {rarityFilter.map(rarity => {
                    return (
                      <Checkbox
                        key={rarity}
                        name={'rarity'}
                        value={rarity}
                        checked={assetRarity.includes(rarity)}
                        className={styles.filterCheckbox}
                        onChange={handleChange}
                      >
                        {rarity}
                      </Checkbox>
                    );
                  })}
                </div>
              </div>
              {assetType !== 'Boxes' && (
                <>
                  <div className={styles.filterBlock}>
                    <p className={styles.filterCaption}>{t('fitler.class')}</p>
                    <div className={styles.filterOptions}>
                      {classFilter.map(asset => {

                        return (
                          <Checkbox
                            key={asset}
                            name={'class'}
                            value={asset}
                            checked={assetClass.includes(asset)}
                            className={styles.filterCheckbox}
                            onChange={handleAssetClassChange}
                          >
                            <span>{asset}</span>
                            {
                              // @ts-ignore
                              <span className={styles.filterProperties}>{speeds[asset.toLowerCase()]}km/h</span>
                            }
                          </Checkbox>
                        );
                      })}
                    </div>
                  </div>
                  <div className={styles.filterBlock}>
                    <p className={styles.filterCaption}>{t('fitler.mint')}</p>
                    <div className={styles.filterSlider}>
                      <Slider
                        min={0}
                        max={7}
                        value={mintSlider}
                        step={0}
                        onChange={value => {
                          setMintSlider(value);
                          setPage(1);
                          if (mintSlider === 0 && value > mintSlider) {
                            setFilterCount(filterCount + 1);
                          } else if (value === 0) {
                            setFilterCount(filterCount - 1);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.filterBlock}>
                    <p className={styles.filterCaption}>{t('fitler.level')}</p>
                    <div className={styles.filterSlider}>
                      <Slider
                        min={0}
                        max={30}
                        value={levelSlider}
                        step={0}
                        onChange={value => {
                          setLevelSlider(value);
                          setPage(1);
                          if (levelSlider === 0 && value > levelSlider) {
                            setFilterCount(filterCount + 1);
                          } else if (value === 0) {
                            setFilterCount(filterCount - 1);
                          }
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              {/*<div className={styles.filterBlock}>
                <p className={styles.filterCaption}>BREED COUNT</p>
                <div className={styles.filterSlider}>
                  <Slider
                    min={0}
                    max={6}
                    value={slider}
                    step={0}
                    onChange={value => {
                      setSlider(value);
                      if (value === 1 && value > slider) {
                        setFilterCount(filterCount + 1);
                      } else if (value === 0) {
                        setFilterCount(filterCount - 1);
                      }
                    }}
                  />
                </div>
              </div>*/}
            </div>
          </div>
          <div className={styles.split}></div>
          <div className={styles.listHeader}>
            <div className={styles.sum}>{`${count} RunBloxes`}</div>
            <div className={styles.sort}>
              <Select
                value={onsale}
                options={saleOptions}
                onChange={handleSelectSale}
                className={styles.select}
              />
              <Select
                value={sortPrice}
                options={sortOptions}
                onChange={handleSelectSort}
                className={styles.select}
              />
            </div>
          </div>
          <div className={styles.list}>
            <div className={styles.cards}>
              {nfts.map(nft => {
                return (
                  <Card
                    id={nft.tokenId}
                    backgroundColor={nft?.backgroundColor}
                    ethPrice={nft.order?.price}
                    lastPrice={nft.order?.lastPrice}
                    onsale={nft.order?.onsale}
                    owner={nft?.owner}
                    imgSrc={nft.imageUrl}
                    heroTag={nft?.properties?.race}
                    key={nft.tokenId}
                    sourceType={'marketplace'}
                    storage={{
                      slider: slider,
                      legendarySlider: legendarySlider,
                      mintSlider: mintSlider,
                      levelSlider: levelSlider,
                      assetType: assetType,
                      page: page,
                      count: count,
                      filterCount: filterCount,
                      sortPrice: sortPrice,
                      onsale: onsale,
                      assetRarity: assetRarity.join(',')
                    }}
                  />
                );
              })}
            </div>
            <div className={styles.pagination}>
              <Pagination
                current={page}
                total={Math.ceil(count / LIMIT)}
                onChange={handlePage}
              />
            </div>
          </div>
        </div>
      </div>
      }
      {process.env.REACT_APP_MAINTENANCE_MODE === 'true' && <div>
        <h1 className={styles.maintenance}>RunBlox marketplace is undergoing scheduled maintenance, we will be back
          soon.</h1>
      </div>}

    </>
  );
};

export default Marketplace;
