import React from 'react';
import styles from './noResult.module.css';
import { ReactComponent as NoResultIcon } from 'assets/icons/icon-class.svg';

export interface NoResultProps {
  className?: string;
}

const NoResult: React.FC<NoResultProps> = ({ className = '' }) => {
  return (
    <section className={`${className} ${styles.noResult}`}>
      <NoResultIcon />
      <div className={styles.noResultInfo}>No items to display</div>
    </section>
  );
};

export default NoResult;
