export const TestTag = '@@auth/TestTag';
export const ActionTypes = {
  TestTag
} as const;

export interface MainResponse {
  data: string;
  filter: string;
}
export type ActionMainRequest = {
  type: typeof TestTag;
};
