import React, { useEffect, ReactNode } from 'react';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Success } from 'assets/icons/success.svg';
import { ReactComponent as Error } from 'assets/icons/error.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Loading } from 'assets/icons/loading.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import styles from './message.module.css';

export type NoticeType = 'info' | 'success' | 'error' | 'warning' | 'loading';

export interface NoticeProps {
  type: NoticeType;
  content: ReactNode;
  duration: number;
  onClose: () => void;
}

const typeToIcon = {
  info: Info,
  success: Success,
  error: Error,
  warning: Warning,
  loading: Loading
};

const Notice: React.FC<NoticeProps> = ({
  content,
  type,
  duration,
  onClose
}) => {
  const IconComponent = typeToIcon[type];

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (duration) {
      timer = setTimeout(onClose, duration * 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  });

  const canClose = duration === 0 || duration > 5;

  return (
    <div className={`${styles['message-notice']} ${styles[type]}`}>
      {IconComponent && <IconComponent className={styles.icon} />}
      <span className={styles['message-notice-text']}>{content}</span>
      {canClose && (
        <button onClick={onClose} className={styles.close}>
          <Close className={styles.closeIcon} />
        </button>
      )}
    </div>
  );
};
export default Notice;
