import { FC } from 'react';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './mint.module.css';

interface Props {
  max?: number;
  value: number;
}

const Mint: FC<Props> = ({ max = 7, value }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.mint}>
      <div className={styles.label}>
        <span className={styles.text}>{t('stats.mint')}</span>
        <span className={styles.value}>
          {value}/{max}
        </span>
      </div>
      <div className={styles.bar}>
        {Array.from(new Array(max), (_, i) => i + 1).map(i => (
          <span key={i} className={cls({ [styles.active]: i <= value })}></span>
        ))}
      </div>
    </div>
  );
};

export default Mint;
