import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import cls from 'clsx';
import { useUserWeb3 } from '@moverfinance/web3';
import styles from './header.module.css';
import { ExternalLink, WalletV2 } from 'components';
import { useWindowEvent } from 'hooks/useWindowEvent';
import { ReactComponent as Obx } from 'assets/icons/token/obx.svg';
import { ReactComponent as Arbitrum } from 'assets/icons/token/arbitrum_grey.svg';
import { ReactComponent as Avax } from 'assets/icons/token/avax_grey.svg';
import { ReactComponent as Runblox } from 'assets/icons/token/runblox.svg';
import { ReactComponent as Logo } from 'assets/icons/logo-v2.svg';
import { priceRequest, TypePriceRequest } from 'store/price/actions';
import Account from './Account';

interface HeaderProps {
  priceRequest: TypePriceRequest;
}

const Header: React.FC<HeaderProps> = ({ priceRequest }) => {
  const { active, error } = useUserWeb3();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(open => !open);
  };

  const isDownloadView = useMemo(
    () => location.pathname === '/download',
    [location]
  );

  useWindowEvent('resize', () => {
    if (!window.matchMedia('(max-width: 768px)').matches) {
      if (open) {
        setOpen(false);
      }
    }
  });

  useEffect(() => {
    priceRequest();
  }, [priceRequest]);

  useEffect(() => {
    if (error) {
      hiddenMenu();
    }
  }, [error]);

  const hiddenMenu = () => {
    setOpen(open => !open);
  };

  useEffect(() => {
    if (open) {
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [open]);

  return (
    <div className={styles.header}>
      <div className={styles.inner}>
        <div className={styles.nav}>
          <ExternalLink to="https://runblox.io" className={styles.navLogo}>
            <Logo className={styles.navLogoIcon} />
          </ExternalLink>
          <NavLink
            className={styles.navItem}
            activeClassName={styles.navItemActive}
            to={'/assets'}
          >
            Marketplace
          </NavLink>
          <ExternalLink
            className={styles.navItem}
            to="https://runblox.io/howToUse.html"
          >
            How To Use
          </ExternalLink>
          {!isDownloadView && (
            <NavLink
              className={styles.navItem}
              activeClassName={styles.navItemActive}
              to={'/download'}
            >
              Go to Arbitrum
            </NavLink>
          )}
        </div>
        <button className={styles.menu} onClick={toggleMenu}>
          <span
            className={cls(styles.menuIcon, {
              [styles.open]: open
            })}
          ></span>
        </button>
        <div
          className={cls(styles.info, {
            [styles.open]: open
          })}
        >
          <NavLink
            className={styles.infoItem}
            to={'/assets'}
            onClick={() => setOpen(false)}
          >
            Marketplace
          </NavLink>
          <ExternalLink
            className={styles.infoItem}
            to="https://runblox.io/howToUse.html"
          >
            How To Use
          </ExternalLink>
          {!isDownloadView && (
            <NavLink
              className={styles.infoItem}
              to={'/download'}
              onClick={() => setOpen(false)}
            >
              Go to Arbitrum
            </NavLink>
          )}
          <ExternalLink
            to="https://traderjoexyz.com/avalanche/trade?inputCurrency=0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7&outputCurrency=0xa1afcc973d44ce1c65a21d9e644cb82489d26503"
            className={styles.infoLink}
          >
            <span>RUX</span>
            <div className={styles.infoIconWrapper}>
              <Runblox className={styles.infoIcon} />
              <Avax className={cls(styles.infoIcon, styles.offset)} />
            </div>
          </ExternalLink>
          <ExternalLink
            to="https://traderjoexyz.com/arbitrum/trade?inputCurrency=0xc701f86e6d242b97d3035f6c2cecaf8e7087e898&outputCurrency=0xff970a61a04b1ca14834a43f5de4533ebddb5cc8"
            className={styles.infoLink}
          >
            <span>RUX</span>
            <div className={styles.infoIconWrapper}>
              <Runblox className={styles.infoIcon} />
              <Arbitrum className={cls(styles.infoIcon, styles.offset)} />
            </div>
          </ExternalLink>
          <ExternalLink
            to="https://openblox.io/obx-page"
            className={styles.infoLink}
          >
            <span>Get OBX</span>
            <Obx className={styles.infoIcon} />
          </ExternalLink>
          {active ? <Account /> : !isDownloadView && <WalletV2 />}
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = {
  priceRequest
};
export default connect(null, mapDispatchToProps)(Header);
