import { ActionTypes } from './actionsTypes';

const homeReduces = (
  state = { data: '' },
  action: { type: any; data: any }
) => {
  switch (action.type) {
    case ActionTypes.TestTag:
      return {
        data: action.data
      };
    default:
      return state;
  }
};
export default homeReduces;
