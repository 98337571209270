import { BigNumber } from '@ethersproject/bignumber';
import { hexStripZeros } from '@ethersproject/bytes';
import { INFO } from 'constants/chains';

export const addNetwork = async (chainId: number) => {
  const info = INFO[chainId];
  if (!window?.ethereum?.request) return;
  const formattedChainId = hexStripZeros(BigNumber.from(chainId).toHexString());
  try {
    await window?.ethereum?.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: formattedChainId,
          chainName: info.name,
          rpcUrls: info.rpcUrls,
          nativeCurrency: info.nativeCurrency,
          blockExplorerUrls: [info.explorerUrl]
        }
      ]
    });
  } catch (error) {
    console.error('error add eth network: ', chainId, info, error);
    return false;
  }
};
export const switchToNetwork = async (chainId?: number,library?:any) => {
  const formattedChainId = hexStripZeros(BigNumber.from(chainId).toHexString());
  if (!window?.ethereum?.request && library) {
    try {
      const network = chainId || '0';
      await library.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network }]
      });
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          const info = INFO[chainId ?? 0];
          await library.provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: formattedChainId,
                chainName: info?.name,
                rpcUrls: info?.rpcUrls,
                nativeCurrency: info?.nativeCurrency,
                blockExplorerUrls: [info?.explorerUrl]
              }
            ]
          });
        } catch (error) {
          console.error('error switch eth network: ', chainId, error);
        }
      }
    }
  } else if (window?.ethereum?.request) {
    try {
      await window?.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: formattedChainId }]
      });
    } catch (error: any) {
      // 4902 (-32603 is mobile error code) is the error code for attempting to switch to an unrecognized chainId
      if (
        (error.code === 4902 || error.code === -32603) &&
        chainId !== undefined
      ) {
        // metamask (only known implementer) automatically switches after a network is added
        // the second call is done here because that behavior is not a part of the spec and cannot be relied upon in the future
        // metamask's behavior when switching to the current network is just to return null (a no-op)
        const result=await addNetwork(chainId);
        if (!result) return;
        await switchToNetwork(chainId, library);
      } else {
        console.error('error switch eth network: ', chainId, error);
      }
    }
  }else {
    return "error";
  }
}
export const isMainnet =process.env.REACT_APP_ACTIVED_KEY === 'ETH' || process.env.REACT_APP_ACTIVED_KEY === 'ARBITRUM';
