import {
  PriceState,
  ActionPriceRequest,
  ActionPriceSuccessed,
  ActionPriceFailed,
  ActionTypes
} from './types';
import { Reducer } from 'redux';

export const initialState: PriceState = {
  price: 0,
  loading: false
};

type Actions = ActionPriceRequest | ActionPriceSuccessed | ActionPriceFailed;

const priceReducer: Reducer<PriceState, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.PRICE_REQUEST: {
      return { ...state, loading: true };
    }
    case ActionTypes.PRICE_SUCCESSED: {
      return { ...state, loading: false, ...action.data };
    }
    case ActionTypes.PRICE_FAILED: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};

export { priceReducer };
