import moment from 'moment';
import { RUNBLOX_ADDRESS } from 'constants/address';

export const speeds: { [key: string]: string } = {
  flower: '2-7',
  insect: '2-7',
  shark: '5-11',
  hardshell: '5-11',
  techno: '7-20',
  dragon: '7-20',
  spaceman: '2-20',
}

export const canBuy = (id: number | string, birthday: string) => {
  //10001-20000之间的id的nft从出生90天之内不能买卖
  const ms = parseInt(birthday) * 1000;
  return !(moment(ms).add(90, 'days').valueOf() >= moment().valueOf() && id > 10000 && id <= 20000);
};

export const isColdWallet = (owner: string) => {
  return owner?.toLowerCase() === RUNBLOX_ADDRESS?.toLowerCase();
};

export const replaceColdWalletAddress = () => {
  return "RunBlox";
};

export const setCardBackground = (backgroundColor: any) => {
  if (backgroundColor.indexOf('http') >= 0) {
    return { backgroundImage: 'url(' + backgroundColor + ')' };
  }
  const bg = backgroundColor.indexOf('#') < 0 ? '#' + backgroundColor : backgroundColor;
  return { backgroundColor: bg };
};
