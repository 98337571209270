import { useCallback, useEffect, useState } from 'react';
import { useTryConnect, useUserWeb3, WalletConnectConnector } from '@moverfinance/web3';
import { ContractReceipt } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';
import {
  useAvaxContract,
  useRealmCoreContract,
  useRuxContract,
  useOBXContract
} from 'hooks/useContract';
import { useApprove as useApproveRealmCore, useIsApproved as useIsApprovedRealmCore } from 'hooks/useApprove';
import { BIND_WALLET_SIGNATURE, LOGIN_WITH_WALLET_SIGNATURE, MIGRATION_SIGNATURE } from 'constants/runBlox';
import moment from 'moment-timezone';
import { injected, walletconnect } from 'connectors/injected';
import { RUNBLOX_ADDRESS } from 'constants/address';
import { parseEther } from '@ethersproject/units';

export const CONTRACT_MARKETPLACE = 0;
export const CONTRACT_NFT = 1;
export const CONTRACT_RUX = 2;
export const CONTRACT_OBX = 3;

export const contracts = [
  CONTRACT_MARKETPLACE,
  CONTRACT_NFT,
  CONTRACT_RUX,
  CONTRACT_OBX
];

const getBindWalletSig = (email: string, datetime: string) => {
  return BIND_WALLET_SIGNATURE.replace('{email}', email).replace(
    '{datetime}',
    datetime
  );
};
const getMigrationSig = (address: string, datetime: string) => {
  return MIGRATION_SIGNATURE.replace('{address}', address).replace(
    '{datetime}',
    datetime
  );
};

const getLoginWithWalletSig = (datetime: string) => {
  return LOGIN_WITH_WALLET_SIGNATURE.replace('{datetime}', datetime);
};

export const useRUXBalance = (reloadBalance: boolean) => {
  const ruxContract = useRuxContract();
  const { account } = useUserWeb3();
  const [balance, setBalance] = useState(BigNumber.from('0'));
  useEffect(() => {
    const contract = ruxContract();
    contract.balanceOf(account || '').then((balance: BigNumber) => {
      setBalance(balance);
    });
  }, [ruxContract, account, reloadBalance]);
  return balance;
};

export const useOBXBalance = (reloadBalance: boolean) => {
  const obxContract = useOBXContract();
  const { account } = useUserWeb3();
  const [balance, setBalance] = useState(BigNumber.from('0'));
  useEffect(() => {
    const contract = obxContract();
    contract.balanceOf(account || '').then((balance: BigNumber) => {
      setBalance(balance);
    });
  }, [obxContract, account, reloadBalance]);
  return balance;
};

const useSigner = () => {
  const { library } = useUserWeb3();
  return useCallback(async () => {
    if (!library) throw new Error('library is not found');
    return await library.getSigner();
  }, [library]);
};

export const useApprove = (address: string) => {
  const avaxContract = useAvaxContract();
  return useCallback(async () => {
    const contract = avaxContract();
    const tx = await contract.setApprovalForAll(address, true);
    const receipt = await tx.wait();

    return receipt as ContractReceipt;
  }, [avaxContract, address]);
};

export const useIsApproved = (address: string) => {
  const { active, account } = useUserWeb3();
  const avaxContract = useAvaxContract();
  return useCallback(async () => {
    if (!active || !account) return false;
    const contract = avaxContract();
    return (await contract.isApprovedForAll(account, address)) as boolean;
  }, [active, account, avaxContract, address]);
};

/**
 * approve contract
 *
 * @param address account
 * @param abstractContract Contract hook
 * @version beta
 */
export const useCommonApprove = () => {
  return useCallback(async (address: string, abstractContract: any, amount: number | string) => {
    const contract = abstractContract(), tx = await contract.approve(address, amount), receipt = await tx.wait();
    return receipt as ContractReceipt;
  }, []);
};

/**
 * if approve contract
 *
 * @param address account
 * @param abstractContract Contract hook
 * @version beta
 */
export const useCommonIsApproved = (address: string, abstractContract: any) => {
  const { active, account } = useUserWeb3();
  return useCallback(async () => {
    if (!active || !account) return false;
    const contract = abstractContract();
    return (await contract.isApprovedForAll(account, address)) as boolean;
  }, [active, account, abstractContract, address]);
};

export const useDeposit = () => {
  const tryConnect = useTryConnect();
  const { account, connector, library } = useUserWeb3();
  const ruxContract = useRuxContract();
  const obxContract = useOBXContract();
  const realmCoreContract = useRealmCoreContract();
  const depositAvax = useDepositAvax();

  // deposit amount
  const deposit = useCallback(async (onStep: (steps: boolean[]) => void, amounts: string, type: string) => {
    await tryConnect(connector instanceof WalletConnectConnector ? walletconnect : injected);
    onStep([true, true, false]);
    if (type === 'RUX') {
      const gasLimit = await ruxContract().estimateGas.transfer(RUNBLOX_ADDRESS, parseEther(amounts));
      const gasPrice = await library?.getGasPrice();
      return ruxContract().transfer(RUNBLOX_ADDRESS, parseEther(amounts), {
        gasLimit: gasLimit,
        gasPrice: gasPrice
      });
    } else if (type === 'OBX') {
      const gasLimit = await obxContract().estimateGas.transfer(RUNBLOX_ADDRESS, parseEther(amounts));
      const gasPrice = await library?.getGasPrice();
      return obxContract().transfer(RUNBLOX_ADDRESS, parseEther(amounts), {
        gasLimit: gasLimit,
        gasPrice: gasPrice
      });
    } else if (type === 'NFT') {
      return realmCoreContract().transferFrom(account || '', RUNBLOX_ADDRESS, amounts);
    } else if (type === 'AVAX') {
      return depositAvax(amounts);
    }

  }, [tryConnect, ruxContract, obxContract, account, connector, depositAvax, realmCoreContract, library]);

  return { deposit };
};

export const useDepositAvax = () => {
  const { account, chainId, library } = useUserWeb3();
  return useCallback(async (amounts: string) => {
    const transactionParameters = {
      gas: '0x5208', // mobile metamask mandatory
      to: RUNBLOX_ADDRESS, // Required except during contract publications.
      from: account, // must match user's active address.
      value: parseEther(amounts).toHexString(), // Only required to send ether to the recipient from the initiating external account.
      chainId: chainId // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
    };
    const promise = await new Promise<string>((resolve, reject) => {
      // @ts-ignore
      library?.provider?.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters]
      }).then(d => {
        return resolve(d);
      }).catch(err => {
        return reject(err);
      });
    });
    return promise;
  }, [account, chainId, library]);

};

export const useWithdraw = () => {
  const tryConnect = useTryConnect();
  const approve = useApproveRealmCore();
  const isApproved = useIsApprovedRealmCore();
  useCallback(async (onStep: (steps: boolean[]) => void, amount: string | number) => {
    const approved = await isApproved();
    await tryConnect(injected);
    onStep([true, false, false]);
    if (!approved) await approve();
    onStep([true, true, false]);
    // return await signTypedData(amount);
    return null;
  }, [isApproved, approve, tryConnect]);

};

export const useSignTypedData = () => {

};

export const useBindWallet = () => {
  const getSigner = useSigner();
  return useCallback(
    async (email: string) => {
      const datetime = moment()
        .add(60, 'seconds')
        .tz('GMT')
        .format('yyyy-MM-DD HH:mm:ssZ');
      const signer = await getSigner();
      const sigMessage = getBindWalletSig(email, datetime);
      const sig = await signer.signMessage(sigMessage);
      console.log(sig);
      return {
        signature: sig,
        expiry: datetime
      };
    },
    [getSigner]
  );
};

export const useLoginWithWallet = () => {
  const getSigner = useSigner();
  return useCallback(async () => {
    const datetime = moment()
      .add(60, 'seconds')
      .tz('GMT')
      .format('yyyy-MM-DD hh:mm:ssZ');
    const signer = await getSigner();
    const sigMessage = getLoginWithWalletSig(datetime);
    return {
      signature: await signer.signMessage(sigMessage),
      expiry: datetime
    };
  }, [getSigner]);
};

export const useMigration = () => {
  const getSigner = useSigner();
  return useCallback(
    async (address: string) => {
      const datetime = moment()
        .add(60, 'seconds')
        .tz('GMT')
        .format('yyyy-MM-DD HH:mm:ssZ');
      const signer = await getSigner();
      const sigMessage = getMigrationSig(address, datetime);
      const sig = await signer.signMessage(sigMessage);
      return {
        signature: sig,
        expiry: datetime
      };
    },
    [getSigner]
  );
};
