import axios from '../axios';
import { PaginationParams } from './types';

export interface NFTRequest extends PaginationParams {
  nftAddress: string;
  tokenId?: string | number;
  owner?: string;
  rarity?: string;
  race?: string;
  reproduction?: number;
  legendary?: number;
}

export interface OrderStatus {
  onsale: boolean;
  orderId?: string;
  price?: string;
  lastPrice?: string;
}
// export interface Info {
//   class: string;
//   generation: number;
//   genes: string;
// }
export interface Properties {
  arm: string;
  back: number;
  body: string;
  bornAt: string;
  generation: number;
  level?: number;
  mintTimes?: number;
  speed?: number;
  luck?: number;
  attack?: number;
  hitPoints?: number;
  durability?: number;
  genes: string;
  head: string;
  horn: string;
  leg: string;
  legendary: number;
  race: string;
  rarity: 'common' | 'rare' | 'epic' | 'hero' | 'legendary';
  reproduction: number;
  parents: number[];
  birthday?: string;
}

export interface NFT {
  _id: string;
  tokenId: number;
  owner: string;
  nftAddress: string;
  order: OrderStatus;
  properties: Properties;
  imageUrl: string;
  imageUri?: string;
  backgroundColor?:string
}

export interface NFTResponse {
  nfts: NFT[];
  count: number;
}

export const listNFTs = (params: NFTRequest): Promise<NFTResponse> => {
  return axios.get('nft', {
    params
  });
};
