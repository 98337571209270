import { FC } from 'react';
import cls from 'classnames';
import styles from './attribute.module.css';

interface Props {
  label: string;
  value: number | null;
  max?: number;
  color: string;
  className?: string;
}

const Attribute: FC<Props> = ({
  label,
  value,
  max = 100,
  color,
  className
}) => (
  <div className={cls(styles.attribute, className)}>
    <p className={styles.label}>
      <i className={styles.icon} style={{ backgroundColor: color }} />
      <span className={styles.text}>{label}</span>
      <span className={styles.value}>{value ? value : '--'}</span>
    </p>
    <div className={styles.bar}>
      <div
        style={{
          backgroundColor: color,
          width: value ? `${(value / max) * 100}%` : 0
        }}
      />
    </div>
  </div>
);

export default Attribute;
