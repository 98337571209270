import mixpanel from 'mixpanel-browser';

mixpanel.init('1c48556a26df85f5923e53aa6d122a95', {
  ignore_dnt: true
});

export const trackName = {
  CONNECT_WALLET: 'Connect Wallet',
  CLICK_MY_ACCOUNT: 'Click My Account',
  CLICK_CLASS_FILTER: 'Click Class Filter',
  RARITY_ON_CHANGE: 'Rarity on change',
  CLICK_ITEM: 'Click Item',
  FILTER_ON_CHANGE: 'Filter on change',
  SORT_ON_CHANGE: 'Sort on change',
  CLICK_BUY_NOW: 'Click Buy Now',
  CLICK_BUY: 'Click Buy',
  CLICK_ITEM_IN_INVENTORY: 'Click Item in inventory',
  CLICK_OWNER_ADDRESS: 'Click owner address',
  CLICK_SELL: 'Click Sell',
  CLICK_POSTING_FOR_SELL: 'Click Posting for sell',
  CLICK_EMAIL_LOGIN: 'Click Email Login',
  CLICK_ACCOUNT_DEPOSIT: 'Click Account Deposit',
  CLICK_ACCOUNT_WITHDRAW: 'Click Account Withdraw',
  CLICK_WATCH_ASSET: 'Click Watch Asset',
  CLICK_TRANSFER: 'Click Transfer',
  FOCUS_TRANSFER_TO_INPUT: 'Focus Transfer To Input'
};
export const track = (eventName: string, attributes?: {}) => {
  mixpanel.track(eventName, {
    ...attributes
  });
};
