import { InjectedConnector } from '@moverfinance/web3';
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { CHAIN_IDS, WALLETCONNECT_RPC_URL } from 'constants/chains';

export const injected = new InjectedConnector({
  supportedChainIds: [CHAIN_IDS[process.env.REACT_APP_ACTIVED_KEY as string]]
});

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: [CHAIN_IDS[process.env.REACT_APP_ACTIVED_KEY as string]],
  chainId: CHAIN_IDS[process.env.REACT_APP_ACTIVED_KEY as string],
  rpc: WALLETCONNECT_RPC_URL,
  qrcodeModalOptions: {
    mobileLinks: [
      "metamask"
    ],
    desktopLinks: [
      "metamask"
    ],
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true
});
