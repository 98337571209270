import React, { useMemo } from 'react';
import { ReactComponent as Setting } from 'assets/icons/setting.svg';
import { ReactComponent as Transfer } from 'assets/icons/transfer.svg';

import styles from './accountTips.module.css';
import { useAuthLogin } from '../../Account/AuthLogin';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';

/**
 * basic slider menu
 * process: if login => if pair => get token => get profile
 *
 * @see {@link Account}
 *
 * @param props basic menu props
 * @constructor
 */
const AccountTips: React.FC<any> = ({ history }) => {
  const { t } = useTranslation();
  const { isLogin, isPaired, needForceBind, isMigration, handlePair, handleForcePair, showLogin, setShowLogin } =
    useAuthLogin();
  const showTips = useMemo(() => {
    return !isLogin || !isPaired;
  }, [isLogin, isPaired]);

  const tipsText = useMemo(() => {
    if (!isLogin) {
      return 'Login in your account to check your in-game asset';
    } else if (!isPaired) {
      if (needForceBind) {
        return 'Please confirm to force pair your account with your wallet';
      }
      return 'Complete setting up your account';
    } else {
      return '';
    }
  }, [isLogin, isPaired, needForceBind]);

  const handleMigration = () => {
    history.push({
      pathname: '/migration'
    });
  };

  let button;
  if (!isLogin) {
    button = <button className={styles.btn} onClick={() => setShowLogin(true)}>
      LOGIN
    </button>
  } else {
    if (needForceBind) {
      button = <button className={styles.btn} onClick={handleForcePair}>
        FORCE PAIR YOUR ACCOUNT
      </button>
    } else {
      button = <button className={styles.btn} onClick={handlePair}>
        PAIR TO YOUR ACCOUNT
      </button>
    }
  }

  return (
    <>
      {showTips && (
        <section className={styles.tips}>
          <div className={styles.textWrapper}>
            <Setting className={styles.icon} />
            <p className={styles.text}>{tipsText}</p>
          </div>
          {button}
        </section>
      )}
      {isLogin && isPaired &&
      <section className={styles.tips}>
        <div className={styles.textWrapper}>
          <Transfer className={styles.icon}/>
          <p className={styles.text}>{isMigration ? 'Transfer is complete' : 'Migrate your assets to your new wallet address'}</p>
        </div>
        <button className={styles.btn} onClick={handleMigration} disabled={isMigration}>
          {t('views.migration.submit')}
        </button>
      </section>
      }
      <Modal
        visible={showLogin}
        title={'Pair to your account'}
        onClose={() => setShowLogin(false)}
      >
        <div>
          <div id={'firebaseui-auth-container'}></div>
        </div>
      </Modal>
    </>
  );
};

export default AccountTips;
