import React, { useEffect, useState } from 'react';
import styles from './wallet_connect_component.module.css';
import { Button, Radio } from 'components';
import { useUserWeb3, isMobile } from '@moverfinance/web3';
import { UnsupportedChainIdError } from '@web3-react/core';
import { injected, walletconnect } from '../../connectors/injected';
import { ReactComponent as MetaMask } from '../../assets/icons/metamask.svg';
import { ReactComponent as WalletConnectSVG } from '../../assets/icons/walletconnect.svg';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
interface WalletConnectComponentProps {
  name: string;
  onChange?: () => void;
}

function WalletConnectComponent(props: WalletConnectComponentProps) {
  const {
    name,
    onChange,
  } = props;
  const {
    activate,
  } = useWeb3React();
  const { error } = useUserWeb3();
  const { t } = useTranslation();
  const [walletPrivacyPolicyCheckAble, setWalletPrivacyPolicyCheckAble] = useState("");
  const walletPrivacyPolicyCheckAbleHandle = (value: string) => {
    setWalletPrivacyPolicyCheckAble(value === walletPrivacyPolicyCheckAble ? '' : value);
    onChange && onChange();
  }
  useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      setWalletPrivacyPolicyCheckAble('');
    }
  }, [error]);
  return (
    <div className={styles.walletConnectModalInner}>
      <div className={styles.walletConnectModalInnerInfo}>
        <Radio name={'walletPrivacyPolicyCheckAble'+name} value={'true'} onChange={(v)=>walletPrivacyPolicyCheckAbleHandle(v)}
               disabled={error instanceof UnsupportedChainIdError ? true : false}
               checked={walletPrivacyPolicyCheckAble === '' ? false : true} className={styles.walletConnectModalInnerInfoRadio}></Radio>
        <div className={styles.walletConnectModalInnerInfoDescription}>I read and accept RunBlox’ <a target = "_blank" rel="noopener noreferrer" href={"https://runblox.io/terms.html"}>Terms of Service</a> and <a target = "_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/30870666">Privacy Policy</a>.</div>
      </div>

      <Button
        className={styles.walletConnectModalInnerWallet}
        mode={walletPrivacyPolicyCheckAble === ''?"disabled":'normal'}
        disabled={walletPrivacyPolicyCheckAble === ''}
        onClick={() => {
          if (!window.ethereum && isMobile) {
            window.open(`https://metamask.app.link/dapp/${document.domain}`);
          } else if (!window.ethereum) {
            window.open(`https://metamask.io/download.html`);
          } else {
            activate(injected);
            window.localStorage.setItem('provider', 'injected');
          }
        }}
      >
        <MetaMask></MetaMask>{(window.ethereum || isMobile) ? t('common.metaMaskWallet') : t('common.installMetaMask')}
      </Button>
      <Button
        className={styles.walletConnectModalInnerWallet}
        mode={walletPrivacyPolicyCheckAble === ''?"disabled":'normal'}
        disabled={walletPrivacyPolicyCheckAble === ''}
        onClick={() => {
          walletconnect.walletConnectProvider = undefined;
          activate(walletconnect);
          window.localStorage.setItem('provider', 'walletConnect');
        }}
      >
        <WalletConnectSVG></WalletConnectSVG>{t('common.walletConnectWallet')}
      </Button>
    </div>
  );
}

export default WalletConnectComponent;
