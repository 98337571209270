import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode;
  getContainer?: () => HTMLElement;
}

const Portal: FC<PortalProps> = ({ children, getContainer }) => {
  const container = getContainer ? getContainer() : document.body;
  return createPortal(children, container);
};

export default Portal;
