import axios from '../axios';
import { PaginationParams } from './types';

interface ActivityByAddressRequest extends PaginationParams {
  address: string;
}

interface ActivityByNFTRequest extends PaginationParams {
  nftAddress: string;
  tokenId: string;
}

interface ListActivityRequest extends PaginationParams {
  event?: string;
}

type Event = 'born' | 'buy' | 'cancel' | 'sale' | 'transfer';

export interface Activity {
  _id: string;
  address: Array<string>;
  event: Event;
  nftAddress: string;
  from?: string;
  item?: string;
  qty?: string;
  to?: string;
  price?: string;
  tokenId: string;
  imageUrl?: string;
  ts: string;
}

export interface ActivityResponse {
  activities: Activity[];
  count: number;
}

export const listActivitiesByAddress = (
  params: ActivityByAddressRequest
): Promise<ActivityResponse> => {
  return axios.get('activity/address', {
    params
  });
};

export const listActivitiesByNFT = (
  params: ActivityByNFTRequest
): Promise<ActivityResponse> => {
  return axios.get('activity/nft', {
    params
  });
};

export const listActivities = (
  params: ListActivityRequest
): Promise<ActivityResponse> => {
  return axios.get('activity/list', {
    params
  });
};
