import axios from '../axios';
import { RUNBLOX_API_HOST, RUNBLOX_MP_API_HOST } from 'constants/address';
import { PaginationParams } from './types';

export interface ShoeBoxRequest extends PaginationParams {
  token?: string;
}

export interface BindWalletRequest {
  token: string;
  wallet?: string | number;
  expiry?: string;
  signature?: string;
  force?: boolean;
}

export interface GetBalanceRequest {
  token: string;
}

export interface ClaimCoinRequest {
  token_name: string;
  coins: number;
  token: string;
}

export interface ClaimNFTRequest {
  token: string;
  token_id: string;
  token_name: string;
}

export interface BindWalletResponse {
  success: boolean;
  existing_email: string;
  existing_wallet: string;
}

export interface GetBalanceResponse {
  wallet: string;
  tokens: object;
}
export interface ClaimCoinResponse {
  task_id: string;
}

export interface ClaimNFTResponse {
  task_id: string;
}

export interface ShoesBox {
  id: number;
  image_uri: string;
  image_url: string;
  listed: boolean;
  race: string;
  background_color: string;
}

export interface ShoesBoxResponse {
  shoes: ShoesBox[];
  shoe_boxes: ShoesBox[];
}

export const bindWallet = (
  params: BindWalletRequest
): Promise<BindWalletResponse> => {
  axios.defaults.headers.post['Authorization'] = 'bearer ' + params.token;
  console.log(params);
  return axios.post(RUNBLOX_API_HOST + 'v1/bind_wallet', {
    ...params
  });
};

export const unbindWallet = (
  params: BindWalletRequest
): Promise<BindWalletResponse> => {
  axios.defaults.headers.post['Authorization'] = 'bearer ' + params.token;
  return axios.post(RUNBLOX_MP_API_HOST + 'mp/v1/unbind-wallet');
};

export const getBalance = (
  params: GetBalanceRequest
): Promise<GetBalanceResponse> => {
  axios.defaults.headers.get['Authorization'] = 'bearer ' + params.token;
  return axios.get(RUNBLOX_MP_API_HOST + 'mp/v1/balance', {});
};

export const claimCoin = (
  params: ClaimCoinRequest
): Promise<ClaimCoinResponse> => {
  axios.defaults.headers.post['Authorization'] = 'bearer ' + params.token;
  return axios.post(RUNBLOX_MP_API_HOST + 'mp/v1/claim-coin', { ...params });
};

export const listShoes = (
  params: ShoeBoxRequest
): Promise<ShoesBoxResponse> => {
  axios.defaults.headers.get['Authorization'] = 'bearer ' + params.token;
  return axios.get(RUNBLOX_API_HOST + 'v1/shoes', { params });
};

export const listShoeBox = (
  params: ShoeBoxRequest
): Promise<ShoesBoxResponse> => {
  axios.defaults.headers.get['Authorization'] = 'bearer ' + params.token;
  return axios.get(RUNBLOX_API_HOST + 'v1/shoebox', { params });
};


export const migrate = (
  params: BindWalletRequest
): Promise<BindWalletResponse> => {
  axios.defaults.headers.post['Authorization'] = 'bearer ' + params.token;
  return axios.post(RUNBLOX_API_HOST + 'v1/migrate', { ...params });
};

