import { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ModalV2 } from 'components';
import { ReactComponent as Arbitrum } from 'assets/icons/token/arbitrum.svg';
import { ReactComponent as Avax } from 'assets/icons/token/avax.svg';
import styles from './select-chain.module.css';

const SelectChainView = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('selectChain') !== 'true') {
      setVisible(true);
    }
  }, []);

  const chainSelected = useCallback(() => {
    setVisible(false);
    sessionStorage.setItem('selectChain', 'true');
  }, []);

  return (
    <ModalV2
      visible={visible}
      title={'Select Chains'}
      onClose={setVisible}
      className={styles.chain}
    >
      <div className={styles.body}>
        <NavLink
          className={styles.bodyButton}
          to="/download"
          onClick={chainSelected}
        >
          <Arbitrum className={styles.bodyIcon} />
          <span>Arbitrum</span>
        </NavLink>
        <NavLink
          className={styles.bodyButton}
          to="/assets"
          onClick={chainSelected}
        >
          <Avax className={styles.bodyIcon} />
          <span>Avalanche</span>
        </NavLink>
      </div>
    </ModalV2>
  );
};

export default SelectChainView;
