import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './level.module.css';

interface Props {
  value: number;
  progress: number;
}

const Level: FC<Props> = ({ value, progress }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.level}>
      <div className={styles.label}>
        {t('stats.level')} <span>{value}</span>
      </div>
      <div className={styles.bar}>
        <div style={{ width: `${progress * 100}%` }} />
      </div>
    </div>
  );
};

export default Level;
