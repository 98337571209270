import { useImperativeHandle, useState } from 'react';
import styles from './wallet_connect_modal.module.css';
import { Modal } from 'components';
import WalletConnectComponent from "components/Wallet/WalletConnectComponent";
import React from 'react';

const WalletConnectModal= React.forwardRef(function WalletConnectModal(props,ref){
  const [showModal, setShowModal] = useState(false);
  useImperativeHandle(ref, () => ({
    showWalletConnectModal: () => {
      setShowModal(true);
    },
    hideWalletConnectModal: () => {
      console.log("close modal");
      setShowModal(false);
    }
  }));

  return (
    <Modal
      visible={showModal}
      title={'Connect to wallet'}
      className={styles.walletConnectModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <WalletConnectComponent name={"modal"}></WalletConnectComponent>
    </Modal>
  );
});
export default WalletConnectModal;
