import React, { useCallback, useEffect, useState } from 'react';
import { BaseMenuProps, MenuDataItem } from '../typings';
import styles from './slider.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import { track, trackName } from 'vendor/mixpanel';

/**
 * basic slider menu
 *
 * @param props basic menu props
 * @constructor
 */
const SliderMenu: React.FC<BaseMenuProps> = props => {
  const location = useLocation();

  const { menuData, className: cls = styles.menuItem } = props;
  const [menuItem, setMenuItem] = useState<MenuDataItem>({
    path: location.pathname
  });
  useEffect(() => {
    if (location.pathname) {
      setMenuItem({ path: location.pathname });
    }
  }, [location.pathname]);

  const liClick = useCallback((item: MenuDataItem, i: number) => {
    if(item?.name==='Transfer'){
      track(trackName.CLICK_TRANSFER);
    }
    setMenuItem({ ...item });
  }, []);

  return (
    <aside className={styles.layoutSlider}>
      <ul>
        {menuData?.map((it, i) => (
          <li
            key={'m' + i}
            className={`${cls} ${
              menuItem && menuItem.path === it.path
                ? styles.menuItemSelected
                : ''
            }`}
          >
            <span onClick={liClick.bind(this, it, i)}>
              <NavLink to={it.path}>{it.name}</NavLink>
            </span>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default SliderMenu;
