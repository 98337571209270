import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { runCallback } from 'utils';

interface MaskProps {
  visible: boolean;
  children: React.ReactNode;
  onClose?: (visible: boolean) => void;
}

const Mask: React.FC<MaskProps> = ({ visible, children, onClose }) => {
  const nodeRef = React.useRef(null);
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={visible}
      unmountOnExit
      timeout={300}
      classNames="mask"
    >
      <div
        className={'mask'}
        ref={nodeRef}
        onClick={() => runCallback(onClose, false)}
      >
        {children}
      </div>
    </CSSTransition>
  );
};

export default Mask;
