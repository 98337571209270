import axios from '../axios';

export interface CategoryResponse {
  nftAddress: string;
  name: string;
  symbol: string;
  supply: number;
  owner: string;
  holders: number;
  floorPrice: number;
  floorPriceHex: number;
  volume: number;
  volumeHex: number;
}

export const category = (): Promise<CategoryResponse> => {
  return axios.get('category');
};
