import { PriceResponse } from 'services';

export const PRICE_REQUEST = '@@auth/priceRequest';
export const PRICE_SUCCESSED = '@@auth/priceSuccessed';
export const PRICE_FAILED = '@@auth/priceFailed';

export const ActionTypes = {
  PRICE_REQUEST,
  PRICE_SUCCESSED,
  PRICE_FAILED
} as const;

export type ActionPriceRequest = {
  type: typeof PRICE_REQUEST;
};

export type ActionPriceSuccessed = {
  type: typeof PRICE_SUCCESSED;
  data: PriceResponse;
};

export type ActionPriceFailed = {
  type: typeof PRICE_FAILED;
};

export interface PriceState {
  price: number;
  loading: boolean;
}
