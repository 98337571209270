import { useCallback } from 'react';
import { ContractReceipt } from '@ethersproject/contracts';
import { useUserWeb3 } from '@moverfinance/web3';
import { MARKETPLACE as MARKETPLACE_ADDRESS } from 'constants/address';
import { useRealmCoreContract } from './useContract';

export const useIsApproved = (address?: string) => {
  const { active, account } = useUserWeb3();
  const realmCore = useRealmCoreContract();
  const operator = address || MARKETPLACE_ADDRESS;
  return useCallback(async () => {
    if (!active || !account) return false;
    return (await realmCore().isApprovedForAll(account, operator)) as boolean;
  }, [active, account, realmCore, operator]);
};

export const useApprove = (address?: string) => {
  const realmCore = useRealmCoreContract();
  const operator = address || MARKETPLACE_ADDRESS;
  return useCallback(async () => {
    const tx = await realmCore().setApprovalForAll(operator, true);
    const receipt = await tx.wait();

    return receipt as ContractReceipt;
  }, [realmCore, operator]);
};
