export const SET_IS_LOGIN = '@@account/setIsLogin';
export const SET_IS_PAIRED = '@@account/setIsPaired';
export const SET_NEED_FORCE_BIND = '@@account/setNeedForceBind';
export const SET_IS_MIGRATION = '@@account/setIsMigration';
export const LOGIN = '@@account/login';

export const ActionTypes = {
  SET_IS_LOGIN,
  SET_IS_PAIRED,
  SET_NEED_FORCE_BIND,
  SET_IS_MIGRATION,
  LOGIN
} as const;

export type ActionSetIsLogin = {
  type: typeof SET_IS_LOGIN;
  data: boolean;
};
export type ActionSetIsPaired = {
  type: typeof SET_IS_PAIRED;
  data: boolean;
};
export type ActionSetNeedForceBind = {
  type: typeof SET_NEED_FORCE_BIND;
  data: boolean;
};
export type ActionSetIsMigration = {
  type: typeof SET_IS_MIGRATION;
  data: boolean;
};

export interface AccountState {
  isLogin: boolean;
  isPaired: boolean;
  needForceBind: boolean;
  isMigration: boolean;
}
