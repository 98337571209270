import ReactDOM from 'react-dom';
import MessageComp, { MessageRef } from './Message';
import { NoticeType } from './Notice';
import styles from './message.module.css';

const DEFAULT_DURATION = 3;

class Message {
  private instance: MessageRef | null = null;

  info(content: string, duration = DEFAULT_DURATION) {
    this.show('info', content, duration);
  }

  success(content: string, duration = DEFAULT_DURATION) {
    this.show('success', content, duration);
  }

  warning(content: string, duration = DEFAULT_DURATION) {
    this.show('warning', content, duration);
  }

  error(content: string, duration = DEFAULT_DURATION) {
    this.show('error', content, duration);
  }

  loading(content: string, duration = DEFAULT_DURATION) {
    this.show('loading', content, duration);
  }

  private async getMessageInstance() {
    this.instance = this.instance || (await this.initMessageInstance());
    return this.instance;
  }

  private initMessageInstance(): Promise<MessageRef | null> {
    const container = document.createElement('div');

    return new Promise((resolve, reject) => {
      try {
        container.classList.add(styles.message);
        document.body.appendChild(container);
        ReactDOM.render(
          <MessageComp ref={instance => resolve(instance)} />,
          container
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  private async show(type: NoticeType, content: string, duration: number) {
    const instance = await this.getMessageInstance();
    if (instance) {
      instance.add({
        type,
        content,
        duration
      });
    }
  }
}

export default new Message();
