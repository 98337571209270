import { FC } from 'react';
// rarity
import { ReactComponent as RarityCommon } from '../../../assets/icons/rarity-common.svg';
import { ReactComponent as RarityEpic } from '../../../assets/icons/rarity-epic.svg';
import { ReactComponent as RarityHero } from '../../../assets/icons/rarity-hero.svg';
import { ReactComponent as RarityLegendary } from '../../../assets/icons/rarity-legendary.svg';
import { ReactComponent as RarityRare } from '../../../assets/icons/rarity-rare.svg';

interface Props {
  value: 'common' | 'epic' | 'hero' | 'legendary' | 'rare';
  className?: string;
}

const Rarity: FC<Props> = ({ value, className }) => {
  const icons = {
    common: <RarityCommon className={className} />,
    epic: <RarityEpic className={className} />,
    hero: <RarityHero className={className} />,
    legendary: <RarityLegendary className={className} />,
    rare: <RarityRare className={className} />
  };

  return icons[value] || null;
};

export default Rarity;
