import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import styles from './copyButton.module.css';

import { copyText } from 'utils';

export interface CopyButtonProps {
  className?: string;
  text: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ className = '', text }) => {
  const { t } = useTranslation();

  // 气泡文案
  const [tippyText, setTippyText] = useState<string>(t('common.copy'));
  // 气泡显隐
  const [visible, setVisible] = useState(false);

  const showTippy = useCallback(() => {
    setTippyText(t('common.copy'));
    setVisible(true);
  }, [t]);

  const hideTipppy = useCallback(() => {
    setVisible(false);
  }, []);

  const copyAddress = useCallback(() => {
    if (text && copyText(text)) {
      setTippyText(t('common.copied'));
    }
  }, [t, text]);

  return (
    <section
      className={`${className} ${styles.copyButton}`}
      onClick={copyAddress}
      onMouseEnter={showTippy}
      onMouseLeave={hideTipppy}
    >
      <Tippy content={tippyText} visible={visible}>
        <CopyIcon />
      </Tippy>
    </section>
  );
};

export default CopyButton;
