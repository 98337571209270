import { useTranslation } from 'react-i18next';
import { formatEther } from '@ethersproject/units';
import { trimBalance } from 'utils';
import { BigNumber } from '@ethersproject/bignumber';
export interface BalanceProps {
  className?: string;
  balance: BigNumber;
  symbol?: string;
  decimals: number;
}

const Balance = ({
  className = '',
  balance = BigNumber.from('0'),
  symbol = '',
  decimals=18
}) => {
  const { t } = useTranslation();
  !symbol && (symbol = t('common.eth'));

  return (
    <p className={className}>
      {/* TODOW 确认后面币种的精度，目前是 18 */}
      {balance && decimals === 18 && `${trimBalance(formatEther(balance))} ${symbol}`}
      {balance && decimals === 2 && `${Number(balance) / 100} ${symbol}`}
    </p>
  );
};

export default Balance;
