import { networks as defaultNetworks } from 'arb-ts';
import merge from 'lodash/merge';
import { CHAIN_IDS, INFO, ACTIVED_ID } from './chains';
import { BRIDGE } from './address';

export const networks = merge(INFO, {
  [CHAIN_IDS.ETH]: {
    isArbitrum: false,
    partnerChainID: '42161',
    tokenBridge: merge(defaultNetworks[1].tokenBridge, BRIDGE),
    ethBridge: defaultNetworks[1].ethBridge,
    blockTime: 15,
    rpcURL: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
  },
  [CHAIN_IDS.ARBITRUM]: {
    partnerChainID: '1',
    isArbitrum: true,
    tokenBridge: merge(defaultNetworks[42161].tokenBridge, BRIDGE),
    ethBridge: undefined,
    confirmPeriodBlocks: 45818,
    rpcURL: 'https://arb1.arbitrum.io/rpc'
  },
  [CHAIN_IDS.RINKEBY]: {
    partnerChainID: '421611',
    isArbitrum: false,
    tokenBridge: merge(defaultNetworks[4].tokenBridge, BRIDGE),
    ethBridge: defaultNetworks[4].ethBridge,
    confirmPeriodBlocks: 6545,
    blockTime: 15,
    rpcURL: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
  },
  [CHAIN_IDS.ARB_RINKEY]: {
    partnerChainID: '4',
    isArbitrum: true,
    tokenBridge: merge(defaultNetworks[421611].tokenBridge, BRIDGE),
    ethBridge: undefined,
    confirmPeriodBlocks: 6545,
    rpcURL: 'https://rinkeby.arbitrum.io/rpc'
  }
});

export const L2_RPC =
  ACTIVED_ID === CHAIN_IDS.ETH || ACTIVED_ID === CHAIN_IDS.ARBITRUM
    ? networks[CHAIN_IDS.ARBITRUM].rpcURL
    : networks[CHAIN_IDS.ARB_RINKEY].rpcURL;
