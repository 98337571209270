import { useCallback, useEffect, useState, useMemo } from 'react';
import cls from 'classnames';
import { NavLink, RouteComponentProps, useParams } from 'react-router-dom';
import { UnsupportedChainIdError } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import { useUserWeb3 } from '@moverfinance/web3';
import styles from './asset.module.css';
import { formatEther } from '@ethersproject/units';
import { speeds } from 'utils/assets';
import { useAuthenticated, useBalance, useBid, useCancel } from 'hooks';
import WalletConnectComponent from 'components/Wallet/WalletConnectComponent';

import {
  Button,
  CardOverview,
  ExternalLink,
  Modal,
  Price,
  Table,
} from 'components';
import CardRace from '../../../components/Card/CardRace';
import { shortenAddress, strDateTime, strEvent } from 'utils';
import { canBuy as canBuyNFT, isColdWallet,replaceColdWalletAddress } from 'utils/assets';
import { NFT as NFT_ADDRESS } from 'constants/address';
import { ETHERSCAN_URL, isL1NET } from '../../../constants/chains';
import AttributeItem from './Attribute';
import message from 'components/message';
import {
  Activity,
  listActivitiesByNFT,
  listNFTs,
  listOrders,
  NFT,
  Order
} from 'services';
import { ReactComponent as LinkOutside } from 'assets/icons/link-outside.svg';
import { ReactComponent as Durability } from 'assets/icons/durability.svg';
import Rarity from './Rarity';
import Mint from './Mint';
import Level from './Level';
import { track, trackName } from 'vendor/mixpanel';

interface ParamTypes {
  assetId: string;
}

interface AssetProps extends RouteComponentProps {}

type Status = 'buy' | 'sell' | 'cancel' | 'breed' | '';

const Asset: React.FC<AssetProps> = ({ history, location }) => {
  const { t } = useTranslation();
  const bid = useBid();
  const cancel = useCancel();
  const balance = useBalance();
  const isAuthenticated = useAuthenticated();
  const { assetId } = useParams<ParamTypes>();
  const { account, error } = useUserWeb3();
  const [status, setStatus] = useState<Status>('');
  const [buying, setBuying] = useState(false);
  const [back, setBack] = useState(false);
  const [showBuy, setShowBuy] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [nft, setNft] = useState<NFT>({} as NFT);
  const [order, setOrder] = useState<Order>({} as Order);
  const [activities, setActivities] = useState<Activity[]>([]);

  const maxAttributeValue = useMemo(() => {
    if (!nft || !nft.properties) return 100;
    return Math.max(
      nft.properties.speed ?? 0,
      nft.properties.luck ?? 0,
      nft.properties.attack ?? 0,
      nft.properties.hitPoints ?? 0
    );
  }, [nft]);

  const canBuy = useCallback(() => {
    return Number(nft?.order?.price) <= Number(formatEther(balance));
  }, [nft, balance]);

  const checkoutText = useCallback(() => {
    if (canBuy()) {
      if (buying) {
        return 'BUYING';
      } else {
        return 'CHECKOUT';
      }
    } else {
      return 'NOT ENOUGH FUND';
    }
  }, [buying, canBuy]);
  const buyAsset = useCallback(() => {
    if (order) {

      const bidOrder = order.order;
      const signature = order.signature;
      track(trackName.CLICK_BUY, { TokenID: nft?.tokenId, price: nft?.order?.price });
      if (signature) {
        bid(bidOrder, signature.sig)
          .then(tx => {
            setBuying(true);
            return tx.wait();
          })
          .then(receipt => {
            if (receipt.status) {
              setShowBuy(false);
              setBuying(false);
              message.success('Buy success! Please update your page!');
              setBack(true);
            }
          })
          .catch(error => {
            message.warning(error.error?.message || error.message || error, 5);
          });
      }
    }
  }, [order, bid, nft]);

  useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      setShowBuy(false);
    }
    if (back) {
      history.push({
        pathname: '/new/account/dashboard'
      });
    }
  }, [back, error]); // eslint-disable-line react-hooks/exhaustive-deps
  const cancelAsset = useCallback(() => {
    if (order) {
      const { order: cancelOrder } = order;
      cancel(cancelOrder)
        .then(() => {
          setShowCancel(false);
          message.success('Cancel success! Please update your page!');
        })
        .catch(error => {
          message.warning(error.error?.message || error.message || error, 5);
        });
    }
  }, [order, cancel]);

  const backView = () => {
    history.goBack();
  };

  useEffect(() => {
    const params = {
      nftAddress: NFT_ADDRESS,
      tokenId: assetId
    };
    listNFTs(params).then(data => {
      let nft = data.nfts[0];
      if (nft && nft?.properties && isColdWallet(nft?.owner)) {
        // @ts-ignore
        nft.properties.durability='';
        nft.properties.attack=0;
        nft.properties.luck=0;
        nft.properties.speed=0;
        nft.properties.hitPoints=0;
      }
      setNft(nft);
    });
    listActivitiesByNFT(params).then(data => {
      setActivities(data.activities);
    });
  }, [assetId]);

  useEffect(() => {
    if (nft?.order?.onsale && nft?.order?.orderId) {
      listOrders({
        orderId: nft.order.orderId,
        sig: true
      }).then(data => {
        setOrder(data.orders[data.orders.length - 1]);
      });
    }
  }, [nft]);

  useEffect(() => {
    if (account) {
      setShowBuy(false);
      if (nft?.owner === account) {
        if (nft?.order?.onsale) {
          setStatus('cancel');
        } else if (canBuyNFT(nft?.tokenId, nft?.properties?.birthday || '')) {
          setStatus('sell');
        }
      } else if (nft?.order?.onsale && canBuyNFT(nft?.tokenId,nft?.properties?.birthday||'')) {
        setStatus('buy');
      }
    } else if (nft?.order?.onsale && canBuyNFT(nft?.tokenId, nft?.properties?.birthday || '')) {
      setStatus('buy');
    } else {
      setStatus('');
    }
    if (isL1NET()) {
      setStatus('');
    }
  }, [account, nft]);

  const Trade = () => {
    switch (status) {
      case 'buy': {
        return (
          <Button className={'buy'} onClick={() => {
            track(trackName.CLICK_BUY_NOW, { TokenID: nft?.tokenId });
            setShowBuy(true)
          }}>
            {t('views.asset.buyNow')}
          </Button>
        );
      }
      case 'sell': {
        return (
          <NavLink to={`/assets/${assetId}/sell`} className={'trade'} onClick={() => {
            track(trackName.CLICK_SELL, { Address: account });
          }}>
            {t('views.asset.sell')}
          </NavLink>
        );
      }
      case 'cancel': {
        return (
          <Button
            className={`${styles.cancelListing} ${styles.cancelListingLarge}`}
            onClick={() => setShowCancel(true)}
          >
            {t('views.asset.cancelListing')}
          </Button>
        );
      }
      default: {
        return null;
      }
    }
  };

  const columns = [
    {
      title: '',
      key: 'marginL',
      width: '10px',
      render: (value: string) => {
        if (value) {
          return <span></span>;
        }
      }
    },
    {
      title: 'EVENT',
      key: 'event',
      width: '41px',
      render: (value: string) => {
        if (value) {
          return <span className={styles.event}>{value}</span>;
        }
      }
    },
    {
      title: 'UNIT PRICE',
      key: 'price',
      width: '90px',
      render: (value: string) => {
        if (value) {
          return <span>{value} AVAX</span>;
        }
      }
    },
    {
      title: 'FROM',
      key: 'from',
      width: '91px',
      render: (value: string) => {
        if (value) {
          return (
            !isColdWallet(value) ?
              <ExternalLink
                to={`${ETHERSCAN_URL}/address/${value}`}
                className={styles.accountAddress}
              >
                {shortenAddress(value)}
              </ExternalLink> : <div>
                {replaceColdWalletAddress()}
              </div>
          );
        } else {
          return null;
        }
      }
    },
    {
      title: 'TO',
      key: 'to',
      width: '91px',
      render: (value: string) => {
        if (value) {
          return (
            !isColdWallet(value) ?
              <ExternalLink
                to={`${ETHERSCAN_URL}/address/${value}`}
                className={styles.accountAddress}
              >
                {shortenAddress(value)}
              </ExternalLink> : <div>
                {replaceColdWalletAddress()}
              </div>
          );
        } else {
          return null;
        }
      }
    },
    {
      title: 'DATE',
      key: 'date',
      width: '65px'
    },
    {
      title: '',
      key: 'marginR',
      width: '10px',
      render: (value: string) => {
        if (value) {
          return <span></span>;
        }
      }
    }
  ];

  const dataSource = activities.map(activity => {
    return {
      marginL: '',
      event: strEvent(activity.event),
      price: activity.price || '',
      from: activity.from || '',
      to: activity.to || '',
      date: strDateTime(new Date(activity.ts)),
      marginR: ''
    };
  });

  return (
    <>
      <div className={styles.asset}>
        <div className={styles.inner}>
          <div className={styles.nav}>
            <button
              className={`${styles.button} ${styles.prevBtn}`}
              onClick={backView}
            >
              Back
            </button>
          </div>
          <div className={styles.content}>
            <div className={styles.infoHeaderMobile}>
              <div className={styles.infoDesc}>
                <p className={styles.infoName}>Blox &nbsp;#{nft?.tokenId}</p>
              </div>
            </div>
            <div className={styles.card}>
              <CardOverview
                imgSrc={nft?.imageUrl}
                backgroundColor={nft?.backgroundColor}
                ethPrice={nft?.order?.price}
                lastPrice={nft?.order?.lastPrice}
                owner={nft?.owner}
                onsale={nft?.order?.onsale}
              />
              <div className={styles.tradeView}>
                {!isColdWallet(nft?.owner) &&
                <Trade/>
                }
                {/*<Button className={styles.cancelListing} onClick={() => setShowCancel(true)}>
                  {t('views.asset.breed')}
                </Button>*/}
              </div>
            </div>
            <div className={styles.rightView}>
              <div className={styles.info}>
                <div className={styles.infoHeader}>
                  <div className={styles.infoDesc}>
                    <p className={styles.infoName}>
                      RunBlox &nbsp;#{nft?.tokenId}
                    </p>
                  </div>
                </div>
                <p className={styles.infoCaption}>About</p>
                <div className={styles.infoCard}>
                  <p className={styles.infoSubject}>OWNER</p>
                  {!isColdWallet(nft?.owner) ?
                    <div className={styles.infoOwner}>
                      <a
                        onClick={() => track(trackName.CLICK_OWNER_ADDRESS, {
                          Address: nft?.owner
                        })}
                        href={`${ETHERSCAN_URL}/address/${nft?.owner}`}
                        className={styles.infoOwnerAddress}
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        {nft?.owner}
                      </a>
                      <a
                        onClick={() => track(trackName.CLICK_OWNER_ADDRESS, {
                          Address: nft?.owner
                        })}
                        href={`${ETHERSCAN_URL}/address/${nft?.owner}`}
                        className={styles.infoOwnerAddress}
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        <LinkOutside/>
                      </a>
                    </div> : <div className={styles.infoOwner}>
                      {replaceColdWalletAddress()}
                    </div>
                  }
                </div>
                <p className={styles.infoCaption}>Stats</p>
                <div className={cls(styles.infoCard, styles.stats)}>
                  <div className={styles.infoCardTop}>
                    <div className={styles.infoCardTopRow}>
                      <p className={styles.infoSubject}>{t('stats.rarity')}</p>
                      <p
                        className={cls(styles.infoText, {
                          [styles.none]: !nft?.properties?.rarity
                        })}
                      >
                        {nft?.properties?.rarity ? (
                          <>
                            <Rarity className={styles.icon} value={nft?.properties?.rarity} />
                            {t(`rarity.${nft?.properties?.rarity}`)}
                          </>
                        ) : (
                          t('stats.none')
                        )}
                      </p>
                    </div>
                    <div className={styles.infoCardTopRow}>
                      <p className={styles.infoSubject}>{t('stats.class')}</p>
                      <p
                        className={cls(styles.infoText, {
                          [styles.none]: !nft?.properties?.race
                        })}
                      >
                        {nft?.properties?.race ? (
                          <>
                            <div className={styles.icon}>
                              <CardRace race={nft?.properties?.race} />
                              {speeds[nft?.properties?.race] && (
                                <div className={styles.speed}>{speeds[nft?.properties?.race]}km/h</div>
                              )}
                            </div>
                            {nft?.properties?.race}
                          </>
                        ) : (
                          t('stats.none')
                        )}
                      </p>
                    </div>
                    <div className={styles.infoCardTopRow}>
                      <p className={styles.infoSubject}>
                        {t('stats.durability')}
                      </p>
                      <p
                        className={cls(styles.infoText, {
                          [styles.none]: !nft?.properties?.durability
                        })}
                      >
                        {nft?.properties?.durability ? (
                          <>
                            <Durability className={styles.icon}/>
                            {parseInt(nft?.properties?.durability as unknown as string)}/100
                          </>
                        ) : (
                          t('stats.none')
                        )}
                      </p>
                    </div>
                  </div>
                  <div className={styles.otherStats}>
                    <div>
                      <Level value={nft?.properties?.level ?? 0} progress={0} />
                    </div>
                    <div>
                      <Mint value={nft?.properties?.mintTimes ?? 0} />
                    </div>
                  </div>
                </div>
                {/*<p className={styles.infoCaption}>Stats</p>
                <div className={styles.infoCardStates}>
                  <div className={styles.infoCardStatesText}>
                    <div className={styles.infoSubject}>HP</div>
                    <div className={styles.infoCardStatesTextValue}>
                      <Hp />
                      &nbsp;&nbsp;35
                    </div>
                  </div>
                  <div className={styles.infoCardStatesText}>
                    <div className={styles.infoSubject}>SPEED</div>
                    <div className={styles.infoCardStatesTextValue}>
                      <Speed />
                      &nbsp;&nbsp;45
                    </div>
                  </div>
                  <div className={styles.infoCardStatesText}>
                    <p className={styles.infoSubject}>LUCK</p>
                    <div className={styles.infoCardStatesTextValue}>
                      <Luck />
                      &nbsp;&nbsp;31
                    </div>
                  </div>
                </div>*/}
                <p className={styles.infoCaption}>{t('attributes.label')}</p>
                <div className={styles.attributes}>
                  <AttributeItem
                      className={styles.item}
                      label={t('attributes.attack')}
                      value={nft?.properties?.attack ?? null}
                      max={maxAttributeValue}
                      color="#FF852D"
                  />
                  <AttributeItem
                      className={styles.item}
                      label={t('attributes.luck')}
                      value={nft?.properties?.luck ?? null}
                      max={maxAttributeValue}
                      color="#A0DEEC"
                  />
                  <AttributeItem
                    className={styles.item}
                    label={t('attributes.speed')}
                    value={nft?.properties?.speed ?? null}
                    max={maxAttributeValue}
                    color="#FFE457"
                  />
                  <AttributeItem
                    label={t('attributes.hit')}
                    value={nft?.properties?.hitPoints ?? null}
                    max={maxAttributeValue}
                    color="#C5C5F4"
                  />
                </div>
              </div>
              <div className={styles.trading}>
                <div className={styles.infoHeader}>
                  <div className={styles.infoDesc}>
                    <p className={styles.infoName}>&nbsp;</p>
                  </div>
                </div>
                <div className={styles.history}>
                  <h1 className={styles.infoCaption}>{'Trading History'}</h1>
                  <div className={styles.activity}>
                    <Table columns={columns} dataSource={dataSource} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal visible={showBuy} title={'Complete checkout'} onClose={setShowBuy}>
        {isAuthenticated ? (
          <div className={styles.buy}>
            <div className={styles.buyContent}>
              <div className={styles.buyItem}>
                <div className={styles.buyName}>
                  <div className={styles.buyCard}>
                    <img
                      alt="asset"
                      width={48}
                      height={48}
                      src={nft?.imageUrl}
                    />
                  </div>
                  <div className={styles.buyCardInfo}>
                    <div className={styles.buyCardInfoLeft}>
                      <span className={styles.buyRace}>
                        <CardRace race={nft?.properties?.race}></CardRace>{' '}
                        &nbsp;
                        {nft?.properties?.race}
                      </span>
                      <p className={styles.buyPriceETHHighlight}>
                        {`${nft?.order?.price} ${t('common.eth')}`}
                      </p>
                    </div>
                    <div className={styles.buyPrice}>
                      <span className={styles.buyId}>#{nft?.tokenId}</span>

                      {nft?.order?.price && (
                        <Price
                          eth={nft?.order.price}
                          className={styles.buyPriceUSD}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buyItem}>
                <div className={styles.buyNameTotal}>TOTAL</div>
                <div className={styles.buyPriceFlex}>
                  <p className={styles.buyPriceETH}>
                    {nft?.order?.price}&nbsp;{t('common.eth')}
                  </p>
                  &nbsp;&nbsp;
                  {nft?.order?.price && (
                    <Price
                      eth={nft?.order?.price}
                      className={styles.buyPriceUSDNormal}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.buyFooter}>
              <Button
                className={
                  !canBuy()
                    ? `tradeDisabled`
                    : `${styles.trade} ${styles.checkout}`
                }
                onClick={buyAsset}
                disabled={!canBuy() || buying}
              >
                {checkoutText()}
              </Button>
              <Button
                className={styles.cancelListing}
                onClick={() => setShowBuy(false)}
              >
                {'CANCEL'}
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.connectWalet}>
            <p className={styles.connectWaletText}>
              Before buy, please connect wallet.
            </p>
            <WalletConnectComponent name={"login-asset"}></WalletConnectComponent>
          </div>
        )}
      </Modal>
      <Modal
        visible={showCancel}
        title={'Are you sure you want to cancel your listings?'}
        closable={false}
      >
        <div className={styles.cancel}>
          <p className={styles.cancelContent} style={{ color: '#909090' }}>
            Canceling your listing will unpublish this sale from RunBlox and
            requires a transaction to make sure it will never be fulfillable.
          </p>
        </div>
        <div className={styles.cancelFooter}>
          <Button className={`${styles.trade}`} onClick={cancelAsset}>
            {'CANCEL LISTINGS'}
          </Button>

          <Button
            className={`${styles.cancelListing}`}
            onClick={() => setShowCancel(false)}
          >
            {'CANCEL'}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Asset;
