import axios from '../axios';

export interface AttributeRequest {
  tokenId?: string | number;
}

export interface Attribute {
  key: string;
  value: number;
  count: number;
  total: number;
}

export interface AttributeResponse {
  attributes: Attribute[];
}

export const listAttributes = (
  params: AttributeRequest
): Promise<AttributeResponse> => {
  return axios.get('nft/attributes', {
    params
  });
};
