import { useState, useImperativeHandle, forwardRef } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styles from './message.module.css';
import Notice, { NoticeProps } from './Notice';
import { generateId } from 'utils';

type Config = Omit<NoticeProps, 'onClose'>;
interface NoticeState extends Config {
  key: string;
}

export interface MessageRef {
  add: (config: Config) => void;
  remove: (key: string) => void;
}

const Message = forwardRef<MessageRef>((props, ref) => {
  const [notices, setNotices] = useState<NoticeState[]>([]);

  useImperativeHandle(ref, () => ({
    add,
    remove
  }));

  const add = (config: Config) => {
    setNotices(notices => [...notices, { ...config, key: generateId() }]);
  };
  const remove = (key: string) => {
    setNotices(notices.filter(notice => notice.key !== key));
  };

  return (
    <TransitionGroup component={null}>
      {notices.map(notice => (
        <CSSTransition
          key={notice.key}
          timeout={300}
          classNames={{
            enter: styles['message-notice-enter'],
            enterActive: styles['message-notice-enter-active'],
            exit: styles['message-notice-exit'],
            exitActive: styles['message-notice-exit-active']
          }}
        >
          <Notice
            {...notice}
            key={notice.key}
            onClose={() => remove(notice.key)}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
});

export default Message;
