import React from 'react';
import { ReactComponent as WatchAsset } from 'assets/icons/watchasset.svg';

import { useWatchAsset } from 'hooks';
import styles from './watchAssetButton.module.css';
import { track, trackName } from 'vendor/mixpanel';
export interface CopyButtonProps {
  tokenAddress: string;
  tokenSymbol: string;
  tokenDecimals: number;
  tokenImage?: string;
}

const WatchAssetButton: React.FC<CopyButtonProps> = ({
  tokenAddress = '',
  tokenSymbol,
  tokenDecimals,
  tokenImage = 'https://placem.at/places?h=100&w=100'
}) => {
  const watchAsset = useWatchAsset();
  const handleWatchAsset = () => {
    track(trackName.CLICK_WATCH_ASSET, { token: tokenSymbol });
    watchAsset(tokenAddress, tokenSymbol, tokenDecimals, tokenImage).then(data => {
      console.log(data);
    });
  };

  return (
    <WatchAsset onClick={handleWatchAsset} className={styles.watchAssetIcon}></WatchAsset>
  );
};

export default WatchAssetButton;
