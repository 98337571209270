import React from 'react';
import styles from './radio.module.css';

export type Mode = 'point' | 'inner';

interface CheckboxProps<T> {
  name: string;
  mode?: Mode;
  value: T;
  onChange: (value: T) => void;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
}

function Radio<T>(props: CheckboxProps<T>) {
  const {
    name,
    mode = 'inner',
    value,
    onChange,
    checked = false,
    disabled = false,
    className = '',
    children
  } = props;

  return (
    <div className={`${styles.radio} ${className}`}>
      <input
        disabled={disabled}
        id={value + ''}
        type="radio"
        checked={checked}
        name={name}
        className={`${styles[mode]}`}
        onChange={() => onChange(value)}
      />
      <label htmlFor={value + ''}>
        <span className={styles.text}>{children}</span>
      </label>
    </div>
  );
}

export default Radio;
