import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setIsLogin } from 'store/account/actions';
import { useLogOut } from 'hooks/useFireBase';

import { ButtonNew } from 'components';

import loginBannerUrl from 'assets/images/account_login_banner.png';
import styles from './account.module.css';
import { useAuthLogin } from './AuthLogin';
import InventoryCards from './InventoryCard';
import { track, trackName } from 'vendor/mixpanel';

const Account: React.FC<any> = () => {
  const { isLogin, isPaired, needForceBind, handlePair, handleForcePair, setShowLogin, gameAccount } =
    useAuthLogin();
  const firebaseLogOut = useLogOut();

  /**
   * Login & Pair
   */
  const dispatch = useDispatch();

  const pageTitle = useMemo(() => {
    if (isLogin && !isPaired) {
      return 'Inventory in your game';
    } else {
      return 'Account Inventory';
    }
  }, [isLogin, isPaired]);

  const handleLogin = () => {
    track(trackName.CLICK_EMAIL_LOGIN);
    setShowLogin(true);
  };
  const handleLogOut = () => {
    firebaseLogOut();
    dispatch(setIsLogin(false));
  };

  let button;
  if (!isPaired) {
    if (needForceBind) {
      button = <ButtonNew className={styles.pairBtn} onClick={handleForcePair}>
        FORCE PAIR YOUR ACCOUNT
      </ButtonNew>
    } else {
      button = <ButtonNew className={styles.pairBtn} onClick={handlePair}>
        COMPLETE YOUR ACCOUNT SETUP
      </ButtonNew>
    }
  }

  return (
    <main className={styles.container}>
      {/* Inventory */}
      <section className={styles.inventory}>
        <h1 className={styles.caption}>{pageTitle}</h1>

        {/* 未登录时 Login */}
        {!isLogin && (
          <div className={styles.login}>
            <img
              className={styles.loginBanner}
              src={loginBannerUrl}
              alt="login"
              onClick={handleLogin}
            />
          </div>
        )}

        {/* 已登录时 */}
        {isLogin && (
          <>
            {/* User */}
            <div className={styles.user}>
              <div className={styles.infoAvatar}>
                <div className={styles.avatar}>
                  {gameAccount?.displayName?.substring(0, 1)}
                </div>
                <button
                  className={styles.logout}
                  onClick={() => handleLogOut()}
                >
                  Log out
                </button>
              </div>
              <div className={styles.email}>
                <p className={styles.emailField}>
                  {isPaired ? 'PAIRED AT:' : 'PLEASE PAIRE YOUR EMAIL AT:'}
                </p>
                <p className={styles.emailValue}>{gameAccount?.email}</p>
              </div>
              {button}
            </div>
          </>
        )}
      </section>

      <InventoryCards />
    </main>
  );
};

export default Account;
