import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './card.module.css';
import { Link } from 'react-router-dom';
import { ButtonNew, Price } from 'components';
import { useUserWeb3 } from '@moverfinance/web3';
import CardRace from './CardRace';
import { store } from '../../store';
import { ActionTypes } from '../../store/main/actionsTypes';
import { ReactComponent as Checked } from 'assets/icons/check.svg';
import { ReactComponent as UnChecked } from 'assets/icons/uncheck.svg';
import { ReactComponent as DisableCheck } from 'assets/icons/check-disable.svg';
import { useAuthLogin } from '../../views/AccountNew/components/Account/AuthLogin';
import { setCardBackground } from 'utils/assets';

export interface CardProps {
  id: number;
  backgroundColor?: string;
  imgSrc: string;
  heroTag: string;
  ethPrice?: string;
  onsale?: boolean;
  className?: string;
  lastPrice?: string;
  owner?: string;
  sourceType?: string;
  storage?: any;
  cardClickable?: boolean;
  showSmallCard?: boolean;
  checkAble?: boolean;
  checked?: boolean;
  checkCallback?: (value: number) => void;
  depositCallback?: (tokenId: number) => void;
  withdrawCallback?: (tokenId: number) => void;
  frozenWithdraw?: boolean;
}

const Card: React.FC<CardProps> = ({
  id,
  backgroundColor='',
  imgSrc,
  heroTag,
  ethPrice,
  onsale = false,
  className = '',
  lastPrice = '',
  owner,
  sourceType = '',
  storage = '',
  cardClickable = true,
  showSmallCard = false,
  checkAble = false,
  checked = false,
  checkCallback,
  depositCallback,
  withdrawCallback,
  frozenWithdraw=false
}) => {
  const { isPaired, isMigration } = useAuthLogin();
  const { t } = useTranslation();
  const { account } = useUserWeb3();
  /**
   * 保存列表状态
   * @param data
   * @returns {Function}
   */
  const toAssetPage = () => {
    store.dispatch({
      type: ActionTypes.TestTag,
      data: JSON.stringify(storage)
    });
    if (checkAble && checkCallback && !onsale) {
      console.log('checked');
      checkCallback(id);
    }
  };

  return (
    <div
      className={`${styles.cardItem} ${
        checkAble && checked ? styles.cardItemChecked : ''
      } ${checkAble && !checked ? styles.cardItemUnChecked : ''}`}
      key={id}
    >
      <Link
        onClick={() => toAssetPage()}
        className={`${showSmallCard ? styles.smallCard : ''} ${
          styles.card
        } ${className}`}
        style={setCardBackground(backgroundColor)}
        to={cardClickable ? `/assets/${id}` : '#'}
      >
        {checkAble && (
          <div className={styles.cardCheck}>
            {!onsale && !checked && <UnChecked></UnChecked>}
            {!onsale && checked && <Checked></Checked>}
            {onsale && <DisableCheck></DisableCheck>}
          </div>
        )}
        {onsale && <div className={styles.cardSale}>{t('common.onsale')}</div>}
        <div className={styles.cardLink}>
          <img
            src={imgSrc}
            width={showSmallCard ? 114 : 150}
            height={showSmallCard ? 114 : "auto"}
            className={styles.cardImage}
            alt="asset"
          />
        </div>
      </Link>
      <div
        className={`${styles.cardInfo} ${
          showSmallCard ? styles.smallCardInfo : ''
        }  ${
          checkAble && checked
            ? styles.smallCardInfoChecked
            : checkAble
            ? styles.smallCardInfoUnChecked
            : ''
        }`}
      >
        <span className={styles.cardId}>
          <CardRace race={heroTag}></CardRace>
          {t('card.title')} #{id}
        </span>
        {owner && owner === account && sourceType !== 'marketplace' && (
          <div className={styles.cardPrice}>
            <div
              className={`${
                showSmallCard
                  ? styles.cardPriceInnerSmall
                  : styles.cardPriceInner
              }`}
            >
              {!onsale && (
                <p className={styles.cardPriceETH}>
                  {`${lastPrice} ${t('common.eth')}`}
                </p>
              )}
              {!onsale && (
                <Price eth={lastPrice} className={styles.cardPriceUSD} />
              )}
              {onsale && ethPrice && (
                <p className={styles.cardPriceETH}>
                  {`${ethPrice} ${t('common.eth')}`}
                </p>
              )}
              {onsale && ethPrice && (
                <Price eth={ethPrice} className={styles.cardPriceUSD} />
              )}
            </div>
            {onsale && lastPrice && (
              <div className={styles.cardLastPrice}>
                Last {`${lastPrice} ${t('common.eth')}`}，{' '}
                <div className={styles.priceView}>
                  {' '}
                  <Price eth={lastPrice} className={styles.cardLastPriceUSD} />
                  <div className={styles.pricePoint}>。</div>
                </div>
              </div>
            )}
          </div>
        )}
        {onsale && owner !== account && (
          <div className={styles.cardPrice}>
            <div
              className={`${
                showSmallCard
                  ? styles.cardPriceInnerSmall
                  : styles.cardPriceInner
              }`}
            >
              {ethPrice && (
                <p className={styles.cardPriceETH}>
                  {`${ethPrice} ${t('common.eth')}`}
                </p>
              )}
              {ethPrice && (
                <Price eth={ethPrice} className={styles.cardPriceUSD} />
              )}
            </div>
          </div>
        )}
        {onsale && owner === account && sourceType === 'marketplace' && (
          <div className={styles.cardPrice}>
            <div className={styles.cardPriceInner}>
              {ethPrice && (
                <p className={styles.cardPriceETH}>
                  {`${ethPrice} ${t('common.eth')}`}
                </p>
              )}
              {ethPrice && (
                <Price eth={ethPrice} className={styles.cardPriceUSD} />
              )}
            </div>
          </div>
        )}
        {!onsale && isPaired && !isMigration && owner === account && sourceType === 'dashboard' && (
          <div style={{marginTop:20}}>
            <ButtonNew
              className={styles.btn}
              mode="secondary"
              onClick={() => {
                depositCallback && depositCallback(id);
              }}
            >
              DEPOSIT
            </ButtonNew>
          </div>
        )}
        {!onsale && isPaired && sourceType === 'account/rouge' && (
          <div style={{marginTop:20}}>
            <ButtonNew
              className={styles.btn}
              mode="secondary"
              disabled={frozenWithdraw}
              onClick={() => {
                withdrawCallback && withdrawCallback(id);
              }}
            >
              {frozenWithdraw ? 'FREEZING' : 'WITHDRAW'}
            </ButtonNew>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
