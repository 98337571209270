import React, { useCallback, useState, useRef, useEffect } from 'react';
import { BaseMenuProps } from './typings';
import { ReactComponent as TransferTips } from 'assets/icons/transfer-tips.svg';
import transfers from './transfer.module.css';
import { Button, CardList, Input, Modal } from 'components';
import { useTransfer } from 'hooks';
import { ReactComponent as TransferIcon } from 'assets/icons/transfer.svg';
import { isAddress } from '@ethersproject/address';
import { useUserWeb3 } from '@moverfinance/web3';
import { useTranslation } from 'react-i18next';
import message from 'components/message';
import { walletType } from 'utils/account';
import { ReactComponent as Warn } from 'assets/icons/warn.svg';
import { ReactComponent as Done } from 'assets/icons/done.svg';
import styles from '../../../Account/components/account.module.css';
import { ReactComponent as Check } from '../../../../assets/icons/check.svg';
import { ReactComponent as Loading } from '../../../../assets/icons/waiting.svg';
import { track, trackName } from 'vendor/mixpanel';

let LIMIT = 12;
const MaximumCheck = 50;

const Transfer: React.FC<BaseMenuProps> = props => {
  const transferBtnRef = useRef(null);
  const { t } = useTranslation();
  let { account } = useUserWeb3();
  const transfer = useTransfer();
  const [batchTransferError, setBatchTransferError] = useState('');
  const [batchTransferAddress, setBatchTransferAddress] = useState('');
  const [showListing, setShowListing] = useState(false);
  const [check, setCheck] = useState<number[]>([]);
  const [steps, setSteps] = useState<boolean[]>([false, false, false]);
  const [showBatchTransferConfirm, setShowBatchTransferConfirm] =
    useState(false);
  const handleTransfer = (value: string) => {
    if (value === account) {
      setBatchTransferError(
        'You cannot transfer assets to your current wallet.'
      );
    } else if (isAddress(value) || value === '') {
      setBatchTransferError('');
    } else {
      setBatchTransferError('Please confirm address');
    }
    setBatchTransferAddress(value);
  };
  const handleCheckCallback = useCallback((ids: number[]) => {
    setCheck(ids);
    setShowBatchTransferConfirm(false);
    setShowListing(false);
  }, []);

  const resetPage = useCallback(() => {
    handleCheckCallback([]);
    setBatchTransferError('');
    setBatchTransferAddress('');
  }, [handleCheckCallback]);
  const batchTransferConfirm = () => {
    if (check.length > MaximumCheck) {
      message.warning(
        'WE RECOMMEND TRANSFERRING A MAXIMUM OF 50 BLOXES EVERY TIME.',
        5
      );
    } else {
      setShowBatchTransferConfirm(true);

    }
  };
  useEffect(() => {
    if(showBatchTransferConfirm){
      // @ts-ignore
      window.scrollTo(0, transferBtnRef.current.offsetTop);
    }
  }, [showBatchTransferConfirm]);
  const batchTransfer = () => {
    if (account) {
      setShowListing(true);
      transfer(check, batchTransferAddress, steps => {
        setSteps(steps);
      })
        .then(data => {
          setSteps([true, true, true]);
          setTimeout(function() {
            setShowListing(false);
            message.success('Transfer success!' + (walletType() !== 'walletConnect' ? 'Please update your page!' : ''), 5);
            resetPage();
          }, 1000);
        })
        .catch(error => {
          setShowListing(false);
          message.warning(
            error.message ===
            'MetaMask Tx Signature: User denied transaction signature.'
              ? 'YOU DECLINED THE ACTION IN YOUR WALLET.'
              : (error.message || error),
            5
          );
        });
    }
  };
  return (
    <div>
      {/*transfer header*/}
      <div className={transfers.topBanner}>
        <TransferTips className={transfers.topBannerIcoTips} />
        <span className={transfers.topBannerTips}>
          Transfer your Bloxes to another wallet you own.
        </span>
      </div>
      <div className={transfers.transferTips} ref={transferBtnRef}>
        <span>
          {!showBatchTransferConfirm
            ? 'Transfer your Bloxes to new address'
            : 'Confirm your transfer'}
        </span>
      </div>
      {/*transfer card list*/}
      <div
        className={`${transfers.transferContent} ${
          showBatchTransferConfirm ? transfers.transferContentDisable : ''
        }`}
      >
        {!showBatchTransferConfirm &&
        <CardList
          showSortOptions={false}
          useCardComponent={true}
          checkAble={true}
          showSmallCard={true}
          showSmallModal={false}
          showSearchComponent={true}
          limit={LIMIT}
          checkCallback={handleCheckCallback}
          paginationClass={transfers.cardListModalContentPagination}
        />
        }
        <div className={transfers.cardListModalContentBtn}>
          <div className={transfers.cardListModalContentBtnTitle}>
            <TransferIcon />
            <span>Transfer to address</span>
          </div>
          <div className={transfers.cardListModalBottom}>
            <div className={`${transfers.cardListModalContentInputView}`}>
              <div className={styles.cardListModalTransferError}>
                {batchTransferError}
              </div>
              {!showBatchTransferConfirm &&
              <Input
                blur={() => {
                  track(trackName.FOCUS_TRANSFER_TO_INPUT);
                }}
                inputChange={handleTransfer}
                className={`${transfers.cardListModalContentInput} ${
                  batchTransferError === '' && batchTransferAddress
                    ? transfers.cardListModalContentInputDone
                    : ''
                }`}
                placeholder={'e.g. 430a24...3f6cbb'}
              />
              }

              {batchTransferError !== '' && <Warn></Warn>}
              {batchTransferError === '' && batchTransferAddress && <Done />}
            </div>
            <Button
              className={`${
                batchTransferError === '' &&
                batchTransferAddress &&
                check.length > 0
                  ? transfers.cardListModalTransferBtn
                  : transfers.cardListModalTransferBtnDisable
              }`}
              disabled={
                !(
                  batchTransferError === '' &&
                  batchTransferAddress &&
                  check.length > 0
                )
              }
              onClick={() => batchTransferConfirm()}
            >
              {t('views.account.batchTransferBtn')}
            </Button>
          </div>
        </div>
      </div>
      {/*transfer step2 transfer card list*/}
      {showBatchTransferConfirm && (
        <div className={`${transfers.transferContent}`}>
          <CardList
            showSortOptions={false}
            useCardComponent={true}
            showSmallCard={true}
            showSmallModal={false}
            tokenIds={check.join(',')}
            limit={LIMIT}
            paginationClass={transfers.cardListModalContentPagination}
          />

          <div className={transfers.cardListModalContentConfirmTransfer}>
            <div className={styles.cardListModalBottom}>
              <div
                className={`${transfers.cardListModalContentConfirmTransferBlox} ${styles.cardListModalContentConfirm}`}
              >
                <span>{check.length} bloxes will be transfer to</span>
                <div>{batchTransferAddress}</div>
              </div>
            </div>
            <div className={transfers.cardListModalContentConfirmTransferBtn}>
              <Button
                className={`backBtn ${transfers.cardListModalContentConfirmTransferBackBtn} ${styles.cardListModalBtnBack}`}
                onClick={() => {
                  setShowBatchTransferConfirm(false);
                }}
              >
                {t('views.account.batchTransferBtnBack')}
              </Button>
              <Button
                style={{ width: '242px' }}
                className={`${
                  transfers.cardListModalContentConfirmTransferConfirmBtn
                } ${
                  batchTransferError === '' &&
                  batchTransferAddress &&
                  check.length > 0
                    ? styles.cardListModalTransferBtn
                    : styles.cardListModalTransferBtnDisable
                }`}
                disabled={
                  batchTransferError === '' &&
                  batchTransferAddress &&
                  check.length > 0
                    ? false
                    : true
                }
                onClick={() => batchTransfer()}
              >
                {t('views.account.batchTransferBtnConfirm')}
              </Button>
            </div>
          </div>
        </div>
      )}
      {/*transfer step2 confirm transfer*/}
      <Modal
        visible={showListing}
        title={'Complete your transfer'}
        closable={false}
      >
        <div className={styles.steps}>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {steps[0] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>
                INTIALIZE YOUR WALLET
              </span>
            </h2>
            <p className={styles.stepContent}>
              To use our Marketplace for the first time you will need to
              initialize your wallet, which requires a one time gas fee.
            </p>
          </div>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {steps[1] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>APPROVE NFT</span>
            </h2>
            <p className={styles.stepContent}>
              Approving the NFTs for transfer sets up for avalanche bridge. This
              requires a one time gas fee.
            </p>
          </div>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {steps[2] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>CONFIRM TRANSFER</span>
            </h2>
            <p className={styles.stepContent}>
              Confirm the transfer by accepting the signature request in your
              wallet.
            </p>
            {steps[2] && (
              <p className={styles.stepSuccess}>Waiting for signature...</p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Transfer;
