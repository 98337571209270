import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserWeb3 } from '@moverfinance/web3';
import { ReactComponent as Enabled } from 'assets/icons/enabled.svg';
import styles from './account.module.css';
import { Balance, ExternalLink } from 'components';
import { copyText, shortenAddress } from 'utils';
import { track, trackName } from 'vendor/mixpanel';
import { ReactComponent as OpenOutside } from 'assets/icons/open-outside.svg';
import { ETHERSCAN_URL } from 'constants/chains';
import Tippy from '@tippyjs/react';
import { useBalance } from 'hooks';

const Account = () => {
  const { t } = useTranslation();
  const { account } = useUserWeb3();
  const [tippyText, setTippyText] = useState<string>(t('common.copy'));
  const [visible, setVisible] = useState(false);

  const balance = useBalance();

  const copyAddress = () => {
    if (account && copyText(account)) {
      setTippyText(t('common.copied'));
    }
  };

  const showTippy = () => {
    setTippyText(t('common.copy'));
    setVisible(true);
  };

  const hideTipppy = () => {
    setVisible(false);
  };

  return (
    <div className={styles.account}>
      <div className={styles.accountInfo}>
        <div className={styles.accountBalance}>
          <Balance className={styles.balance} balance={balance} />
          <Enabled />
        </div>
        {account && (
          <div className={styles.accountView}>
            <ExternalLink
              to={`${ETHERSCAN_URL}/address/${account}`}
              className={styles.address}
            >
              <p>{shortenAddress(account)}&nbsp;</p>
            </ExternalLink>
            <Tippy content={tippyText} visible={visible}>
              <div
                className={styles.address}
                onClick={copyAddress}
                onMouseEnter={showTippy}
                onMouseLeave={hideTipppy}
              >
                <OpenOutside />
              </div>
            </Tippy>
          </div>
        )}
      </div>
      <NavLink
        className={styles.accountLink}
        to={'/account'}
        onClick={() => track(trackName.CLICK_MY_ACCOUNT, { Address: account })}
      >
        {t('layouts.header.account')}
      </NavLink>
    </div>
  );
};

export default Account;
