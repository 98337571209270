import { useState, useEffect, useCallback } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useBlockNumber, useUserWeb3 } from '@moverfinance/web3';

export const useBalance = (owner?: string, reloadBalance?: boolean) => {
  const { library, account } = useUserWeb3();
  const blockNumber = useBlockNumber();
  const [balance, setBalance] = useState(BigNumber.from('0'));
  useEffect(() => {
    const ownerAddress = owner || account;
    if (!ownerAddress || !library) return;
    library.getBalance(ownerAddress).then((balance: BigNumber) => {
      setBalance(balance);
    });
  }, [library, blockNumber, account, owner, reloadBalance]);

  return balance;
};
export const useWatchAsset = () => {
  const { library } = useUserWeb3();
  return useCallback(
    async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number, tokenImage: string) => {
      // @ts-ignore
      const wasAdded = await library?.provider?.request({
        method: 'wallet_watchAsset',
        params: {
          // @ts-ignore
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage // A string url of the token logo
          }
        }
      });
      if (wasAdded) {
        return ('Thanks for your interest!');
      } else {
        return ('Your loss!');
      }
    }, [library]);
};
