import React from 'react';
import styles from './checkbox.module.css';

interface CheckboxProps<T> {
  name: string;
  value: T;
  onChange: (value: T) => void;
  checked?: boolean;
  className?: string;
  children?: React.ReactNode;
}

function Checkbox<T>(props: CheckboxProps<T>) {
  const {
    name,
    value,
    onChange,
    checked = false,
    className = '',
    children
  } = props;
  return (
    <div className={`${styles.checkbox} ${className}`}>
      <input
        id={value + ''}
        type="checkbox"
        checked={checked}
        name={name}
        className={styles.inner}
        onChange={() => onChange(value)}
      ></input>
      <label className={styles.text} htmlFor={value + ''}>
        <div className={checked ? styles.checked : styles.unchecked}>
          {children}
        </div>
      </label>
    </div>
  );
}

export default Checkbox;
