import React from 'react';

type ExternalLinkProps = {
  to: string;
  title?: string;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const ExternalLink: React.FC<ExternalLinkProps> = ({
  to,
  title,
  className,
  onMouseEnter,
  onMouseLeave,
  children
}) => {
  return (
    <a
      href={to}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
