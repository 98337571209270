import { isMainnet } from 'utils/network';
import { strEvent, strNewWorkFullName } from '../utils';

const ETH = 1;
const RINKEBY = 4;
const ARBITRUM = 42161;
const ARB_RINKEY = 421611;
const AVAX = 43114;
const AVAX_FUJI = 43113;

export const CHAIN_IDS: Record<string, number> = {
  ETH,
  RINKEBY,
  ARBITRUM,
  ARB_RINKEY,
  AVAX,
  AVAX_FUJI
} as const;

export const INFO = {
  [CHAIN_IDS.ETH]: {
    chainID: CHAIN_IDS.ETH,
    name: 'Ethereum',
    explorerUrl: 'https://etherscan.io',
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    provider: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [CHAIN_IDS.ARBITRUM]: {
    chainID: CHAIN_IDS.ARBITRUM,
    name: 'Arbitrum',
    explorerUrl: 'https://arbiscan.io',
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    provider: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [CHAIN_IDS.RINKEBY]: {
    chainID: CHAIN_IDS.RINKEBY,
    name: 'Rinkeby',
    explorerUrl: 'https://rinkeby.etherscan.io',
    provider: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    rpcUrls: ['https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [CHAIN_IDS.ARB_RINKEY]: {
    chainID: CHAIN_IDS.ARB_RINKEY,
    name: 'Arbitrum Rinkeby',
    explorerUrl: 'https://testnet.arbiscan.io',
    rpcUrls: ['https://rinkeby.arbitrum.io/rpc'],
    provider: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [CHAIN_IDS.AVAX]: {
    chainID: CHAIN_IDS.AVAX,
    name: 'Avalanche C-Chain',
    explorerUrl: 'https://snowtrace.io/',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    provider: 'https://api.avax.network/ext/bc/C/rpc',
    nativeCurrency: { name: 'AVAX', symbol: 'AVAX', decimals: 18 }
  },
  [CHAIN_IDS.AVAX_FUJI]: {
    chainID: CHAIN_IDS.AVAX_FUJI,
    name: 'Avalanche Fuji Testnet',
    explorerUrl: 'https://testnet.snowtrace.io/',
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    provider: 'https://api.avax-test.network/ext/bc/C/rpc',
    nativeCurrency: { name: 'AVAX', symbol: 'AVAX', decimals: 18 }
  }
};

export const WALLETCONNECT_RPC_URL = {
  [CHAIN_IDS.ETH]: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  [CHAIN_IDS.ARBITRUM]:'https://arb1.arbitrum.io/rpc',
  [CHAIN_IDS.RINKEBY]: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  [CHAIN_IDS.ARB_RINKEY]: 'https://rinkeby.arbitrum.io/rpc',
  [CHAIN_IDS.AVAX]: 'https://api.avax.network/ext/bc/C/rpc',
  [CHAIN_IDS.AVAX_FUJI]: 'https://api.avax-test.network/ext/bc/C/rpc'
};

export const SUPPORTED_IDS = isMainnet
  ? [ETH, ARBITRUM]
  : [RINKEBY, ARB_RINKEY];
export const ACTIVED_ID =
  CHAIN_IDS[process.env.REACT_APP_ACTIVED_KEY as string] || ETH;

export const ETHERSCAN_URL = INFO[ACTIVED_ID]?.explorerUrl ?? '';

export const ERC20_PROVIDER_HOST= INFO[ACTIVED_ID]?.provider ?? '';

const L1NET = ['ETH', 'RINKEBY'];

export const envNetworkKey = strEvent(
  strNewWorkFullName(process.env.REACT_APP_ACTIVED_KEY as string).toLowerCase()
);

export const isL1NET = (key?: string) => {
  return (
    L1NET.filter(
      e => e === (key || (process.env.REACT_APP_ACTIVED_KEY as string))
    ).length > 0
  );
};

export const L1Name = strEvent(
  strNewWorkFullName(process.env.REACT_APP_L1_NAME as string).toLowerCase()
);
export const L2Name = strEvent(
  strNewWorkFullName(process.env.REACT_APP_L2_NAME as string).toLowerCase()
);
