import React, { useEffect, useState } from 'react';
import styles from './pagination.module.css';

export interface PaginationProps {
  current: number;
  total: number;
  onChange: (page: number, total?: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  current,
  total,
  onChange
}) => {
  const [value, setValue] = useState<string | number>(current);
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowValue = event.target.value;
    const value = Number(rowValue);
    if (rowValue === '0' || value < 0) {
      setValue(1);
      onChange(1, total);
      return;
    }
    if (value > total) {
      setValue(total);
      onChange(total, total);
      return;
    }
    if (value >= total) {
      onChange(total, total);
    } else if (value >= 1) {
      onChange(value, total);
    }
    if (rowValue === '') {
      setValue(rowValue);
    } else {
      setValue(value);
    }
  };

  useEffect(() => {
    setValue(current);
  }, [current]);

  const prevDisabled = () => current <= 1;
  const nextDisabled = () => current >= total;

  const prev = () => {
    onChange(current - 1, total);
  };

  const next = () => {
    onChange(current + 1, total);
  };

  return (
    <div className={styles.pagination}>
      <div className={styles.pc}>
        <button
          className={`${styles.button} ${styles.prevBtn}`}
          disabled={prevDisabled()}
          onClick={prev}
        >
          PREV
        </button>
        <div className={styles.page}>
          <span className={styles.text}>page</span>
          <input
            inputMode="numeric"
            type="number"
            value={value}
            disabled={total === 1}
            onKeyDown={event => {
              if (
                !/^[0-9]+$/.test(event.key) &&
                event.keyCode !== 8 &&
                event.keyCode !== 46
              ) {
                event.preventDefault();
              }
            }}
            onWheel={e => {
              e.currentTarget.blur();
            }}
            onChange={handleInput}
            className={styles.input}
          />
          <span className={styles.text}>of {total === 0 ? 1 : total}</span>
        </div>
        <button
          className={`${styles.button} ${styles.nextBtn}`}
          disabled={nextDisabled()}
          onClick={next}
        >
          NEXT
        </button>
      </div>
      <div className={styles.mobile}>
        <div className={styles.mobilePageBar}>
          <button
            className={`${styles.button} ${styles.prevBtn} ${styles.prevBtnMobile}`}
            disabled={prevDisabled()}
            onClick={prev}
          ></button>
          <div className={styles.page}>
            <span className={styles.text}>page</span>
            <input
              inputMode="numeric"
              type="number"
              value={value}
              disabled={total === 1}
              onChange={handleInput}
              onKeyDown={event => {
                if (
                  !/^[0-9]+$/.test(event.key) &&
                  event.keyCode !== 8 &&
                  event.keyCode !== 46
                ) {
                  event.preventDefault();
                }
              }}
              onWheel={e => {
                e.currentTarget.blur();
              }}
              className={styles.input}
            />
            <span className={styles.text}>of {total === 0 ? 1 : total}</span>
          </div>
          <button
            className={`${styles.button} ${styles.nextBtn} ${styles.nextBtnMobile}`}
            disabled={nextDisabled()}
            onClick={next}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
