import React, { MouseEvent } from 'react';
import './modal.css';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { runCallback } from 'utils';
import Portal from 'components/Portal';
import Mask from './Mask';

export interface ModalProps {
  children: React.ReactNode;
  visible: boolean;
  closable?: boolean;
  onClose?: (visible: boolean) => void;
  title?: string;
  className?: string;
  style?: {};
  customContent?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  children,
  visible,
  closable = true,
  onClose,
  title,
  className = '',
  style = {},
  customContent
}) => {
  const nodeRef = React.useRef(null);
  const stopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };
  return (
    <Portal>
      <Mask
        visible={visible}
        onClose={() => closable && runCallback(onClose, false)}
      >
        {customContent}
        <div
          className={`modal ${className}`}
          style={style}
          ref={nodeRef}
          onClick={stopPropagation}
        >
          <div className={'modal-inner'}>
            <div className={'modal-header'}>
              <p className={'modal-title'}>{title}</p>
              {closable && (
                <button
                  onClick={() => runCallback(onClose, false)}
                  className={'modal-close'}
                >
                  <Close />
                </button>
              )}
            </div>
            {children}
          </div>
        </div>
      </Mask>
    </Portal>
  );
};

export default Modal;
